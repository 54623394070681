import { Select } from "antd";
import { observer } from "mobx-react";
import { groupProductStore } from "../../GroupProductStore";
import React from "react";
interface ProductRequirement {
    onChange: (value: any) => void;
    value: string;
}
const SelectProductRequirement: React.FC<ProductRequirement> = ({
    onChange,
    value,
}) => {
    return (
        <>
            <Select
                value={value}
                onChange={(e: any) => {
                    onChange(e);
                }}
                options={[
                    {
                        value: "empty",
                        label: "Chọn loại điều kiện",
                        disabled: true,
                    },
                    { value: "product-name", label: "Tên sản phẩm" },
                    // { value: 'product-category', label: 'Danh mục sản phẩm', disabled: true },
                    // { value: 'product-tag', label: 'Thẻ sản phẩm', disabled: true },
                    // { value: 'product-label', label: 'Nhãn sản phẩm', disabled: true },
                ]}
            />
        </>
    );
};
export default observer(SelectProductRequirement);
