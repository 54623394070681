import { getRequest } from "../../../common/helpers/RequestHelper";

class OrdersSessionMachineService {
    public fetchOrderSessionMachinePos(idPos: any, idSession: any, params: any): Promise<any> {
        const queryParams = [
            params.order_by ? `order_by=${params.order_by}` : "",
            params.page ? `page_number=${params.page}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.search ? `search=${params.search}` : "",
            params.user_id ? `user_id=${params.user_id}` : "",
            params.order_type ? `order_type=${params.order_type}` : "",
            params.status ? `payment_status=${params.status}` : "",
            params.start_date ? `start_date=${params.start_date}` : "",
            params.end_date ? `end_date=${params.end_date}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`api/v1/pos/ecommerce/order/${idPos}?${queryParams}&pos_session_id=${idSession}`);
    }

}

export const ordersSessionMachineService = new OrdersSessionMachineService();
