import { observer } from "mobx-react";
import { orderStore } from "./OrderStore";
import { useEffect, useState } from "react";
import "./styles/order.scss";
import { Button } from "react-bootstrap";
import type { RadioChangeEvent } from "antd";
import { DatePicker, Radio, ConfigProvider } from "antd";
import {
    createSearchParams,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Loading from "../../shared/components/loading/Loading";
import NoContent from "../../shared/components/NoContent";
import { RangePickerProps } from "antd/es/date-picker";
import ReactPaginate from "react-paginate";
import {
    handleKeyPressSpecialCharacters,
    utils,
} from "../../common/utils/Utils";
import dayjs from "dayjs"; // Thư viện xử lý ngày tháng
import viVN from "antd/locale/vi_VN"; // Ngôn ngữ tiếng Việt
import "dayjs/locale/vi"; // Cài đặt ngôn ngữ cho dayjs
dayjs.locale("vi");

const Order = () => {
    const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        orderStore.staticParamOrder.search = searchParams.get("search") || "";
        orderStore.staticParamOrder.payment_method =
            searchParams.get("payment_method") || "";
        orderStore.staticParamOrder.payment_status =
            searchParams.get("payment_status") || "";
        orderStore.staticParamOrder.order_type =
            searchParams.get("order_type") || "";
        orderStore.staticParamOrder.order_status =
            searchParams.get("order_status") || "";
        orderStore.staticParamOrder.page = page || 0;
        orderStore.staticParamOrder.startDate =
            searchParams.get("startDate") || "";
        orderStore.staticParamOrder.endDate = searchParams.get("endDate") || "";

        orderStore.fetctAllListOder();
    }, [location.search]);

    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const plainOptionsOrdermethod = ["Tiền mặt", "Chuyển khoản"];
    const plainOptionsInfoPaymentMethod = [
        "Hoàn trả",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn thành",
        "Thất bại",
    ];
    const plainOptionsOderType = ["POS", "WEBSITE"];
    const plainOptionsInfoOderType = [
        "Hoàn thành",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn trả",
    ];
    const startDate =
        orderStore.staticParamOrder.startDate !== ""
            ? dayjs(orderStore.staticParamOrder.startDate, "DD-MM-YYYY")
            : null;
    const endDate =
        orderStore.staticParamOrder.endDate !== ""
            ? dayjs(orderStore.staticParamOrder.endDate, "DD-MM-YYYY")
            : null;
    const handleSearchOrder = (e: React.FormEvent) => {
        e.preventDefault();
        orderStore.staticParamOrder.page = 0;
        handleSearch();
    };

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        const start: string = dateString[0];
        const end: string = dateString[1];
        localStorage.setItem("startDate", start);
        localStorage.setItem("endDate", end);
        orderStore.staticParamOrder.startDate = start;
        orderStore.staticParamOrder.endDate = end;

        let currentParams = new URLSearchParams(window.location.search);
        if (
            orderStore.staticParamOrder.startDate &&
            orderStore.staticParamOrder.endDate !== ""
        ) {
            currentParams.set(
                "startDate",
                orderStore.staticParamOrder.startDate
            );
            currentParams.set("endDate", orderStore.staticParamOrder.endDate);
        } else {
            currentParams.delete("startDate");
            currentParams.delete("endDate");
        }
        localStorage.setItem("currentUrl", currentParams.toString());

        currentParams.set("page", "1");
        orderStore.staticParamOrder.page = 0;
        // Điều hướng tới URL đã cập nhật
        navigate({
            search: currentParams.toString(),
        });
        orderStore.staticParamOrder.page = 0;
    };

    const handleClearFilter = () => {
        window.scroll(0, 0);
        //* Clear params khi ma xoa loc
        navigate({
            search: createSearchParams({}).toString(),
        });
        orderStore.handeClose();
    };

    const handleChangePage = (e: any) => {
        orderStore.staticParamOrder.page = e.selected;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(
            "page",
            (orderStore.staticParamOrder.page + 1).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleSeeDetail = (item: any) => {
        navigate(`/don-hang/${item.id}`);
        orderStore.staticOrder.payment_status = `${item.payment_status}`;
        orderStore.staticOrder.order_status = `${item.status_id}`;
    };

    const handleSearch = () => {
        updateUrlParams(true);
    };

    const handleSelectUpdateUrl = (isSelect: boolean) => {
        updateUrlParams(isSelect);
    };

    const updateUrlParams = (isSearch: boolean) => {
        
        const searchParams = new URLSearchParams(location.search);

        const params = {
            search: orderStore.staticParamOrder.search,
            payment_method: orderStore.staticParamOrder.payment_method,
            payment_status: orderStore.staticParamOrder.payment_status,
            order_type: orderStore.staticParamOrder.order_type,
            order_status: orderStore.staticParamOrder.order_status,
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });
        searchParams.set("page", "1");
        orderStore.staticParamOrder.page = 0;
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    return (
        <>
            <div className="container">
                <div className="mainContentOrder d-flex">
                    <div className="filter">
                        <h1>Đơn hàng</h1>

                        <div className="filterWrapper">
                            <div className="filterOrder">
                                <div
                                    className="filterOrder-datePicker d-flex flex-column"
                                    style={{
                                        gap: "8px",
                                    }}
                                >
                                    <label>Chọn thời gian</label>
                                    <ConfigProvider locale={viVN}>
                                        <RangePicker
                                            placeholder={[
                                                "Bắt đầu",
                                                "Kết thúc",
                                            ]}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            format="DD-MM-YYYY"
                                            // defaultValue={[startDate, endDate]}
                                            value={[startDate, endDate]}
                                            onChange={handleChangeDate}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsOrdermethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsOrdermethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "COD";
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "BANK_CASH";
                                            }

                                            handleSelectUpdateUrl(true);
                                        }}
                                        value={
                                            !orderStore.staticParamOrder
                                                .payment_method
                                                ? ""
                                                : orderStore.staticParamOrder
                                                      .payment_method === "COD"
                                                ? "Tiền mặt"
                                                : "Chuyển khoản"
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT Thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsInfoPaymentMethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsInfoPaymentMethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "REFUND";
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "CANCEL";
                                            } else if (selectedIndex === 2) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "PENDING";
                                            } else if (selectedIndex === 3) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "COMPLETE";
                                            } else if (selectedIndex === 4) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "FAILED";
                                            }

                                            handleSelectUpdateUrl(true);
                                        }}
                                        value={
                                            !orderStore.staticParamOrder
                                                .payment_status
                                                ? ""
                                                : orderStore.staticParamOrder
                                                      .payment_status ===
                                                  "CANCEL"
                                                ? "Hủy bỏ"
                                                : orderStore.staticParamOrder
                                                      .payment_status ===
                                                  "REFUND"
                                                ? "Hoàn trả"
                                                : orderStore.staticParamOrder
                                                      .payment_status ===
                                                  "PENDING"
                                                ? "Đang xử lý"
                                                : orderStore.staticParamOrder
                                                      .payment_status ===
                                                  "COMPLETE"
                                                ? "Hoàn thành"
                                                : orderStore.staticParamOrder
                                                      .payment_status ===
                                                  "FAILED"
                                                ? "Thất bại"
                                                : ""
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại đơn</label>
                                    <Radio.Group
                                        options={plainOptionsOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            orderStore.staticParamOrder.order_type =
                                                value;
                                            handleSelectUpdateUrl(true);
                                        }}
                                        value={
                                            orderStore.staticParamOrder
                                                .order_type
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT đơn</label>
                                    <Radio.Group
                                        options={plainOptionsInfoOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            const selectedIndex =
                                                plainOptionsInfoOderType.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex + 1 === 1) {
                                                orderStore.staticParamOrder.order_status =
                                                    "COMPLETED";
                                            } else if (
                                                selectedIndex + 1 ===
                                                2
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "CANCEL";
                                            } else if (
                                                selectedIndex + 1 ===
                                                3
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "PENDING";
                                            } else if (
                                                selectedIndex + 1 ===
                                                4
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "REFUND";
                                            }

                                            handleSelectUpdateUrl(true);
                                        }}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                        value={
                                            !orderStore.staticParamOrder
                                                .order_status
                                                ? ""
                                                : orderStore.staticParamOrder
                                                      .order_status ===
                                                  "COMPLETED"
                                                ? "Hoàn thành"
                                                : orderStore.staticParamOrder
                                                      .order_status === "CANCEL"
                                                ? "Hủy bỏ"
                                                : orderStore.staticParamOrder
                                                      .order_status ===
                                                  "PENDING"
                                                ? "Đang xử lý"
                                                : orderStore.staticParamOrder
                                                      .order_status === "REFUND"
                                                ? "Hoàn trả"
                                                : ""
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                className="btnClearFilter"
                                onClick={() => handleClearFilter()}
                            >
                                Xóa lọc <span>X</span>
                            </button>
                        </div>
                    </div>
                    <div className="content d-flex flex-column">
                        <div className="contentSearch">
                            <div className="content-page-header-left">
                                <form
                                    className="content-page-header-search d-flex align-items-center"
                                    onSubmit={handleSearchOrder}
                                >
                                    <input
                                        type="text"
                                        name="keyword"
                                        id="keyword"
                                        placeholder="Nhập mã sản phẩm, số điện thoại"
                                        onChange={(e) => {
                                            orderStore.staticParamOrder.search =
                                                e.target.value;
                                        }}
                                        value={
                                            orderStore.staticParamOrder.search
                                        }
                                        onKeyPress={
                                            handleKeyPressSpecialCharacters
                                        }
                                    />
                                    <button type="submit">
                                        <img
                                            src="/assets/icon/search.svg"
                                            alt="search"
                                        />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="contentListOrder">
                            <div className="table-responsive">
                                <table
                                    className="table table-striped"
                                    id="table-2"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: "20%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Đơn hàng
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Ngày
                                                <span
                                                    style={{
                                                        paddingLeft: "4px",
                                                    }}
                                                    onClick={() => {
                                                        orderStore.handleSortDateTime();
                                                        orderStore.fetctAllListOder();
                                                    }}
                                                >
                                                    <img
                                                        src="/assets/icon/sorting.svg"
                                                        alt=""
                                                        style={{
                                                            width: "14px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </span>
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Thao tác
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderStore.isLoading ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : orderStore.dataListOrder.length ===
                                          0 ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <NoContent />
                                                </td>
                                            </tr>
                                        ) : (
                                            orderStore.dataListOrder.map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                to={`/don-hang/${item.id}`}
                                                                            >
                                                                                {
                                                                                    item.transaction_id
                                                                                }
                                                                            </Link>
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                color: "#0e72ed",
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {item
                                                                                .customer
                                                                                .name ===
                                                                            "guest"
                                                                                ? "Khách lẻ"
                                                                                : item
                                                                                      .customer
                                                                                      .name}
                                                                        </span>
                                                                        -
                                                                        <span
                                                                            style={{
                                                                                color: "#0e72ed",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {item
                                                                                .customer
                                                                                ?.phone ||
                                                                                "Chưa có số điện thoại"}
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <strong>
                                                                            Tổng
                                                                            đơn:
                                                                        </strong>
                                                                        <span
                                                                            className="price"
                                                                            style={{
                                                                                fontSize:
                                                                                    "14px",
                                                                            }}
                                                                        >
                                                                            {utils.formatCurrency(
                                                                                item.total
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className="price"
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        {utils.formatCurrency(
                                                                            item.total -
                                                                                item.amount_after_payment
                                                                        )}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}{" "}
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleTimeString(
                                                                        "en-GB"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="ordermethod"
                                                                        style={{
                                                                            display:
                                                                                "inline-block",
                                                                            whiteSpace:
                                                                                "nowrap",
                                                                        }}
                                                                    >
                                                                        {item.source_type ===
                                                                        "pos"
                                                                            ? item.order_method ===
                                                                              "COD"
                                                                                ? "Tiền mặt"
                                                                                : "Chuyển khoản và tiền mặt"
                                                                            : item.order_method ===
                                                                              "COD"
                                                                            ? "Tiền mặt"
                                                                            : "Chuyển khoản"}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.payment_status ===
                                                                    0 ? (
                                                                        <span className="info-type infoPaymentmethod-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      1 ? (
                                                                        <span className="info-type infoPaymentmethod-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      2 ? (
                                                                        <span className="info-type infoPaymentmethod">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      3 ? (
                                                                        <span className="info-type infoPaymentmethod-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      4 ? (
                                                                        <span className="info-type infoPaymentmethod-failed">
                                                                            Thất
                                                                            bại
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.source_type ===
                                                                    "website" ? (
                                                                        <span className="info-type orderType orderType-web">
                                                                            Website
                                                                        </span>
                                                                    ) : (
                                                                        <span className="info-type orderType orderType-pos">
                                                                            Pos
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.status_id ===
                                                                    1 ? (
                                                                        <span className="info-type infoOrdertype-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      2 ? (
                                                                        <span className="info-type infoOrdertype-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      3 ? (
                                                                        <span className="info-type infoOrdertype-pending">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      4 ? (
                                                                        <span className="info-type infoOrdertype-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : (
                                                                        "Chưa có dữ liệu"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="seeDetail"
                                                                        onClick={() =>
                                                                            handleSeeDetail(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Xem ngay
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {orderStore.isLoading
                                    ? ""
                                    : Math.ceil(
                                          orderStore.metadata.total_page
                                      ) > 1 && (
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              pageCount={Math.ceil(
                                                  orderStore.metadata.total_page
                                              )}
                                              onPageChange={handleChangePage}
                                              forcePage={
                                                  orderStore.staticParamOrder
                                                      .page
                                              }
                                          />
                                      )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Order);
