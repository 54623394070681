import {getRequest, postRequest} from "../../../../common/helpers/RequestHelper"

class PaymentDebtService{
    apiRefresh = "api/v1/pos/ecommerce/order/refesh_amount_debt"
    apiPayDebts = "api/v1/pos/ecommerce/order/order_debt"
    public fetchRefreshAmountDebtOfCustomer(id:string,params:any): Promise<any>{
        return getRequest(`${this.apiRefresh}/${id}?pos_id=${params.pos_id}`);
    }
    public fetchPayDebtsToCustomer(params:any): Promise<any>{
        return postRequest(`${this.apiPayDebts}`,params);
    }
}
export const paymentDebtService = new PaymentDebtService();