import {getRequest, postRequest} from "../../../../common/helpers/RequestHelper"

class CustomerDebtService{
    apiLocation = "api/v1/pos/ecommerce/order/order_debt"
    public fetchListCustomerDebt(params:any): Promise<any>{
        const queryParameters = [
            params.pos_id ? `pos_id=${params.pos_id}` : '',
            params.keyword ? `keyword=${params.keyword}` : '',
            params.page ? `page_number=${params.page}` : '',
            params.per_page ? `per_page=${params.per_page}` : '',
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiLocation}?${queryParameters}`);
    }
}
export const customerDebtService = new CustomerDebtService();