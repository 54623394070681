import React, { useState, forwardRef, useImperativeHandle } from "react";
import { observer } from "mobx-react";
import { Modal } from "antd";
import { salePointStore } from "../SalePointStore";
import "../styles/salePoint.css";

export interface CurrencyTypeRef {
    resetCurrency: () => void;
}

const CurrencyType = forwardRef<CurrencyTypeRef, { onConfirm: (total: number, details: string[]) => void }>(
    ({ onConfirm }, ref) => {
        const initialCurrencyValues = [
            { value: 500, count: 0 },
            { value: 1000, count: 0 },
            { value: 2000, count: 0 },
            { value: 5000, count: 0 },
            { value: 10000, count: 0 },
            { value: 20000, count: 0 },
            { value: 50000, count: 0 },
            { value: 100000, count: 0 },
            { value: 200000, count: 0 },
            { value: 500000, count: 0 },
        ];

        const [currencyValues, setCurrencyValues] = useState(initialCurrencyValues);
        const [isConfirmed, setIsConfirmed] = useState(false);  // Cờ để kiểm tra việc xác nhận

        const handleIncrement = (index: number) => {
            setCurrencyValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[index].count += 1;
                return newValues;
            });
        };

        const handleDecrement = (index: number) => {
            setCurrencyValues((prevValues) => {
                const newValues = [...prevValues];
                if (newValues[index].count > 0) {
                    newValues[index].count -= 1;
                }
                return newValues;
            });
        };

        const handleInputChange = (index: number, value: string) => {
            // Cho phép nhập chuỗi rỗng (giá trị sẽ là 0) hoặc các số hợp lệ
            if (value === "" || /^\d*$/.test(value)) {
                setCurrencyValues((prevValues) => {
                    const newValues = [...prevValues];
                    newValues[index].count = value === "" ? 0 : parseInt(value, 10);
                    return newValues;
                });
            }
        };

        const totalAmount = currencyValues.reduce((total, currency) => {
            return total + currency.value * currency.count;
        }, 0);

        const details = currencyValues
            .filter((currency) => currency.count > 0)
            .map((currency) => `${currency.count} x ${currency.value.toLocaleString()} ₫`);

        const handleConfirm = () => {
            onConfirm(totalAmount, details);
            setIsConfirmed(true);  // Đánh dấu là đã xác nhận
            salePointStore.handleCurrencyOK();
        };

        const handleReset = () => {
            setCurrencyValues(initialCurrencyValues);
        };

        useImperativeHandle(ref, () => ({
            resetCurrency: handleReset,
        }));

        const handleModalClose = () => {
            if (!isConfirmed) {
                handleReset();
            }
            setIsConfirmed(false);
            salePointStore.handleCurrencyClose();
        };

        return (
            <Modal
                className="modal-form"
                footer={null}
                title="Loại tiền mặt"
                open={salePointStore.isModalCurrencyType}
                onCancel={handleModalClose}
                afterClose={handleModalClose}
            >
                <div className="line"></div>
                <div className="currency-table">
                    <div className="currency-column">
                        {currencyValues.slice(0, 5).map((currency, index) => (
                            <div key={currency.value} className="currency-row">
                                <div className="currency-operation">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => handleDecrement(index)}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="text"
                                        className="currency-input"
                                        value={currency.count}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                    />
                                    <button
                                        className="btn btn-light"
                                        onClick={() => handleIncrement(index)}
                                    >
                                        +
                                    </button>
                                </div>
                                <span className="currency-value">
                                    {currency.value.toLocaleString()} ₫
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="currency-column">
                        {currencyValues.slice(5).map((currency, index) => (
                            <div key={currency.value} className="currency-row">
                                <div className="currency-operation">
                                    <button
                                        className="btn btn-light"
                                        onClick={() => handleDecrement(index + 5)}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="text"
                                        className="currency-input"
                                        value={currency.count}
                                        onChange={(e) => handleInputChange(index + 5, e.target.value)}
                                    />
                                    <button
                                        className="btn btn-light"
                                        onClick={() => handleIncrement(index + 5)}
                                    >
                                        +
                                    </button>
                                </div>
                                <span className="currency-value">
                                    {currency.value.toLocaleString()} ₫
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="line"></div>
                <div className="footer">
                    <button className="btn btn-primary" onClick={handleConfirm}>
                        Xác nhận
                    </button>
                    <span className="total">Tổng: {totalAmount.toLocaleString()} ₫</span>
                </div>
            </Modal>
        );
    }
);

export default observer(CurrencyType);
