import React, {useEffect, useState} from "react";
import HeaderProductInPos from "./components/headerProductInPos/HeaderProductInPos";
import "./styles/ProductInPosPage.scss";
import ProductInPosControl from "./components/productInPosControl/ProductInPosControl";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { productInPostStore } from "./ProductInPostStore";
import { utils } from "../../../common/utils/Utils";
import Loading from "../../../shared/components/loading/Loading";
import ModalDetailProduct from "./components/modal/modalDetailProduct/ModalDetailProduct";
import { productInPosCartStore } from "./ProductInPosCartStore";
import { useNavigate } from "react-router-dom";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import {toastUtils} from "../../../common/utils/Toastutils";
const ProductInPosPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (productInPostStore.dataFilterProduct.search) {
      productInPostStore.getListProduct();
    }
  }, []);

  useEffect(() => {
    if (
      !productInPostStore.isLoadingDetailPos &&
      !productInPostStore.dataPosMachine?.is_working
    ) {
      navigate("/danh-sach-pos");
    }
  }, [
    productInPostStore.dataPosMachine,
    productInPostStore.isLoadingDetailPos,
  ]);
    const onNewScanResult = (decodedText:any, decodedResult:any) => {
      toastUtils.success("Quét mã thành công",'1');
      productInPosCartStore.getVariantByBarcode(
          decodedText
      );
    };


  return (
    <div className="ProductInPosPage-container">
      <HeaderProductInPos />
      <div className="ProductInPosPage-body">
          {productInPostStore.isBarcode ? (
              <>
                  <Html5QrcodePlugin
                      fps={10}
                      qrbox={{ width: 500, height: 250 } }
                      disableFlip={false}
                      qrCodeSuccessCallback={onNewScanResult}
                  /></>
          ) : (
              <>
                  <div className="listProduct">
                      {productInPostStore.isLoadingProduct ? (
                          <div className="h-100 d-flex align-items-center justify-content-center">
                              <Loading/>
                          </div>
                      ) : (
                          <div className="listProduct-box">
                              {productInPostStore.dataProduct.length > 0 &&
                                  productInPostStore.dataProduct.map((item, index) => (
                                      <div
                                          key={index}
                                          className="product-item"
                                          onClick={() => {
                                              productInPosCartStore.isOpenModalDetailProduct = true;
                                              productInPosCartStore.dataDetailProduct = item;
                                          }}
                                      >
                                          <div className="product-item__boxImg">
                                              <img src={item.preview.value} alt="img"/>
                                          </div>
                                          <div className="product-item__body">
                                              <h2 className="multiline-ellipsis" title={item.title}>
                                                  {item.title}
                                              </h2>
                                              <h3>{utils.formatCurrency(item.price_origin)}</h3>
                                          </div>
                                      </div>
                                  ))}
                          </div>
                      )}
                  </div>
              </>
          )}
          <ProductInPosControl/>
      </div>
        <ModalDetailProduct></ModalDetailProduct>
    </div>
  );
};

export default observer(ProductInPosPage);
