import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./styles/category_product.css";
import { Dropdown } from "react-bootstrap";
import { categoryProductStore } from "./CategoryProductStore";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import CreateCategoryProduct from "./CreateCategoryProduct";
import NoContent from "../../../shared/components/NoContent";
import { showDelete } from "../../../shared/components/showDelete";
import { handleKeyPressSpecialCharacters } from "../../../common/utils/Utils";
import { toastUtils } from "../../../common/utils/Toastutils";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const CategoryProductPage = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const location = useLocation();
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (categoryProductStore.staticParamCategoryProduct.search !== "")
            params.keyword =
                categoryProductStore.staticParamCategoryProduct.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        categoryProductStore.staticParamCategoryProduct.page = selectedPage;
        // categoryProductStore.fetchListCategoryProduct();
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== "") params.keyword = searchKeyword;
        // if (categoryProductStore.staticParamCategoryProduct.page !== 0) params.page = categoryProductStore.staticParamCategoryProduct.page;
        navigate({
            search: createSearchParams(params).toString(),
        });
        categoryProductStore.staticParamCategoryProduct.page = 0;
        categoryProductStore.fetchListCategoryProduct();
    };

    const [selected, setSelected] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selected, name]);
        } else {
            setSelected(selected.filter((item) => item !== name));
        }
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(
                categoryProductStore.dataListCategoryProduct.map(
                    (item: any) => item.id
                )
            );
        } else {
            setSelected([]);
        }
    }
    const allSelected =
        selected.length === categoryProductStore.dataListCategoryProduct.length;
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        categoryProductStore.staticParamCategoryProduct.search = query.get(
            "keyword"
        )
            ? String(query.get("keyword"))
            : "";
        categoryProductStore.staticParamCategoryProduct.page = query.get(
            "page"
        )
            ? Number(query.get("page")) - 1
            : 0;
        categoryProductStore.fetchListCategoryProduct().then();
    }, [location.search]);

    return (
        <div className="container">
            <CreateCategoryProduct />
            <div className="mainContentGroupProduct">
                <div className="filter">
                    <div className="filter-left">
                        <h1>Danh mục</h1>
                        <form className="filter-search" onSubmit={handleSearch}>
                            <input
                                type="text"
                                value={
                                    categoryProductStore
                                        .staticParamCategoryProduct.search
                                }
                                onKeyPress={handleKeyPressSpecialCharacters}
                                id="keyword"
                                onChange={(e: any) => {
                                    categoryProductStore.staticParamCategoryProduct.search =
                                        e.currentTarget.value;
                                }}
                                placeholder="Nhập tên danh mục"
                            />
                            <button type="submit">
                                <img
                                    src="/assets/icon/search.svg"
                                    alt="search"
                                />
                            </button>
                        </form>
                    </div>

                    <button
                        className="btn-add-modal"
                        onClick={(event) => {
                            event.preventDefault();
                            categoryProductStore.cshowModal();
                            categoryProductStore.isCreate = true;
                        }}
                    >
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className={`content-table`}>
                    <div className="table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "55%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Tên
                                    </th>
                                    <th
                                        style={{
                                            width: "28%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Url
                                    </th>
                                    <th
                                        style={{
                                            width: "12%",
                                            textAlign: "center",
                                        }}
                                    >
                                        Thao tác
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryProductStore.isLoading ? (
                                    <tr>
                                        <td
                                            colSpan={4}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : categoryProductStore.dataListCategoryProduct
                                      .length > 0 ? (
                                    categoryProductStore.dataListCategoryProduct.map(
                                        (item: any, index: number) => (
                                            <tr>
                                                {/*<td styles={{textAlign: 'center'}}>*/}
                                                {/*    <div className={`checkboxTable`}>*/}
                                                {/*        /!*<input type="checkbox" name="ids[]" value={item.id}/>*!/*/}
                                                {/*        <input*/}
                                                {/*            type="checkbox"*/}
                                                {/*            name="ids[]"*/}
                                                {/*            value={item.id}*/}
                                                {/*            checked={selected.includes(item.id)}*/}
                                                {/*            onChange={(e) => handleSelect(e.target.checked, item.id)}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*</td>*/}
                                                <td>
                                                    <div className="info">
                                                        <div className="image">
                                                            {item?.meta
                                                                ?.content ? (
                                                                <>
                                                                    <img
                                                                        src={
                                                                            item
                                                                                ?.meta
                                                                                ?.content
                                                                                ? item
                                                                                      .meta
                                                                                      .content
                                                                                : "/assets/images/no-image.svg"
                                                                        }
                                                                        alt="no-image"
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={
                                                                            item?.meta
                                                                                ? item.meta
                                                                                : "/assets/images/no-image.svg"
                                                                        }
                                                                        alt="no-image"
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="title-code">
                                                            <div className="title-code-content">
                                                                <p className="code">
                                                                    {item.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{item.slug}</p>
                                                </td>
                                                <td>
                                                    <Dropdown className="action-table">
                                                        <Dropdown.Toggle
                                                            variant="success"
                                                            id={`'dropdown-basic${index}`}
                                                        >
                                                            Thao tác
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                href="javascript:void(0)"
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    categoryProductStore.setIsModalOpen(
                                                                        true
                                                                    );
                                                                    categoryProductStore.isCreate =
                                                                        false;
                                                                    categoryProductStore.fetchGetCategoryProduct(
                                                                        item.id
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                                Sửa
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                href="javascript:void(0)"
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    event.preventDefault();
                                                                    showDelete.showDeleteConfirm(
                                                                        "Xác nhận xoá danh mục",
                                                                        "Bạn chắc chắn muốn xoá danh mục này? (Hành động này không thể hoàn tác)",
                                                                        () =>
                                                                            categoryProductStore.fetchDeleteCategoryProduct(
                                                                                item.id
                                                                            )
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                                Xóa
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={4}
                                            style={{ textAlign: "center" }}
                                        >
                                            <NoContent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {categoryProductStore.isLoading
                            ? ""
                            : Math.ceil(
                                  categoryProductStore.metadata.total_page
                              ) > 1 && (
                                  <ReactPaginate
                                      previousLabel={
                                          <img
                                              src="/assets/icon/fi-rr-angle-small-left.svg"
                                              alt="right"
                                          />
                                      }
                                      nextLabel={
                                          <img
                                              src="/assets/icon/fi-rr-angle-small-right.svg"
                                              alt="right"
                                          />
                                      }
                                      breakLabel={"..."}
                                      pageCount={Math.ceil(
                                          categoryProductStore.metadata
                                              .total_page
                                      )}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={3}
                                      onPageChange={handlePageClick}
                                      containerClassName={"pagination"}
                                      activeClassName={"active"}
                                      forcePage={
                                          categoryProductStore
                                              .staticParamCategoryProduct.page
                                      }
                                  />
                              )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default observer(CategoryProductPage);
