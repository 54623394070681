import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link, useNavigate, useParams} from "react-router-dom";

import {ThreeDot} from "react-loading-indicators";
import {utils} from "../../../../common/utils/Utils";
import Loading from "../../../../shared/components/loading/Loading";
import StorageService from "../../../../common/service/StorageService";
import {paymentDebtSuccessStore} from "./PaymentDebtSuccessStore";
import {paymentSuccessStore} from "../../paymentSuccess/PaymentSuccessStore";
const PaymentDebtSuccessPage = () => {
    const navigate = useNavigate();
    const { transactionPosId } = useParams<{ transactionPosId: string }>();
    useEffect(() => {
        paymentDebtSuccessStore.transactionPosId = transactionPosId
        paymentDebtSuccessStore.fetchTransactionDetail((route) => navigate(route))
    }, [transactionPosId]);
    return (
        <>
            <div className="success-page">
                <div className="session1">
                    <h1>POS</h1>
                </div>
                <div className="session2">
                    {paymentDebtSuccessStore.isLoading === true ? (
                        <p><ThreeDot
                            variant="bob"
                            color="#6D727C"
                            size="small"
                            textColor=""
                        /></p>
                    ) : (
                        <p>{utils.formatCurrency( paymentDebtSuccessStore.dataTransaction?.cash_amount + paymentDebtSuccessStore.dataTransaction?.bank_amount)}</p>
                    )}
                </div>
                <div className="container-pos">
                    <div className="session3 session3-debt">
                        <div className="rightSession3">
                            <h2>Thanh toán thành công</h2>

                            <div className="footerRight">
                                <Link to={`/pos/${paymentDebtSuccessStore.dataTransaction?.pos_id}/san-pham`}>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40"
                                             viewBox="0 0 41 40"
                                             fill="none">
                                            <path d="M15.5 30L25.5 20L15.5 10" stroke="white" stroke-width="5"
                                                  stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        Đơn hàng mới
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(PaymentDebtSuccessPage)