import { makeAutoObservable, toJS } from "mobx";
import { adminService } from "./AdminService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

interface StaticParam {
  page: number;
  paginate: number;
}
export enum EType {
  admin = 1,
  staff = 2,
}

export type TFormStaff = {
  id?: number | null;
  name?: string;
  email?: string;
  phone?: string | null;
  roles?: number | null;
  type?: number | null;
  avatar?: string;
  status?: any;
  password?: string | null;
  password_confirmation?: string | null;
};


class AdminStore {

  openModalAddEditStaff: boolean = false;
  openModalChangePermission: boolean = false;
  openModalChangePassword: boolean = false;

  typeModal: string = "add";
  isLoading: boolean = false;
  isLoadingBtn: boolean = false;

  allowSearch:boolean = false

  dataStaff: any[] = [];
  dataListRole: any[] = [];

  searchTemp: string = "";

  staticParam: StaticParam = {
    page: 1,
    paginate: 10,
  };

  metadata: any = {
    total: null,
    page: 1,
    total_page: null,
    size: null,
  };

  dataFormStaff: TFormStaff = {
    id: null,
    name: "",
    email: "",
    phone: null,
    roles: null,
    type: null,
    avatar: "",
    password: "",
    password_confirmation: ""
  };

  dataFilterStaff: any = {
    search: "",
    status: "",
    role: null,
  };

  changeFileImage = (pathFile: string) => {
    this.dataFormStaff.avatar = pathFile
};

    handleCloseIcon = () => {
      this.dataFormStaff.avatar = ""
    };

    resetFormStaff = () => {
      this.dataFormStaff = {
        id: null,
        name: "",
        email: "",
        phone: null,
        roles: null,
        type: null,
        avatar: "",
        password: null,
        password_confirmation: null
      };
    }

  constructor() {
    makeAutoObservable(this);
  }

  async getListStaff(run?: boolean) {
    let { page, paginate } = this.staticParam;
    let { search, status, role } = this.dataFilterStaff;

    const params = {
      page: page + 1,
      per_page: paginate,
      search: search,
      status: status,
      role: role,
      order : "DESC"
    };

    if (this.dataStaff.length < 1 || run) {
      this.isLoading = true;
    }

    const result = await adminService.fetchListStaff(params);
    this.isLoading = false;

    if (result.status === HttpStatusCode.Ok) {
      this.dataStaff = result.body.data;
      this.metadata = {
        total: result.body.metadata.total,
        page: result.body.metadata.page,
        total_page: result.body.metadata.total_page,
        size: result.body.metadata.size,
      };
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async getListRole() {
    const result = await adminService.fetchListRole();
    if (result.status === HttpStatusCode.Ok) {
      this.dataListRole = result.body.data;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async createStaff({ form }: { form: any }) {
    const {
      name,
      email,
      phone,
      roles,
      type,
      avatar,
      password,
      password_confirmation,
    } = this.dataFormStaff;

    const data = {
      name: name,
      email: email,
      phone: phone,
      roles: Number(roles),
      type: type || EType.staff,
      avatar: avatar,
      password: password,
      password_confirmation: password_confirmation,
    };
    this.isLoadingBtn = true;
    this.openModalAddEditStaff = true;
    const result = await adminService.addStaff(data);
    this.isLoadingBtn = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Thêm mới thành công", "");
      form.resetFields();
      this.dataStaff.unshift({
        ...result.body,
      });
      if(this.dataStaff.length > this.staticParam.paginate){
          this.dataStaff.pop();
      }
      this.openModalAddEditStaff = false;
      this.resetFormStaff()
    } else {
      toastUtils.error(result.body.message);
    }
  }

  async updateStaff({form}: { form?: any }) {
    const {
      id,
      name,
      email,
      phone,
      avatar,
      type,
      status,
      password,
      password_confirmation,
      roles
    } = this.dataFormStaff;
    
    const data: any = {
      name:name,
      email: email,
      phone: phone,
      avatar: avatar,
      type: type,
      roles: roles, 
      status: status
    };

    if (password && password_confirmation) {
      data.password = password ;
      data.password_confirmation = password_confirmation;
    }

    this.isLoadingBtn = true;
    const result = await adminService.editStaff(
      id,
      data
    );
    this.isLoadingBtn = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Cập nhật thành công", "");

      if(!password && !password_confirmation){
          const updatedArrStaff = this.dataStaff.map((item) =>
            item.id === id
              ? {
                  ...item,
                  ...this.dataFormStaff,
                  roles: [{id: this.dataFormStaff.roles, name: JSON.parse(result.body.permissions)}]
                }
              : item
          );
    
          this.dataStaff = updatedArrStaff;
      }
      this.resetFormStaff()
     this.typeModal === "edit" && form.resetFields()
     this.typeModal = ""
      this.openModalAddEditStaff = false;
      this.openModalChangePassword = false;
      this.openModalChangePermission = false
    } else {
      if(result.body?.errors?.email){
      toastUtils.error("Email đã tồn tại!");

      }
      toastUtils.error(result.body.message);
    }
  }


  async deleteStaff(id: number) {
    this.isLoadingBtn = true;
    const result = await adminService.deleteStaff(id);
    this.isLoadingBtn = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Xóa thành công", "");

      this.dataStaff = this.dataStaff.filter(
        (item: any) => item.id !== id
      );

      if (this.dataStaff.length === 0) {
        this.getListStaff();
      }
    } else {
      toastUtils.error(result.body.message);
    }
  }

}
export const adminStore = new AdminStore();
