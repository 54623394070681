import { getRequest, putRequest } from "../../../common/helpers/RequestHelper";

class InventoryService {
    apiLocation = "api/v1/location/list"
    apiSubdirectory = "api/v1/inventory_products";
    categoriesSubdirectory = "api/v1/product_categories";
    // productsSubdirectory = "api/v1/products";
    brandsSubdirectory = "api/v1/brands";
    variantsSubdirectory = "api/v1/variants";
    public fetchLocation(): Promise<any> {
        return getRequest(this.apiLocation);
    }
    // public fetchAllInventoryProduct(): Promise<any> {
    //     return getRequest(`${this.apiSubdirectory}?per_page=20`);
    // }
    // public fetchAllOutOfStockInventoryProduct(): Promise<any> {
    //     return getRequest(`${this.apiSubdirectory}/getOutOfStockProducts`);
    // }
    // public fetchInventoryProduct(params: any): Promise<any> {
    //     const queryParameters = [
    //         params.search ? `search=${params.search}` : "",
    //         // params.store_id ? `store_id=${params.store_id}` : '',
    //         params.page ? `page=${params.page}` : "",
    //         params.per_page ? `per_page=${params.per_page}` : "",
    //     ]
    //         .filter(Boolean)
    //         .join("&");
    //     return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
    // }
    public fetchModifyInventoryProduct(param: any): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${param.id}`, param);
    }
    public fetchAllBrand(): Promise<any> {
        return getRequest(`${this.brandsSubdirectory}`);
    }
    // public fetchAllProduct(): Promise<any> {
    //     return getRequest(`${this.productsSubdirectory}`);
    // }
    // public fetchProductViaParam(param: any): Promise<any> {
    //     const queryParams = [
    //         param.search ? `search=${param.search}` : "",
    //         param.brand_id ? `brand_id=${param.brand_id}` : "",
    //         param.category_id ? `category_id=${param.category_id}` : "",
    //         param.is_variation ? `category_id=${param.is_variation}` : "",
    //     ]
    //         .filter(Boolean)
    //         .join("&");
    //     return getRequest(`${this.productsSubdirectory}?${queryParams}`);
    // }


    public fetchAllCategories(): Promise<any> {
        return getRequest(`${this.categoriesSubdirectory}`);
    }

    public fetchAllVariants(store_id: string, paramVariant: any): Promise<any> {
        const queryParams = [
            paramVariant.offset ? `offset=${paramVariant.offset}` : "",
            paramVariant.order_by ? `order_by=${paramVariant.order_by}` : "",
            paramVariant.page ? `page=${paramVariant.page}` : "",
            paramVariant.parent_id ? `parent_id=${paramVariant.parent_id}` : "",
            paramVariant.per_page ? `per_page=${paramVariant.per_page}` : "",
            paramVariant.search ? `search=${paramVariant.search}` : "",
            paramVariant.status ? `status=${paramVariant.status}` : "",
            paramVariant.brand_id ? `brand_id=${paramVariant.brand_id}` : "",
            paramVariant.is_variant !== null ? `is_variant=${paramVariant.is_variant}` : "", // Sửa ở đây
        ]
            .filter(param => param !== "")
            .join("&");

        const apiSubWithParams = queryParams
            ? `${this.variantsSubdirectory}?store_id=${store_id}&${queryParams}`
            : `${this.variantsSubdirectory}?store_id=${store_id}`;

        return getRequest(apiSubWithParams);
    }


}
export const inventoryService = new InventoryService();
