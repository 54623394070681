import React from 'react';
import axios, {HttpStatusCode} from 'axios';
import {postRequest} from "./RequestHelper";
import {toastUtils} from "../utils/Toastutils";
import {filesStore} from "../../pages/settings/files/FilesStore";

export async function handleFile(
    e: React.ChangeEvent<HTMLInputElement>,
    changeFile: (pathFile: string) => void
) {
    const filePreview = e.target.files?.[0];
    if (filePreview) {
        try {
            const params = { file_name: filePreview.name };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                const preSignedUrl = resultCategoryProductAll.body.pre_signed;
                const uploadResult = await axios.put(preSignedUrl, filePreview, {
                    headers: {
                        'Content-Type': filePreview.type,
                        'X-Skip-Auth': true,
                    },
                });
                if (uploadResult.status === 200) {
                    const previewPath = resultCategoryProductAll.body.full_path;
                    changeFile(previewPath);
                } else {
                    toastUtils.error('File upload failed', '');
                }
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
            toastUtils.error('File upload failed due to an error', '');
        }
    }
}

export async function handleFileVariant(e: React.ChangeEvent<HTMLInputElement>,index:number, changeFileVariant: (pathFile: string,ordering:number) => void) {

    const filePreview = e.target.files?.[0];
    if (filePreview) {
        try {
            const params = {
                'file_name': filePreview.name,
            };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                const preSignedUrl = resultCategoryProductAll.body.pre_signed;
                await axios.put(preSignedUrl, filePreview, {
                    headers: {
                        'Content-Type': filePreview.type,
                        'X-Skip-Auth': true,
                    },
                });
                const previewPath = resultCategoryProductAll.body.full_path;
                changeFileVariant(previewPath,index);
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
        }
    }
}
export async function handleFileMultiple(e: React.ChangeEvent<HTMLInputElement>, changeFile: (pathFile: string[] ) => void) {
    const filePreview = e.target.files;
    if (filePreview && filePreview.length > 0) {
        const filePreviewArray: string[] = [];
        const files = [];
        for (let i = 0; i < filePreview.length; i++) {
            const file= filePreview[i];
            files.push(file.name)
        }
        try {
            const params = {
                'file_name': files,
            };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                for (let s = 0; s < resultCategoryProductAll.body.length; s++) {
                    if(resultCategoryProductAll.body[s].pre_signed && filePreview[s]){
                        await axios.put(resultCategoryProductAll.body[s].pre_signed, filePreview[s], {
                            headers: {
                                'Content-Type': filePreview[s].type,
                                'X-Skip-Auth': true,
                            }
                        });
                        filePreviewArray.push(resultCategoryProductAll.body[s].full_path);
                    }
                }
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
        }
        changeFile(filePreviewArray);
    }
}

export async function handleMediaFileMultiple(e: React.ChangeEvent<HTMLInputElement>, changeFile: (pathFile: string[] ) => void, directory: string) {
    const filePreview = e.target.files;
    if (filePreview && filePreview.length > 0) {
        const filePreviewArray: string[] = [];
        const files = [];
        for (let i = 0; i < filePreview.length; i++) {
            const file= filePreview[i];
            files.push(file.name)
        }
        try {
            const params = {
                'file_name': files,
                'directory': directory,
            };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                for (let s = 0; s < resultCategoryProductAll.body.length; s++) {
                    if(resultCategoryProductAll.body[s].pre_signed && filePreview[s]){
                        await axios.put(resultCategoryProductAll.body[s].pre_signed, filePreview[s], {
                            headers: {
                                'Content-Type': filePreview[s].type,
                                'X-Skip-Auth': true,
                            }
                        });
                        filePreviewArray.push(resultCategoryProductAll.body[s].full_path);
                    }
                }
                filesStore.fetchMediaList(true)
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
        }
        changeFile(filePreviewArray);
    }
}