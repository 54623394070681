import React, {useState, useRef} from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "antd";
import {salePointStore} from "../SalePointStore";
import "../styles/salePoint.css";
import CurrencyType, {CurrencyTypeRef} from "./CurrencyType";
import {useNavigate} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";

interface MoneyControlProps {
    posId: string;
}

const MoneyControl: React.FC<MoneyControlProps> = ({posId}) => {
    const [cashOnOpen, setCashOnOpen] = useState("");
    const [sessionNote, setSessionNote] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [noteError, setNoteError] = useState("");

    const currencyTypeRef = useRef<CurrencyTypeRef>(null);
    const navigate = useNavigate();

    const handleOpenCurrencyType = () => {
        salePointStore.showModalCurrency();
    };

    const handleCurrencyConfirm = (total: number, details: string[]) => {
        const formattedTotal = total.toLocaleString();
        setCashOnOpen(formattedTotal);

        const totalWithoutCommas = total.toString().replace(/,/g, '');

        const metaNoteStart = details.map((detail) => {
            const [quantity, name] = detail.split(" x ");
            return {
                name: `${name.trim()} `,
                quantity: quantity.trim(),
            };
        });

        const note = `Chi tiết tiền mặt:\n${details.join("\n")}`;
        setSessionNote(note);

        salePointStore.staticDataMoney = {
            pos_id: posId,
            price_pos: totalWithoutCommas,
            meta_note_start: metaNoteStart,
            note_start: note,
        };
    };


    const handleReset = () => {
        setCashOnOpen("");
        setSessionNote("");
        setErrorMessage("");
        setNoteError("");
        if (currencyTypeRef.current) {
            currencyTypeRef.current.resetCurrency();
        }
    };

    const handleModalClose = () => {
        handleReset();
        salePointStore.handleClose();
    };

    const handleOpenSession = async () => {
        if (!cashOnOpen) {
            setErrorMessage("Không được để trống!");
        } else {
            setErrorMessage("");
        }

        if (!sessionNote) {
            setNoteError("Không được để trống!");
        } else {
            setNoteError("");
        }

        if (salePointStore.staticDataMoney.pos_id && salePointStore.staticDataMoney.price_pos) {
            salePointStore.addCash(salePointStore.staticDataMoney);
            navigate(`/pos/${posId}/san-pham`);
        } else {
            console.error("Vui lòng điền đầy đủ thông tin trước khi mở phiên!");
        }
    };

    const handleCashOnOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(value)) {
            const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            setCashOnOpen(formattedValue);
            setErrorMessage("");

            salePointStore.staticDataMoney = {
                ...salePointStore.staticDataMoney,
                price_pos: value,
            }
        } else {
            setErrorMessage("Giá trị không hợp lệ! Vui lòng nhập số.");
        }
    };

    const handleSessionNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const noteValue = e.target.value;
        setSessionNote(noteValue);
        salePointStore.staticDataMoney = {
            ...salePointStore.staticDataMoney,
            note_start: noteValue,
        }
        if (e.target.value) {
            setNoteError("");
        }
    };

    const handleClearCashOnOpen = () => {
        setCashOnOpen("");
        salePointStore.staticDataMoney = {
            ...salePointStore.staticDataMoney,
            price_pos: "",
        }
    };

    return (
        <Modal
            className="modal-form"
            footer={null}
            title="Kiểm soát tiền mặt"
            open={salePointStore.isModalMoneyControl}
            onCancel={handleModalClose}
            afterClose={handleModalClose}
        >
            <div className="line"></div>
            <div className="modal-body">
                <div className="form-group">
                    <label>Tiền mặt khi mở</label>
                    <div className="input-group">
                        {/*<div className="input-money-group">*/}
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Vui lòng nhập"
                                value={cashOnOpen}
                                onChange={handleCashOnOpenChange}
                            />
                            {/*<Button*/}
                            {/*    type="text"*/}
                            {/*    icon={<CloseOutlined/>}*/}
                            {/*    className="btn-close"*/}
                            {/*    aria-label="Close"*/}
                            {/*    onClick={handleClearCashOnOpen}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        <div className="input-group-append">
                            <span className="input-group-text" onClick={handleOpenCurrencyType}>
                                <img src="/assets/icon/money_dolar.svg" alt="Money Icon"/>
                            </span>
                        </div>
                    </div>
                    {errorMessage && (
                        <div className="error-message" style={{color: "red"}}>
                            {errorMessage}
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>Ghi chú mở phiên</label>
                    <textarea
                        className="form-control"
                        rows={5}
                        placeholder="Vui lòng nhập"
                        value={sessionNote}
                        onChange={handleSessionNoteChange}
                    />
                    {noteError && (
                        <div className="error-message" style={{color: "red"}}>
                            {noteError}
                        </div>
                    )}
                </div>
            </div>
            <div className="line"></div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary btn-open-session"
                    onClick={handleOpenSession}
                >
                    Mở phiên
                </button>
            </div>

            <CurrencyType onConfirm={handleCurrencyConfirm} ref={currencyTypeRef}/>
        </Modal>
    );
};

export default observer(MoneyControl);
