import React, { useEffect } from "react";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import useDebounce from "../../../../common/customHook/useDebounce";
import {posStore} from "../PosStore";
import {adminStore} from "../../admin/AdminStore";
import {handleKeyPressSpecialCharacters} from "../../../../common/utils/Utils";
const FilterAdmin = () => {
  const navigate = useNavigate();
  const debouncedSearchStaff = useDebounce(posStore.searchTemp, 300);
  const updateUrlParams = (isSearchTriggered = false) => {
    const searchParams = new URLSearchParams();

    if (debouncedSearchStaff) {
      searchParams.set("search", debouncedSearchStaff);
    }
    if (posStore.staticParamPos.status) {
      searchParams.set("status", posStore.staticParamPos.status);
    }
    if (posStore.staticParamPos.store_id) {
      searchParams.set("store_id", `${posStore.staticParamPos.store_id}`);
    }
    posStore.staticParamPos.page = 0;
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    // posStore.allowSearch: khi load lại trang không bay vào đây tránh mất search trên url
    if (posStore.allowSearch && debouncedSearchStaff !== posStore.staticParamPos.search) {
      posStore.staticParamPos.search = debouncedSearchStaff;
      updateUrlParams(true);
    }
  }, [debouncedSearchStaff]);
  const handleSelectChange = (isSearchTriggered = true) => {
    updateUrlParams(isSearchTriggered);
  };
  return (
    <div className="box-filter">
      <div className="search">
        <img src="/assets/icon/searchModel.svg" alt="searchModel" />
        <input
          type="text"
          placeholder="Tìm kiếm theo tên ..."
          defaultValue={posStore.searchTemp ? posStore.searchTemp : ''}
          onKeyPress={handleKeyPressSpecialCharacters}
          onChange={(e: any) => {
            posStore.searchTemp = e.target.value
            posStore.allowSearch = true
          }}
        />
      </div>
      <SelectCustom
        list={posStore.listStore && posStore.listStore}
        placeholder="Chọn địa điểm"
        showSearch
        allowClear
        onChange={(value: any) => {
          posStore.staticParamPos.store_id = Number(value);
          handleSelectChange(true);
        }}
        selected={() => {
          // return  posStore.staticParamPos.store_id ? posStore.staticParamPos.store_id : 'Chọn địa điểm'
            return posStore.listStore?.find((item)=> item.id === posStore.staticParamPos.store_id)?.id || null ;
        }}
        getLabel={(item: any) => item.name}
      ></SelectCustom>

      <div className="select-status">
        <SelectCustom
          list={[
            { id: "active", name: "Hoạt động" },
            { id: "deactive", name: "Ngừng hoạt động" },
          ]}
          placeholder="Chọn trạng thái"
          showSearch
          allowClear
          onChange={(value: any) => {
            posStore.staticParamPos.status = value;
            handleSelectChange(true);
          }}
          selected={() => {
            return posStore.staticParamPos.status || 'Chọn trạng thái';
          }}
          getLabel={(item: any) => item.name}
        ></SelectCustom>
      </div>

      <div className="action--add" onClick={() => {
          posStore.modalOpen = true;
          posStore.isCreating = true;
          posStore.handleClearContent();
          posStore.modalName = "Thêm mới máy Pos"
      }}>
        <span>
          <img src="/assets/icon/plus.svg" alt="add" />
          Thêm mới
        </span>
      </div>
    </div>
  );
};

export default observer(FilterAdmin);
