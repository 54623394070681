import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { couponStore } from "./CouponStore";
import { Dropdown } from "react-bootstrap";
// import "./styles/coupon.css";
import "./styles/coupon.scss";
import ReactPaginate from "react-paginate";
import Loading from "../../../shared/components/loading/Loading";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import CreateCoupon from "./components/CreateCoupon";
import { showDelete } from "../../../shared/components/showDelete";
import NoContent from "../../../shared/components/NoContent";
import PutCoupon from "./components/PutCoupon";
import { handleKeyPressSpecialCharacters, utils } from "../../../common/utils/Utils";

const Coupon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState<any>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        couponStore.staticParamCoupon.search = searchParams.get("search") || "";
        couponStore.staticParamCoupon.page = page || 0;

        couponStore.fetchAllListCoupon();
    }, [location.search]);

    const handleSearchURL = () => {
        updateUrlParams(true);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();
        const params = {
            search: couponStore.staticParamCoupon.search,
            page: (couponStore.staticParamCoupon.page + 1).toString(),
        };
        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });
        navigate({ search: searchParams.toString() }, { replace: true });
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        couponStore.staticParamCoupon.page = 0;
        handleSearchURL();
    };

    const handleChangePage = (e: any) => {
        couponStore.staticParamCoupon.page = e.selected;
        const params: any = {};
        if (couponStore.staticParamCoupon.page !== 0) {
            params.page = couponStore.staticParamCoupon.page;
        }
        params.page = e.selected + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const showDeleteConfirm = (item: any) => {
        couponStore.staticCoupon.id = item.id;
        showDelete.showDeleteConfirm(
            "Xác nhận xóa",
            "Bạn có chắc muốn xóa mục này",
            async () => {
                await couponStore.fetchDeleteCoupon();
            }
        );
    };

    const handlePut = (item: any) => {
        couponStore.initialValues = {
            id: item.id,
            name: item.name,
            code: item.code,
            value: item.value,
            is_conditional: item.is_conditional?.toString(),
            min_amount: item.min_amount,
            start_from: item.start_from,
            will_expire: item.will_expire,
            preview: item.avatar,
            is_percentage: item.is_percentage?.toString(),
            is_featured: item.is_featured?.toString(),
            status: item.status?.toString(),
        };
        couponStore.initialValues.id = item.id;
        couponStore.showModalOpenUpdate();
    };

    return (
        <>
            <div className="container">
                <CreateCoupon />
                <PutCoupon />
                <div className="mainContentCoupon">
                    <div className="filter">
                        <div className="filter-left">
                            <h1>Mã giảm giá</h1>
                            <div className="filter-search">
                                <form
                                    className="filter-search"
                                    onSubmit={handleSearch}
                                >
                                    <input
                                        type="text"
                                        placeholder="Nhập mã giảm giá"
                                        value={
                                            couponStore.staticParamCoupon.search
                                        }
                                        onChange={(e) => {
                                            couponStore.staticParamCoupon.search =
                                                e.target.value;
                                        }}
                                        onKeyPress={handleKeyPressSpecialCharacters}
                                    />
                                    <button type="submit">
                                        <img
                                            src="/assets/icon/search.svg"
                                            alt="search"
                                        />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <button
                            className="btn-add-modal"
                            onClick={() => {
                                couponStore.showModalOpenCreate();
                            }}
                        >
                            <span>
                                Thêm mới
                                <img
                                    src="/assets/icon/plus.svg"
                                    alt="sorting"
                                />
                            </span>
                        </button>
                    </div>
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Ảnh
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            Mã
                                        </th>
                                        <th
                                            style={{
                                                textAlign:
                                                    windowWidth < 1200
                                                        ? "center"
                                                        : "left",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {windowWidth < 1200 ? (
                                                <span>
                                                    Số tiền/
                                                    <br />
                                                    Số phần
                                                    <br /> trăm
                                                </span>
                                            ) : (
                                                "Số tiền/Số phần trăm"
                                            )}
                                        </th>
                                        <th
                                            style={{
                                                textAlign:
                                                    windowWidth < 1200
                                                        ? "center"
                                                        : "left",
                                            }}
                                        >
                                            {windowWidth < 1200 ? (
                                                <span>
                                                    Bắt đầu <br /> từ
                                                </span>
                                            ) : (
                                                "Bắt đầu từ"
                                            )}
                                        </th>
                                        <th
                                            style={{
                                                textAlign:
                                                    windowWidth < 1200
                                                        ? "center"
                                                        : "left",
                                            }}
                                        >
                                            {windowWidth < 1200 ? (
                                                <span>
                                                    Sẽ hết <br /> hạn
                                                </span>
                                            ) : (
                                                "Sẽ hết hạn"
                                            )}
                                        </th>
                                        <th
                                            style={{
                                                textAlign:
                                                    windowWidth < 1200
                                                        ? "center"
                                                        : "left",
                                            }}
                                        >
                                            {windowWidth < 1200 ? (
                                                <span>
                                                    Tổng sử <br /> dụng
                                                </span>
                                            ) : (
                                                "Tổng sử dụng"
                                            )}
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            Trạng thái
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {couponStore.isLoading ? (
                                        <tr className="justify-content-center">
                                            <td colSpan={7}>
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : couponStore.dataListCoupon.length ===
                                      0 ? (
                                        <tr className="justify-content-center">
                                            <td
                                                colSpan={7}
                                                style={{
                                                    width: "15%",
                                                }}
                                            >
                                                <NoContent />
                                            </td>
                                        </tr>
                                    ) : (
                                        couponStore.dataListCoupon.map(
                                            (item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td className="d-flex align-items-center justify-content-center">
                                                                {!item.avatar ? (
                                                                    <img src="/assets/images/no-image.svg" />
                                                                ) : (
                                                                    <img
                                                                        src={`${item.avatar}`}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    style={{
                                                                        transform:
                                                                            "uppercase",
                                                                    }}
                                                                >
                                                                    {item.code}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {item.is_percentage ===
                                                                        1 ||
                                                                    item.is_percentage ===
                                                                        "1"
                                                                        ? `${item.value} %`
                                                                        : utils.formatCurrency(
                                                                              item.value
                                                                          )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {new Date(
                                                                        item.start_from
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    {new Date(
                                                                        item.will_expire
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {windowWidth <
                                                                1200 ? (
                                                                    <span>
                                                                        Tổng sử
                                                                        <br />{" "}
                                                                        dụng
                                                                    </span>
                                                                ) : (
                                                                    "Tổng sử dụng"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.status ===
                                                                    1 ||
                                                                item.status ===
                                                                    "1" ? (
                                                                    <span className="status status-active">
                                                                        Hoạt
                                                                        động
                                                                    </span>
                                                                ) : (
                                                                    <span className="status">
                                                                        Vô hiệu
                                                                        hóa
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Dropdown className="action-table">
                                                                    <Dropdown.Toggle
                                                                        variant="success"
                                                                        id="dropdown-basic1"
                                                                    >
                                                                        Thao tác
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() =>
                                                                                handlePut(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                            Sửa
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() =>
                                                                                showDeleteConfirm(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                            Xóa
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            }
                                        )
                                    )}
                                </tbody>
                            </table>
                            {couponStore.isLoading
                                ? ""
                                : Math.ceil(couponStore.metadata.total_page) >
                                      1 && (
                                      <ReactPaginate
                                          previousLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-left.svg"
                                                  alt="right"
                                              />
                                          }
                                          nextLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-right.svg"
                                                  alt="right"
                                              />
                                          }
                                          breakLabel={"..."}
                                          marginPagesDisplayed={2}
                                          pageRangeDisplayed={3}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          pageCount={Math.ceil(
                                              couponStore.metadata.total_page
                                          )}
                                          onPageChange={handleChangePage}
                                          forcePage={
                                              couponStore.staticParamCoupon.page
                                          }
                                      />
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Coupon);
