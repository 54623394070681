import { makeAutoObservable } from "mobx";
import { attributeService } from "./AttributeService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

interface StaticParamAttribute {
    offset: number;
    page: number;
    parent_id: number;
    search: string;
    per_page: number;
    type: string;
}

class AttributeStore {
    // STATE
    isLoadingTable: boolean = true;
    isLoadingModal: boolean = false;
    openModal: boolean = false;
    isCreating: boolean = false;
    isLoading: boolean = false;
    modalName: string = "";
    dropdownRef: object = {};
    inputPosition: number = 0;
    dataListAttribute: any[] = []; //List attribute
    dataListAttributeChildren: any[] = []; //List attribute children
    dataListAttributeChildrenOld: any[] = []; //List attribute children
    waitingText: string = ""; //waiting text appear when hit Lưu button in modal
    selectedTargets: any[] = []; //get the checked checkbox
    uploadAttributeOld: any;

    staticParamAttribute: StaticParamAttribute = {
        offset: 0,
        page: 1,
        parent_id: 0,
        search: "",
        type: "",
        per_page: 10,
    };
    staticDataAttribute: {
        attribute_id: null;
        attributes: any[];
        filterable: number;
        id: number;
        name: string;
        select_type: null;
        slug: string;
        status: 1;
        type: string;
    } = {
        attribute_id: null,
        attributes: [],
        filterable: 1,
        id: 0,
        name: "",
        select_type: null,
        slug: "",
        status: 1,
        type: "parent",
    };
    meta = {
        total: 0,
        page: 1,
        total_page: 1,
        size: 10,
    };
    constructor() {
        makeAutoObservable(this);
        // this.initParam();
    }
    // initParam() {
    //     const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
    //     this.staticParamAttribute.page = query.get("page")
    //         ? Number(query.get("page")) - 1
    //         : 0;
    //     this.staticParamAttribute.search = query.get("keyword")
    //         ? String(query.get("keyword"))
    //         : "";
    // }

    async getDataListAttribute(run?: boolean) {
        let { offset, page, parent_id, per_page, search, type } =
            this.staticParamAttribute;
        const paramForBrands = {
            page: page + 1,
            search: search,
            per_page: per_page,
        };
        if (this.dataListAttribute.length < 1 || run) {
            this.isLoadingTable = true;
        }
        const result = await attributeService.fetchAttributes(paramForBrands);
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.length !== this.dataListAttribute.length ||
                JSON.stringify(result.body.data) !==
                    JSON.stringify(this.dataListAttribute)
            ) {
                this.dataListAttribute = result.body.data;
                this.meta = result.body.meta;
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.isLoadingTable = false;
        this.openModal = false;
        this.handleResetModal();
    }

    async getDataListAttributeAll(run?: boolean) {
        const result = await attributeService.fetchAttributesAll();
        if (this.dataListAttribute.length < 1 || run) {
            this.isLoadingTable = true;
        }
        if (result.status === HttpStatusCode.Ok) {
            if (result.body.data.length !== this.dataListAttribute.length) {
                this.dataListAttribute = result.body.data;
                this.meta = result.body.meta;
            }
        } else {
            toastUtils.error(
                "Đã có lỗi xảy ra khi lấy dữ liệu từ hệ thống.",
                ""
            );
        }
        this.isLoadingTable = false;
        this.openModal = false;
        this.handleResetModal();
    }

    async createAttribute() {
        if (this.checkValidAttributeList(this.dataListAttributeChildren)) {
            this.waitingText = "Vui lòng đợi...";
            let { name } = this.staticDataAttribute;
            const addedAttribute = {
                parent_name: name,
                new_child: this.dataListAttributeChildren,
            };
            const result = await attributeService.fetchCreateNewAttribute(
                addedAttribute
            );
            if (result.status === HttpStatusCode.Ok) {
                this.staticParamAttribute.page = 0;
                this.staticParamAttribute.search = "";
                this.dataListAttribute.unshift(result.body.data)
                if(this.dataListAttribute.length > this.staticParamAttribute.per_page){
                    this.dataListAttribute.pop();
                }

                this.openModal = false
                toastUtils.success("Thêm thành công22!", "");
            } else if (result.status === HttpStatusCode.Created) {
                this.staticParamAttribute.page = 0;
                this.staticParamAttribute.search = "";
                this.dataListAttribute.unshift(result.body.data)
                if(this.dataListAttribute.length > this.staticParamAttribute.per_page){
                    this.dataListAttribute.pop();
                  }
                this.openModal = false
                toastUtils.success("Thêm thành công!", "");
            }
            else if (result.status === HttpStatusCode.UnprocessableEntity) {
                toastUtils.warning(
                    "Thuộc tính con không được vượt quá 100 ký tự !",
                    ""
                );
                this.waitingText = "";
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        } else {
            toastUtils.error("Vui lòng nhập thuộc tính con!", "");
        }
    }

    async modifyAttribute() {
        if (
            this.checkValidAttributeObjectList(
                this.staticDataAttribute.attributes
            )
        ) {
            //the previous attr gets checked OK
            if (!this.checkValidAttributeList(this.dataListAttributeChildren)) {
                //The new attr gets checked FAILED ALL
                this.dataListAttributeChildren = []; //Make it empty for avoiding error
            }
            this.waitingText = "Vui lòng đợi...";
            this.getDataListAttributeChildrenOld(); //Format Old Children into an Object like {'id': 'value', 'id2': 'value'}
            let { name, id } = this.staticDataAttribute;
            const updatedAttribute = {
                id: id,
                parent_name: name,
                filterable: true,
                oldchild: this.uploadAttributeOld,
                newchild: this.dataListAttributeChildren,
            };
            const result = await attributeService.fetchModifyAttribute(
                updatedAttribute
            );
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Sửa thành công!", "");
                this.staticParamAttribute.page = 0;
                this.staticParamAttribute.search = "";
                this.getDataListAttribute();
                this.handleResetModal();
                this.openModal = false;
            } else if (result.status === HttpStatusCode.UnprocessableEntity) {
                this.waitingText = "";
                toastUtils.warning(
                    "Thuộc tính con không được vượt quá 100 ký tự !",
                    ""
                );
            } else {
                toastUtils.error("Đã có lỗi xảy ra!", "");
            }
        } else {
            //When the Previous Attr gets deleted all
            if (this.checkValidAttributeList(this.dataListAttributeChildren)) {
                //When the new Child is also Empty
                toastUtils.error("Vui lòng nhập thuộc tính con!", "");
            } else {
                //When the new Child is not empty
                toastUtils.error(
                    "Không được xoá hết thuộc tính trước đó, vui lòng thử lại!",
                    ""
                );
                this.handleResetModal();
                this.getDataListAttributeAll();
                this.openModal = false;
            }
        }
    }

    // FUNCTION
    // handleRemovePageParam = () => {
    //     const url = new URL(window.location.href);
    //     url.searchParams.delete('page');
    //     window.location.href = url.toString();
    // }
    checkIfOnlyWhiteSpace(element: string) {
        var count: number = 0;
        for (var i of element) {
            if (i == " ") {
                count++;
            }
        }
        if (count === element.length) {
            return true;
        } else {
            return false;
        }
    }
    getDataListAttributeChildrenOld() {
        //Get Datalist the OLD Attribute Children from The Parent
        this.dataListAttributeChildrenOld = [
            ...this.staticDataAttribute.attributes,
        ].map((item) => {
            const id = item.id.toString();
            const name = item.name;
            return [id, name];
        });
        this.uploadAttributeOld = this.dataListAttributeChildrenOld.reduce(
            (initItem, [key, value]) => {
                initItem[key] = value;
                return initItem;
            },
            {}
        );
    }
    handleResetModal() {
        //Reset the field and the information from previous behaviors
        this.waitingText = "";
        this.staticDataAttribute.name = "";
        this.staticDataAttribute.slug = "";
        this.staticDataAttribute.attributes = [];
        this.dataListAttributeChildren = [];
        this.dataListAttributeChildrenOld = [];
        this.uploadAttributeOld = {};
        this.uploadAttributeOld = {};
    }
    handleAddInput() {
        //Handle Add more Input when hit "Thêm thuộc tính"
        this.dataListAttributeChildren.push("");
    }
    handleDeleteInput() {
        this.dataListAttributeChildren.splice(this.inputPosition, 1);
    }
    handleDeleteInputOld() {
        this.staticDataAttribute.attributes.splice(this.inputPosition, 1);
    }
    checkValidAttributeList(array: any[]) {
        //Check if the array is getting only whitespace/empty child
        var dummyDataList: any[] = array;
        var count: any = 0;
        for (var i in dummyDataList) {
            if (dummyDataList[i] === "") {
                count++;
            } else if (this.checkIfOnlyWhiteSpace(dummyDataList[i])) {
                count++;
            }
        }
        if (dummyDataList.length === count) {
            return false;
        } else {
            return true;
        }
    }
    checkValidAttributeObjectList(array: any[]) {
        //Check if the Object array is getting only whitespace/empty child
        var dummyDataList: any[] = array;
        for (var i in dummyDataList) {
            if (dummyDataList[i].name == "") {
                dummyDataList.splice(Number(i), 1);
            } else if (this.checkIfOnlyWhiteSpace(dummyDataList[i].name)) {
                dummyDataList.splice(Number(i), 1);
            }
        }
        if (dummyDataList.length > 0) {
            return true;
        }
        return false;
    }
}

export const attributeStore = new AttributeStore();
