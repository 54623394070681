import React, { useEffect } from "react";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import { adminStore } from "../AdminStore";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import useDebounce from "../../../../common/customHook/useDebounce";
import { handleKeyPressSpecialCharacters } from "../../../../common/utils/Utils";

const FilterAdmin = () => {
  const navigate = useNavigate();
  const debouncedSearchStaff = useDebounce(adminStore.searchTemp, 300);

  const updateUrlParams = () => {
    const searchParams = new URLSearchParams();

    if (debouncedSearchStaff) {
      searchParams.set("search", debouncedSearchStaff);
    }
    if (adminStore.dataFilterStaff.status) {
      searchParams.set("status", adminStore.dataFilterStaff.status);
    }
    if (adminStore.dataFilterStaff.role) {
      searchParams.set("role", adminStore.dataFilterStaff.role);
    }

    adminStore.staticParam.page = 0;

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    // adminStore.allowSearch: khi load lại trang không bay vào đây tránh mất search trên url
    if (adminStore.allowSearch && debouncedSearchStaff !== adminStore.dataFilterStaff.search) {
      adminStore.dataFilterStaff.search = debouncedSearchStaff;
      updateUrlParams();
    }
  }, [debouncedSearchStaff]);



  const handleSelectChange = () => {
    updateUrlParams();
  };
  return (
    <div className="box-filter">
      <div className="search">
        <img src="/assets/icon/searchModel.svg" alt="searchModel" />
        <input
          type="text"
          placeholder="Tìm kiếm theo tên ..."
          onKeyPress={handleKeyPressSpecialCharacters}
          value={adminStore.searchTemp}
          onChange={(e: any) => {
            adminStore.searchTemp = e.target.value
            adminStore.allowSearch = true
          }}
        />
      </div>
      <SelectCustom
        list={adminStore.dataListRole && adminStore.dataListRole}
        placeholder="Chọn nhóm quyền"
        showSearch
        allowClear
        onChange={(value: any) => {
          adminStore.dataFilterStaff.role = Number(value);
          handleSelectChange();
        }}
        selected={() => {
          // return Number(adminStore.dataFilterStaff.role) || null;
          return adminStore.dataListRole?.find((item)=> item.id === adminStore.dataFilterStaff.role)?.id || null;

        }}
        getLabel={(item: any) => item.name}
      ></SelectCustom>

      <div className="select-status">
        <SelectCustom
          list={[
            { id: "active", name: "Hoạt động" },
            { id: "deactive", name: "Ngừng hoạt động" },
          ]}
          placeholder="Chọn trạng thái"
          showSearch
          allowClear
          onChange={(value: any) => {
            adminStore.dataFilterStaff.status = value;
            handleSelectChange();
          }}
          selected={() => {
            return adminStore.dataFilterStaff.status || null;
          }}
          getLabel={(item: any) => item.name}
        ></SelectCustom>
      </div>

      <div className="action--add" onClick={()=>{adminStore.openModalAddEditStaff = true; adminStore.typeModal = "add"}}>
        <span>
          <img src="/assets/icon/plus.svg" alt="add" />
          Thêm mới
        </span>
      </div>
    </div>
  );
};

export default observer(FilterAdmin);
