import Constants from "../../../common/constants/Constants";
import { getRequest } from "../../../common/helpers/RequestHelper";

class DetailMachinePosService {

    public fetchDetailsMachinePos(id: any, params: any): Promise<any> {
        const queryParams = [
            params.order_by ? `order_by=${params.order_by}` : "",
            params.page ? `page_number=${params.page}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.search ? `search=${params.search}` : "",
            params.user_id ? `user_id=${params.user_id}` : "",
            params.status === 0 ? `status=0` : params.status ? `status=${params.status}` : "",
            params.start_date ? `start_date=${params.start_date}` : "",
            params.end_date ? `end_date=${params.end_date}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`api/v1/pos/ecommerce/machine/${id}?${queryParams}`);
    }


    public fetchAllStaff(){
        return getRequest(`api/v1/staff/list?page=1&paginate=${Constants.maxSize}`);
    }

}

export const detailMachinePosService = new DetailMachinePosService();
