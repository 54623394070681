import { HttpStatusCode } from "axios";
import { makeAutoObservable, toJS } from "mobx";
import { toastUtils } from "../../../common/utils/Toastutils";
import { productInPosService } from "./ProductInPosService";
import StorageService from "../../../common/service/StorageService";

export interface IdataFormAddCart {
  productId: number | null;
  productName: string;
  variant: {
    id: number | null;
    name: string;
  };
  quantity: number;
  price: number | null;
  discount: number;
  unit: string; // cây/quả...
  sku?: string;
  // totalPrice?: number | null;
}

class ProductInPosCartStore {
  isLoading: boolean = false;
  isLoadingBtn: boolean = false;
  flagActiveVariant: boolean = false;
  isSave:boolean = false
  dataDetailProduct: any = {};
  dataDetailVariant: any = {};
  isOpenModalDetailProduct: boolean = false;
  isSimplePro: boolean  = false

  dataVariant: any = [];
  dataImagewithTypeImage: any = [];
  dataImagewithTypeVariant: any = [];
  dataImageFull: any = [];

  arrVariantChoose: any[] = [];
  arrVariantChooseName: any[] = [];

  dataListProInCart: any[] = [];

  activeVariantProduct: any = null;
  activeActionControl: any = null;
  postId: any = null;
  searchBarcode: string = "";

  dataCustomer: any; // dùng khi chọn khách hàng

  dataFullVariant: any[] = []

  dataFormAddCart: IdataFormAddCart = {
    productId: null,
    productName: "",
    variant: {
      id: null,
      name: "",
    },
    quantity: 1,
    price: null,
    discount: 0,
    unit: "", // cây/quả...
    sku: "",
  };

  productControl: any = {
    type: "sl",
    keycap: null,
    idVariant: null,
  };

  constructor() {
    // this.initializeParams();
    makeAutoObservable(this);
  }

  async getListVariant() {
    this.isLoading = true;
    const result = await productInPosService.fetchListAttributeByProduct(
      this.dataDetailProduct.id
    );
    this.isLoading = false;

    if (result.status === HttpStatusCode.Ok) {
      this.dataVariant = result.body;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async getProductByType(type: string) {
    this.isLoading = true;

    const result = await productInPosService.fetchProductByType(
      type,
      this.dataDetailProduct.id
    );
    this.isLoading = false;
    if (result.status === HttpStatusCode.Ok) {
      if (type === "image") {
        this.dataImagewithTypeImage =
          result.body.gallery?.value?.length > 0
            ? result.body.gallery?.value?.map((item: any) => ({
                id: Math.floor(1000 + Math.random() * 9000),
                thumbnail: item,
              }))
            : [];
      }
      if (type === "variant") {
        this.dataImagewithTypeVariant =
          result.body.data?.length > 0
            ? result.body.data?.map((item: any) => ({
                id: item.id,
                thumbnail: item.thumbnail,
              }))
            : [];
        this.dataFullVariant = result.body.data
        

        if(!result.body.product_type){
          this.isSimplePro = true
        }else{
          this.isSimplePro = false
        }

      }

      this.dataImageFull = [
        ...this.dataImagewithTypeImage,
        ...this.dataImagewithTypeVariant,
      ];
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async getDetailsVariant(params: any) {
    if (this.dataDetailProduct.id) {
      this.isLoadingBtn = true;
      const result = await productInPosService.fetchDetailVariantByAttributeId(
        this.dataDetailProduct.id,
        params
      );
      this.isLoadingBtn = false;
      if (result.status === HttpStatusCode.Ok) {
        this.dataDetailVariant = result.body;
      } else {
        this.isLoadingBtn = true;
        toastUtils.error("Xin lỗi mã sản phẩm không tồn tại", "2");
      }
    }
  }

  CaculatorTotal(arr: any) {
    const total =
      arr.length > 0
        ? arr.reduce((accumulator: any, currentValue: any) => {
            const priceWithDiscount =
              currentValue.discount > 0
                ? ((((currentValue.price as number) *
                    currentValue.quantity) as number) *
                    (100 - currentValue.discount)) /
                  100
                : (((currentValue.price as number) *
                    currentValue.quantity) as number);
            return accumulator + priceWithDiscount;
          }, 0)
        : 0;

    return total;
  }

  async getVariantByBarcode(barcode: string) {
    if (barcode && barcode.length === 13 && this.isSave === false) {
      this.isSave = true
      const result = await productInPosService.fetchVariantByBarcode(barcode);
      if (result.status === HttpStatusCode.Ok) {
        const dataVariant = {
          productId: result.body.product.id,
          productName: result.body.product.title,
          variant: {
            id: result.body.id,
            name: result.body.attribute_compare,
          },
          quantity: 1,
          price: result.body.price,
          discount: 0,
          unit: result.body.product.unit, // cây/quả...
          sku: result.body.sku,
        };

        const currentCart =
          StorageService.getArrayFromLS(`cartPos${this.postId}`) || [];
        const existingItemIndex = currentCart.findIndex(
          (item) => item.variant.id === result.body.id
        );

        if (existingItemIndex > -1) {
          // nếu đã tồn tại thì chỉ cộng số lượng
          currentCart[existingItemIndex].quantity =
            Number(currentCart[existingItemIndex].quantity) + 1;
        } else {
          // thêm vào mảng mới
          currentCart.unshift(dataVariant);
        }
        this.isSave = false
        this.dataListProInCart = currentCart;
        this.flagActiveVariant = true;
        this.activeVariantProduct = result.body.id;
        this.searchBarcode = "";

        StorageService.saveArayLS(
          `cartPos${this.postId}`,
          this.dataListProInCart
        );
        toastUtils.success("Thêm giỏ hàng thành công",'2');
      }
    }
  }

  ChangeValueProductInfo(type: any, keycap: any, idVariant: any) {
    this.productControl.type = type;
    this.productControl.keycap = keycap;
    this.productControl.idVariant = idVariant;

    if (type === "sl" && keycap !== "sl") {
      const productIndex = this.dataListProInCart.findIndex(
        (item) => item.variant.id === idVariant
      );
      const product = this.dataListProInCart[productIndex];

      if (product) {
        if (keycap === "del2") {
          // Xóa từng số từ ngoài vào trong
          product.quantity = String(product.quantity).slice(0, -1) || "0"; // Xóa ký tự cuối

          // Chỉ chuyển về số nếu không có dấu "."
          if (!product.quantity.includes(".")) {
            product.quantity = Number(product.quantity);
          }
        } else if (keycap === "dots") {
          // Xử lý dấu "." cho số thập phân
          if (!String(product.quantity).includes(".")) {
            product.quantity = `${product.quantity}.`; // Thêm dấu "." nếu chưa có
          }
        } else {
          if (product.quantity === 0) {
            // Nếu số hiện tại là 0, thay thế trực tiếp bằng số mới để tránh hiện 05, 07, etc.
            product.quantity = keycap;
          } else {
            // Nếu số hiện tại không phải 0, nối chuỗi như bình thường
            product.quantity = `${product.quantity}${keycap}`;
          }
        }
      }
    }

    if (type === "price" && keycap !== "price") {
      // const product = this.dataListProInCart.find(
      //   (item) => item.variant.id === idVariant
      // );
      const productIndex = this.dataListProInCart.findIndex(
        (item) => item.variant.id === idVariant
      );
      const product = this.dataListProInCart[productIndex];

      if (product) {
        if (keycap === "del2") {
          // Xóa từng số từ ngoài vào trong
          product.price = String(product.price).slice(0, -1) || "0";
          product.price = Number(product.price);
        } else if (keycap === "dots") {
          return;
        } else {
          product.price = Number(`${product.price}${keycap}`);
        }
      }
    }

    if (type === "ck" && keycap !== "ck") {
      const productIndex = this.dataListProInCart.findIndex(
        (item) => item.variant.id === idVariant
      );
      const product = this.dataListProInCart[productIndex];

      if (product) {
        if (keycap === "del2") {
          // Xóa từng số từ ngoài vào trong
          product.discount = String(product.discount).slice(0, -1) || "0"; 

          // Chỉ chuyển về số nếu không có dấu "."
          if (!product.discount.includes(".")) {
            product.discount = Number(product.discount);
          }
        } else if (keycap === "dots") {
          // Xử lý dấu "." cho số thập phân
          if (!String(product.discount).includes(".")) {
            product.discount = `${product.discount}.`; // Thêm dấu "." nếu chưa có
          }
        } else {
          if (product.discount === 0) {
            product.discount = keycap;
          } else {
            // Nếu số hiện tại không phải 0, nối chuỗi như bình thường
            product.discount = `${product.discount}${keycap}`;

            if (product.discount > 100) {
              product.discount = 100;
            }
          }
        }
      }
    }

    if (keycap === "del1") {
      this.dataListProInCart = this.dataListProInCart.filter(
        (item: any) => item.variant.id !== idVariant
      );
    }

    StorageService.saveArayLS(`cartPos${this.postId}`, this.dataListProInCart);
  }
}

export const productInPosCartStore = new ProductInPosCartStore();
