import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./group_product.scss";
import { Dropdown } from "react-bootstrap";
import { groupProductStore } from "./GroupProductStore";
import { parseISO, format } from "date-fns";
import GroupProductModal from "./form/GroupProductModal";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { showDelete } from "../../../shared/components/showDelete";
import ProductChoosingModal from "./form/ProductChoosingModal";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import { handleKeyPressSpecialCharacters } from "../../../common/utils/Utils";
const GroupProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // useEffect(() => {
    //     groupProductStore.getDataListAll();
    //     groupProductStore.getDataListViaParam();
    // }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        groupProductStore.staticParamGroupProduct.search =
            searchParams.get("search") || "";
        groupProductStore.staticParamGroupProduct.page_number = page || 0;

        // groupProductStore.getDataListAll();
        groupProductStore.getDataListViaParam();
    }, [location.search]);

    const [windowWidth, setWindowWidth] = useState<any>(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // function handleSelect(value: boolean, name: string) {
    //     // handle select checkbox for each item in the table
    //     if (value) {
    //         groupProductStore.selectedTargets.push(name);
    //     } else {
    //         groupProductStore.selectedTargets =
    //             groupProductStore.selectedTargets.filter(
    //                 (item) => item !== name
    //             );
    //     }
    // }
    // var allSelectedTargetInTable: boolean =
    //     groupProductStore.dataListGroupProduct === undefined
    //         ? false
    //         : groupProductStore.selectedTargets.length ===
    //           groupProductStore.dataListGroupProduct.length;
    // function selectAllTargetInTable(value: boolean) {
    //     //handle select The Header Checkbox in table
    //     if (value) {
    //         groupProductStore.selectedTargets =
    //             groupProductStore.dataListGroupProduct.map(
    //                 (item: any) => item.id
    //             );
    //     } else {
    //         groupProductStore.selectedTargets = [];
    //     }
    // }
    const handleSearchURL = () => {
        updateUrlParams(true);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();
        const params = {
            search: groupProductStore.staticParamGroupProduct.search,
            page: (
                groupProductStore.staticParamGroupProduct.page_number + 1
            ).toString(),
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({ search: searchParams.toString() }, { replace: true });

        // if (isSearch) {
        //     groupProductStore.getDataListViaParam();
        // }
    };

    const handlePageClick = (e: any) => {
        groupProductStore.staticParamGroupProduct.page_number = e.selected;

        const searchParams = new URLSearchParams(location.search);
        searchParams.set(
            "page",
            (
                groupProductStore.staticParamGroupProduct.page_number + 1
            ).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        groupProductStore.getDataListViaParam();
    };

    // const handlePageClick = (data: { selected: number }) => {
    //     //Handle page change when paginating
    //     const selectedPage = data.selected;
    //     const params: any = {};
    //     if (groupProductStore.staticParamGroupProduct.search !== "") {
    //         params.keyword = `search=${groupProductStore.staticParamGroupProduct.search}`;
    //     }
    //     params.page = selectedPage + 1;
    //     navigate({
    //         search: createSearchParams(params).toString(),
    //     });
    //     groupProductStore.staticParamGroupProduct.page_number = selectedPage;
    //     groupProductStore.getDataListViaParam();
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    // };

    function onSubmitSearch() {
        // const searchKeyword = groupProductStore.staticParamGroupProduct.search;
        // const params: any = {};
        // if (searchKeyword !== "") {
        //     params.keyword = `${searchKeyword}`;
        // }
        // navigate({
        //     search: createSearchParams(params).toString(),
        // });
        // groupProductStore.getDataListViaParam();
        handleSearchURL();
    }
    function onHandleKeyDown(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch();
        }
    }

    return (
        <div className="container">
            <div className="mainContentGroupProduct">
                <div className="filter">
                    <div className="filter-left">
                        <h1>Nhóm sản phẩm</h1>
                        <div className="filter-search">
                            <input
                                type="text"
                                placeholder="Nhập tên nhóm sản phẩm"
                                value={
                                    groupProductStore.staticParamGroupProduct
                                        .search
                                }
                                onChange={(e) => {
                                    groupProductStore.staticParamGroupProduct.search =
                                        e.target.value;
                                }}
                                onKeyDown={(event) => {
                                    onHandleKeyDown(event);
                                }}
                                onKeyPress={handleKeyPressSpecialCharacters}
                            />
                            <button
                                onClick={() => {
                                    onSubmitSearch();
                                }}
                            >
                                <img
                                    src="/assets/icon/search.svg"
                                    alt="search"
                                />
                            </button>
                        </div>
                    </div>
                    <button
                        className="btn-add-modal"
                        style={{
                            width: "130px",
                        }}
                        onClick={() => {
                            groupProductStore.isCreating = true;
                            groupProductStore.handleResetModal();
                            groupProductStore.openModal = true;
                            groupProductStore.modalName =
                                "Tạo nhóm sản phẩm mới";
                        }}
                    >
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className={`content-table`}>
                    <div className="table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "33%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Tên
                                    </th>
                                    <th
                                        style={{
                                            width: "28%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Url
                                    </th>
                                    <th
                                        style={{
                                            width: "27%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày tạo
                                    </th>
                                    <th
                                        style={{
                                            width: "12%",
                                            textAlign: "right",
                                            paddingRight: "22px",
                                        }}
                                    >
                                        Thao tác
                                    </th>
                                </tr>
                            </thead>
                            {groupProductStore.isLoadingTable === true ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={5}>
                                            <Loading />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <>
                                    {groupProductStore.dataListGroupProduct
                                        .length === 0 ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={5}>
                                                    <NoContent />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <>
                                            <tbody>
                                                {groupProductStore.dataListGroupProduct.map(
                                                    (
                                                        item: any,
                                                        index: number
                                                    ) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="info">
                                                                    <div className="title-code">
                                                                        <div className="title-code-content">
                                                                            <p
                                                                                className="code"
                                                                                title={
                                                                                    item.name
                                                                                }
                                                                                style={{
                                                                                    maxWidth:
                                                                                        windowWidth <
                                                                                        850
                                                                                            ? "112px"
                                                                                            : "420px",
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    textOverflow:
                                                                                        "ellipsis",
                                                                                    whiteSpace:
                                                                                        "nowrap",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p
                                                                    title={
                                                                        item.slug
                                                                    }
                                                                    style={{
                                                                        maxWidth:
                                                                            windowWidth <
                                                                            850
                                                                                ? "112px"
                                                                                : "420px",
                                                                        overflow:
                                                                            "hidden",
                                                                        textOverflow:
                                                                            "ellipsis",
                                                                        whiteSpace:
                                                                            "nowrap",
                                                                    }}
                                                                >
                                                                    {item.slug}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {format(
                                                                    parseISO(
                                                                        item.created_at
                                                                    ),
                                                                    "dd-MM-yyy"
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                <Dropdown
                                                                    align={
                                                                        "start"
                                                                    }
                                                                    className="action-table"
                                                                >
                                                                    <Dropdown.Toggle>
                                                                        Thao tác
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() => {
                                                                                groupProductStore.openModal =
                                                                                    true;
                                                                                groupProductStore.staticDataGroupProduct.id =
                                                                                    item.id;
                                                                                groupProductStore.staticDataGroupProduct.name =
                                                                                    item.name;
                                                                                groupProductStore.staticDataGroupProduct.description =
                                                                                    item.description;
                                                                                groupProductStore.staticDataGroupProduct.seo_description =
                                                                                    item.seo_description;
                                                                                groupProductStore.staticDataGroupProduct.seo_title =
                                                                                    item.seo_title;
                                                                                groupProductStore.staticDataGroupProduct.type =
                                                                                    item.type;
                                                                                groupProductStore.staticDataGroupProduct.thumbnail =
                                                                                    item.thumbnail;
                                                                                groupProductStore.getSpecificGroupProduct();
                                                                                groupProductStore.isCreating =
                                                                                    false;
                                                                                groupProductStore.modalName =
                                                                                    "Chỉnh sửa Nhóm sản phẩm";
                                                                                console.log(
                                                                                    "check",
                                                                                    item
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                            Sửa
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            onClick={() => {
                                                                                groupProductStore.staticDataGroupProduct.id =
                                                                                    item.id;
                                                                                showDelete.showDeleteConfirm(
                                                                                    "Xác nhận xoá thuộc tính",
                                                                                    `Bạn chắc chắn muốn xoá Nhóm sản phẩm: "${item.name}" không?`,
                                                                                    async () => {
                                                                                        await groupProductStore.deleteGroupProduct();
                                                                                    }
                                                                                );

                                                                                //groupProductStore.getSpecificAttribute()
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                            Xóa
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </>
                                    )}
                                </>
                            )}
                        </table>
                        {groupProductStore.isLoadingTable
                            ? ""
                            : Math.ceil(groupProductStore.metadata.total_page) >
                                  1 && (
                                  <div className="pagination__container">
                                      <ReactPaginate
                                          previousLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-left.svg"
                                                  alt="right"
                                              />
                                          }
                                          nextLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-right.svg"
                                                  alt="right"
                                              />
                                          }
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(
                                              groupProductStore.metadata
                                                  .total_page
                                          )}
                                          marginPagesDisplayed={2}
                                          pageRangeDisplayed={3}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          forcePage={
                                              groupProductStore
                                                  .staticParamGroupProduct
                                                  .page_number
                                          }
                                      />
                                      {/* <div className="pagination__info">
                                          Hiển thị{" "}
                                          {groupProductStore.metadata.page !=
                                          groupProductStore.metadata.total_page
                                              ? groupProductStore.metadata.size
                                              : groupProductStore.metadata
                                                    .total -
                                                (groupProductStore.metadata
                                                    .page -
                                                    1) *
                                                    groupProductStore.metadata
                                                        .size}
                                          /Tổng số{" "}
                                          {groupProductStore.metadata.total}{" "}
                                          thuộc tính
                                      </div> */}
                                  </div>
                              )}
                    </div>
                </div>
            </div>
            <GroupProductModal />
            <ProductChoosingModal />
        </div>
    );
};
export default observer(GroupProduct);
