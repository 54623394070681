import React, { useEffect } from "react";
import "./styles/OrdersSessionMachine.scss";
import viVN from "antd/es/locale/vi_VN";
import "dayjs/locale/vi"; // Locale cho Day.js
import dayjs from "dayjs";
import LayoutSessionDetails from "../components/LayoutSessionDetails";
import BoxFilterSessionDetails from "../components/BoxFilterSessionDetails";
import { Avatar, ConfigProvider, DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import ReactPaginate from "react-paginate";
import { detailMachinePosStore } from "../detailMachinePos/DetailMachinePosStore";
import { ordersSessionMachineStore } from "./OrdersSessionMachineStore";
import { handleKeyPressSpecialCharacters, utils } from "../../../common/utils/Utils";
import { TransactionType } from "../../../common/constants/Constants";
import { toastUtils } from "../../../common/utils/Toastutils";
const { RangePicker } = DatePicker;

const OrdersSessionMachinePage = () => {
    const { posId, sessionId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const start = dayjs().subtract(3, "month").format("DD-MM-YYYY");
    const end = dayjs().endOf("day").format("DD-MM-YYYY");

    useEffect(() => {
        detailMachinePosStore.fetchAllStaffList();
    }, []);

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        const start = dateString[0];
        const end = dateString[1];

        if (start && end) {
            const startDate = dayjs(start, "DD-MM-YYYY");
            const endDate = dayjs(end, "DD-MM-YYYY");

            // Kiểm tra nếu ngày kết thúc vượt quá 3 tháng kể từ ngày bắt đầu
            const maxEndDate = startDate.add(3, "month"); // Ngày kết thúc tối đa là 3 tháng sau ngày bắt đầu

            if (endDate.isAfter(maxEndDate)) {
                toastUtils.warning(
                    "Thời gian tìm kiếm không được quá 3 tháng !"
                );

                // Đặt lại ngày kết thúc
                detailMachinePosStore.dataFilterDetailMachinePos.end_date = "";

                return;
            }
        }

        ordersSessionMachineStore.dataFilterOrdersSessionMachine.start_date =
            start;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.end_date = end;

        handleSelectChange();
    };

    const getDefaultPickerValue = () => {
        const start = dayjs().subtract(3, "month").startOf("day");
        const end = dayjs().endOf("day");
        return [start, end];
    };

    const dataStaff =
        detailMachinePosStore.dataAllStaff?.length > 0
            ? detailMachinePosStore.dataAllStaff.map((item: any) => ({
                  id: item.id,
                  name: item.name,
              }))
            : [];

    const updateUrlParams = () => {
        const searchParams = new URLSearchParams();

        if (ordersSessionMachineStore.dataFilterOrdersSessionMachine.search) {
            searchParams.set(
                "search",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine.search
            );
        }
        if (ordersSessionMachineStore.dataFilterOrdersSessionMachine.status) {
            searchParams.set(
                "status",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine
                    .status as string
            );
        }
        if (ordersSessionMachineStore.dataFilterOrdersSessionMachine.user_id) {
            searchParams.set(
                "user_id",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine
                    .user_id as string
            );
        }
        if (
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.order_type
        ) {
            searchParams.set(
                "order_type",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine
                    .order_type as string
            );
        }
        if (
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.start_date
        ) {
            searchParams.set(
                "start_date",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine
                    .start_date
            );
        }

        if (ordersSessionMachineStore.dataFilterOrdersSessionMachine.end_date) {
            searchParams.set(
                "end_date",
                ordersSessionMachineStore.dataFilterOrdersSessionMachine
                    .end_date
            );
        }
        ordersSessionMachineStore.staticOrdersSessionMachine.page = 0;

        navigate({ search: searchParams.toString() }, { replace: true });
    };

    const handleSearch = () => {
        updateUrlParams();
    };

    const handleSelectChange = () => {
        updateUrlParams();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        ordersSessionMachineStore.dataFilterOrdersSessionMachine.search =
            searchParams.get("search") || "";
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.status =
            Number(searchParams.get("status")) || null;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.user_id =
            Number(searchParams.get("user_id")) || null;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.order_type =
            Number(searchParams.get("order_type")) || null;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.start_date =
            searchParams.get("start_date") || start;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.end_date =
            searchParams.get("end_date") || end;
        ordersSessionMachineStore.staticOrdersSessionMachine.page =
            searchParams.get("page") ? Number(searchParams.get("page")) - 1 : 0;
        ordersSessionMachineStore.fetchAllOrdersSessionMachine(
            posId,
            sessionId
        );
    }, [location.search]);

    const handleRemoveFilters = () => {
        const searchParams = new URLSearchParams(location.search);

        if (
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.search ||
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.status ||
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.user_id ||
            ordersSessionMachineStore.dataFilterOrdersSessionMachine
                .order_type ||
            searchParams.get("start_date") ||
            searchParams.get("end_date")
        ) {
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.search =
                "";
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.status =
                null;
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.user_id =
                null;
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.order_type =
                null;
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.start_date =
                start;
            ordersSessionMachineStore.dataFilterOrdersSessionMachine.end_date =
                end;
            ordersSessionMachineStore.staticOrdersSessionMachine.page = 0;
            navigate({ search: "" }, { replace: true });
        }
    };

    const handleChangePage = (e: any) => {
        ordersSessionMachineStore.staticOrdersSessionMachine.page = e.selected;
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(
            "page",
            (
                ordersSessionMachineStore.staticOrdersSessionMachine.page + 1
            ).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.start_date =
            start;
        ordersSessionMachineStore.dataFilterOrdersSessionMachine.end_date = end;
    }, []);

  return (
    <LayoutSessionDetails
      name={`Danh sách đơn hàng theo phiên ${
        ordersSessionMachineStore.metadata.pos_name
          ? ordersSessionMachineStore.metadata.pos_name
          : ""
      }`}
      onClickBack={() => {
        navigate("/danh-sach-pos");
      }}
    >
      <div
        className={`OrdersSessionMachineContainer ${
          ordersSessionMachineStore.isLoading ? "disableScreen" : ""
        }`}
      >
        <BoxFilterSessionDetails handleRemoveFilter={handleRemoveFilters}>
          <form
            className="filter-search"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <input
              type="text"
              name="keyword"
              value={
                ordersSessionMachineStore.dataFilterOrdersSessionMachine.search
              }
              id="keyword"
              onChange={(e: any) => {
                ordersSessionMachineStore.dataFilterOrdersSessionMachine.search =
                  e.currentTarget.value;
              }}
              placeholder="Tìm kiếm theo tên hoặc mã đơn"
            />
            <button type="submit">
              <img src="/assets/icon/search.svg" alt="search" />
            </button>
          </form>

                    <ConfigProvider locale={viVN}>
                        <RangePicker
                            placeholder={["Bắt đầu", "Kết thúc"]}
                            style={{
                                cursor: "pointer",
                            }}
                            format="DD-MM-YYYY"
                            allowClear={false}
                            value={[
                                ordersSessionMachineStore
                                    .dataFilterOrdersSessionMachine.start_date
                                    ? dayjs(
                                          ordersSessionMachineStore
                                              .dataFilterOrdersSessionMachine
                                              .start_date,
                                          "DD-MM-YYYY"
                                      )
                                    : null,
                                ordersSessionMachineStore
                                    .dataFilterOrdersSessionMachine.end_date
                                    ? dayjs(
                                          ordersSessionMachineStore
                                              .dataFilterOrdersSessionMachine
                                              .end_date,
                                          "DD-MM-YYYY"
                                      )
                                    : null,
                            ]}
                            defaultValue={
                                getDefaultPickerValue() as [
                                    dayjs.Dayjs,
                                    dayjs.Dayjs
                                ]
                            } // Chỉ định kiểu cho TypeScript
                            // disabledDate={disabledDate}
                            onChange={handleChangeDate}
                        />
                    </ConfigProvider>

                    <SelectCustom
                        list={dataStaff && dataStaff}
                        placeholder="Nhân viên"
                        showSearch
                        allowClear
                        onChange={(value: any) => {
                            ordersSessionMachineStore.dataFilterOrdersSessionMachine.user_id =
                                Number(value);
                            handleSelectChange();
                        }}
                        selected={() => {
                            return (
                                detailMachinePosStore.dataAllStaff?.find(
                                    (item) =>
                                        item.id ===
                                        ordersSessionMachineStore
                                            .dataFilterOrdersSessionMachine
                                            .user_id
                                )?.id || null
                            );
                        }}
                        getLabel={(item: any) => item.name}
                    ></SelectCustom>

                    <SelectCustom
                        list={[
                            { id: 1, name: "Đơn thường" },
                            { id: 2, name: "Hoàn tiền" },
                            { id: 3, name: "Công nợ" },
                        ]}
                        placeholder="Loại đơn"
                        onChange={(value: any) => {
                            ordersSessionMachineStore.dataFilterOrdersSessionMachine.order_type =
                                Number(value);
                            handleSelectChange();
                        }}
                        allowClear
                        selected={() => {
                            return (
                                Number(
                                    ordersSessionMachineStore
                                        .dataFilterOrdersSessionMachine
                                        .order_type
                                ) || null
                            );
                        }}
                        getLabel={(item: any) => item.name}
                    />

                    <SelectCustom
                        list={[
                            { id: 1, name: "Thành công" },
                            { id: 2, name: "Nợ" },
                        ]}
                        placeholder="Trạng Thái"
                        onChange={(value: any) => {
                            ordersSessionMachineStore.dataFilterOrdersSessionMachine.status =
                                Number(value);
                            handleSelectChange();
                        }}
                        allowClear
                        selected={() => {
                            return (
                                Number(
                                    ordersSessionMachineStore
                                        .dataFilterOrdersSessionMachine.status
                                ) || null
                            );
                        }}
                        getLabel={(item: any) => item.name}
                    />
                </BoxFilterSessionDetails>
                <div className="table-container">
                    <div className="table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "25%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Đơn hàng
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Thanh toán
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Nhân viên
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Loại đơn
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Trạng thái đơn
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersSessionMachineStore.isLoading ? (
                                    <tr>
                                        <td
                                            colSpan={7}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : ordersSessionMachineStore
                                      .dataOrdersSessionMachine.length <= 0 ? (
                                    <tr>
                                        <td colSpan={7}>
                                            {" "}
                                            <NoContent />{" "}
                                        </td>
                                    </tr>
                                ) : (
                                    ordersSessionMachineStore
                                        .dataOrdersSessionMachine.length > 0 &&
                                    ordersSessionMachineStore.dataOrdersSessionMachine.map(
                                        (item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className="tableSession-order d-flex flex-column ">
                                                        <p>
                                                            {
                                                                item?.transaction_code
                                                            }
                                                        </p>
                                                        <h4>
                                                            {
                                                                item
                                                                    .customer_info
                                                                    ?.name
                                                            }
                                                        </h4>
                                                        <div className=" d-flex align-items-center gap-1">
                                                            <h2>Tổng đơn</h2>
                                                            <span className="orderSession-price--plus">
                                                                {" "}
                                                                {utils.formatCurrency(
                                                                    Math.abs(
                                                                        item.amount_total
                                                                    )
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span
                                                        className={` ${
                                                            item.cash_amount >=
                                                            0
                                                                ? "orderSession-price--plus"
                                                                : "orderSession-price--minus"
                                                        } `}
                                                    >
                                                        {" "}
                                                        {utils.formatCurrency(
                                                            item.cash_amount
                                                        )}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-1">
                                                        {new Date(
                                                            item.created_at
                                                        ).toLocaleDateString(
                                                            "en-GB"
                                                        )}
                                                        <span>
                                                            {new Date(
                                                                item.created_at
                                                            ).toLocaleTimeString(
                                                                "en-GB",
                                                                {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                }
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="staff d-flex align-items-center gap-2">
                                                        <img
                                                            src={
                                                                item?.user_info
                                                                    ?.avatar
                                                                    ? item
                                                                          .user_info
                                                                          ?.avatar
                                                                    : "/assets/icon/icon_user.svg"
                                                            }
                                                            alt="img-default"
                                                        />
                                                        {item.user_info.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        className={`orderType ${
                                                            item.refund_transaction_id
                                                                ? "orderType--refund"
                                                                : item.transaction_type ===
                                                                  TransactionType.CUSTOMER_PAY_DEBT
                                                                ? "orderType--payDebt"
                                                                : "orderType--simple"
                                                        }`}
                                                    >
                                                        {item.refund_transaction_id
                                                            ? "Hoàn tiền"
                                                            : item.transaction_type ===
                                                              TransactionType.CUSTOMER_PAY_DEBT
                                                            ? "Công nợ"
                                                            : "Đơn thường"}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div
                                                        className={`StatusOrder ${
                                                            item.transaction_type !==
                                                            TransactionType.DEBT_POS
                                                                ? "StatusOrder--success"
                                                                : "StatusOrder--debt"
                                                        }`}
                                                    >
                                                        {item.transaction_type !==
                                                        TransactionType.DEBT_POS
                                                            ? "Thành công"
                                                            : "Nợ"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <Link
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                        to={`/pos/${item.pos_id}/chi-tiet-don-hang/${item.transaction_code}/${item.id}`}
                                                        className="watch"
                                                    >
                                                        <img
                                                            src="/assets/icon/icon_eye.svg"
                                                            alt="eye"
                                                        />
                                                        Xem
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {(ordersSessionMachineStore.metadata.total_page as number) > 1 && (
                <ReactPaginate
                    previousLabel={
                        <img
                            src="/assets/icon/fi-rr-angle-small-left.svg"
                            alt="right"
                        />
                    }
                    nextLabel={
                        <img
                            src="/assets/icon/fi-rr-angle-small-right.svg"
                            alt="right"
                        />
                    }
                    breakLabel={"..."}
                    pageCount={
                        ordersSessionMachineStore.metadata.total_page as number
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handleChangePage}
                    forcePage={
                        ordersSessionMachineStore.staticOrdersSessionMachine
                            .page
                    }
                />
            )}
        </LayoutSessionDetails>
    );
};

export default observer(OrdersSessionMachinePage);
