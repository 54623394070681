import { observer } from "mobx-react";
import {
    Modal,
    Form,
    DatePicker,
    Input,
    DatePickerProps,
    ConfigProvider,
} from "antd";
import { couponStore } from "../CouponStore";
import UploadFile from "../../../../shared/components/form/UploadFile";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect } from "react";
import viVN from "antd/locale/vi_VN"; // Ngôn ngữ tiếng Việt
import "dayjs/locale/vi"; // Cài đặt ngôn ngữ cho dayjs
import { numberByDateNow, utils } from "../../../../common/utils/Utils";
dayjs.locale("vi");
dayjs.extend(customParseFormat);

const PutCoupon = () => {
    const dateFormat = "YYYY-MM-DD";
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(couponStore.initialValues);
    }, [couponStore.initialValues, form]);

    const generateRandomLetters = () => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let result = "";
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * letters.length);
            result += letters[randomIndex];
        }
        couponStore.initialValues.code = result.toUpperCase();
        form.setFieldsValue({ code: result.toUpperCase() });
    };

    const onFinish = (values: any) => {
        couponStore
            .fetchPutCoupon()
            // .then(() => couponStore.handleCancel())
            // .catch((err) => console.log(err));
    };

    const handleChangeDateStart: DatePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        // couponStore.staticCoupon.start_from = dateString as string;
        couponStore.initialValues.start_from = dateString as string;
        form.setFieldsValue({ start_from: dateString as string });
    };

    const handleChangeDateEnd: DatePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        // couponStore.staticCoupon.will_expire = dateString as string;
        couponStore.initialValues.will_expire = dateString as string;
        form.setFieldsValue({ will_expire: dateString as string });
    };

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Sửa mã giảm giá"
                open={couponStore.isModalOpenUpdate}
                onOk={couponStore.handleOK}
                onCancel={couponStore.handleCancel}
            >
                <UploadFile
                    text={`Hình ảnh`}
                    description={`Hình ảnh mã giảm giá`}
                    file={
                        couponStore.initialValues.preview === ""
                            ? ""
                            : couponStore.initialValues.preview === null
                            ? ""
                            : couponStore.initialValues.preview
                    }
                    onIconRemove={couponStore.handleCloseIcon}
                    onFileChange={(e) => {
                        handleFile(e, couponStore.changeFileImage);
                    }}
                    onChooseImg={(url: string)=>{
                        couponStore.initialValues.preview = url
                    }}
                    code={numberByDateNow()}
                />
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={couponStore.initialValues}
                >
                    <div className="item-form">
                        <label className="label-form d-flex justify-content-between">
                            <p
                                style={{
                                    marginBottom: "0px",
                                }}
                            >
                                Mã giảm giá <span>*</span>
                            </p>

                            <p
                                className="btn-createcoupon"
                                style={{
                                    backgroundColor: "#0E72ED",
                                    padding: "4px 10px",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    marginBottom: "0px",
                                }}
                                onClick={generateRandomLetters}
                            >
                                Tạo mã
                            </p>
                        </label>

                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Mã giảm giá là bắt buộc",
                                },
                                {
                                    max: 10,
                                    message:
                                        "Mã giảm giá không được vượt quá 10 ký tự",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && !value.trim()) {
                                            return Promise.reject(
                                                new Error(
                                                    "Mã giảm giá là bắt buộc"
                                                )
                                            );
                                        }
                                        const specialCharPattern = /[#!@$%^&*]/;
                                        if (specialCharPattern.test(value)) {
                                            return Promise.reject(
                                                new Error(
                                                    "Mã giảm giá không được chứa ký tự đặc biệt như: #!@$%^&*"
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    textTransform: "uppercase",
                                }}
                                placeholder="Mã giảm giá"
                                value={couponStore.initialValues.code}
                                onChange={(e) => {
                                    couponStore.initialValues.code =
                                        e.target.value;
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Loại giảm giá <span>*</span>
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.initialValues.is_percentage = value;
                                couponStore.initialValues.value = 0;
                            }}
                            selected={() =>
                                couponStore.initialValues.is_percentage
                            }
                            textOn="Phần trăm"
                            textOff="Giảm giá cố định"
                        />
                    </div>

                    <div className="item-form ">
                        <label
                            className="label-form"
                            title="Nhập số tiền giảm theo giảm giá cố định. Nhập số % theo giảm giá %"
                        >
                            Số giảm/Phần trăm giảm <span>*</span>
                        </label>

                        <Form.Item
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Số tiền giảm/Phần trăm giảm là bắt buộc",
                                },
                                {
                                    pattern: /^[0-9]+$/,
                                    message:
                                        "Số tiền giảm/Phần trăm giảm chỉ được chứa số",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Số tiền giảm/Phần trăm giảm"
                                value={
                                    couponStore.initialValues.is_percentage ===
                                    "1"
                                        ? couponStore.initialValues.value
                                        : couponStore.initialValues
                                              .is_percentage === "0"
                                        ? utils.formatCurrency(
                                              couponStore.initialValues.value
                                          )
                                        : ""
                                }
                                onChange={(e) => {
                                    let valueRepalce = e.target.value.replace(
                                        /[.,đ]/g,
                                        ""
                                    );
                                    let value = parseFloat(valueRepalce);
                                    if (isNaN(value)) {
                                        couponStore.initialValues.value = 0;
                                    } else {
                                        if (
                                            couponStore.initialValues
                                                .is_percentage === "1"
                                        ) {
                                            value = Math.min(
                                                Math.max(value, 1),
                                                100
                                            );
                                        }
                                        couponStore.initialValues.value = value;
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Có điều kiện <span>*</span>
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.initialValues.is_conditional =
                                    value;
                            }}
                            selected={() =>
                                couponStore.initialValues.is_conditional
                            }
                            textOn="Có"
                            textOff="Không"
                        />
                    </div>

                    {couponStore.initialValues.is_conditional === "1" ? (
                        <div className="item-form">
                            <label className="label-form">
                                Số tiền tối thiểu <span>*</span>
                            </label>

                            <Form.Item
                                name="min_amount"
                                rules={[
                                    {
                                        max: 10,
                                        message:
                                            "Số tiền tối thiểu không được vượt quá 10 ký tự",
                                    },
                                    {
                                        pattern: /^[0-9]+$/,
                                        message:
                                            "Số tiền tối thiểu chỉ được chứa số",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    placeholder="Số tiền tối thiểu"
                                    value={couponStore.initialValues.min_amount}
                                    onChange={(e) =>
                                        (couponStore.initialValues.min_amount =
                                            parseFloat(e.target.value))
                                    }
                                />
                            </Form.Item>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="item-form">
                        <label
                            className="label-form"
                            title="Ngày bắt đầu giảm giá"
                        >
                            Bắt đầu từ <span>*</span>
                        </label>
                        <Form.Item
                            name="start_from"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn ngày bắt đầu!",
                                },
                            ]}
                        >
                            <ConfigProvider locale={viVN}>
                                <DatePicker
                                    defaultValue={dayjs(
                                        `${couponStore.initialValues.start_from}`,
                                        dateFormat
                                    )}
                                    onChange={handleChangeDateStart}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label
                            className="label-form"
                            title="Ngày bắt đầu giảm giá"
                        >
                            Sẽ hết hạn <span>*</span>
                        </label>
                        <Form.Item
                            name="will_expire"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn ngày kết thúc!",
                                },
                            ]}
                        >
                            <ConfigProvider locale={viVN}>
                                <DatePicker
                                    onChange={handleChangeDateEnd}
                                    defaultValue={dayjs(
                                        `${couponStore.initialValues.will_expire}`,
                                        dateFormat
                                    )}
                                    minDate={dayjs(
                                        `${couponStore.initialValues.start_from}`,
                                        dateFormat
                                    )}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Trạng thái
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.initialValues.status = value;
                            }}
                            selected={() => couponStore.initialValues.status}
                            textOn="Hoạt động"
                            textOff="Vô hiệu hóa"
                        />
                    </div>

                    <div className="btnCreateModal">
                        <button
                            className="cancelModal"
                            type="reset"
                            onClick={() => couponStore.handleCancel()}
                        >
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={couponStore.isLoadingBtn}
                        >
                            {couponStore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(PutCoupon);
