import React, { useEffect } from "react";
import "./ConfigMail.scss";
import LayoutSettings from "../../components/layout/LayoutSettings";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import { Divider, Form, Input } from "antd";
import { mailStore, TFormConfigMail } from "../MailStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../shared/components/loading/Loading";

const ConfigMailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const formatConfigMail =
    mailStore.dataConfigMail?.length > 0 &&
    Object.fromEntries(
      mailStore.dataConfigMail.map((item: any) => [item.key, item.value])
    );

  useEffect(() => {
    if (formatConfigMail) {
      form.setFieldsValue({
        username: formatConfigMail.MAIL_USERNAME,
        password: formatConfigMail.MAIL_PASSWORD,
        domain: formatConfigMail.MAIL_DOMAIN || formatConfigMail.MAILGUN_DOMAIN,
        secret_key: formatConfigMail.MAILGUN_SECRET,
        mail_address: formatConfigMail.MAIL_FROM_ADDRESS,
        mail_name: formatConfigMail.MAIL_FROM_NAME,
        mailer: formatConfigMail.MAIL_MAILER || "smtp",
        host: formatConfigMail.MAIL_HOST || (mailStore.typeService === "mailgun" ? "smtp.mailgun.org" : "smtp.gmail.com"),
      });
      mailStore.dataFormConfigMail.encryption =
        formatConfigMail.MAIL_ENCRYPTION;
    }
  }, [form, mailStore.dataConfigMail]);

  const updateUrlParams = () => {
    const searchParams = new URLSearchParams();

    if (mailStore.dataFormConfigMail.using) {
      searchParams.set("service", mailStore.dataFormConfigMail.using);
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    if (formatConfigMail) {
      mailStore.dataFormConfigMail.encryption =
        mailStore.dataFormConfigMail.encryption ||
        formatConfigMail.MAIL_ENCRYPTION;
      mailStore.dataFormConfigMail.using =
        mailStore.dataFormConfigMail.using || mailStore.dataConfigMail.using;
    }
  }, [formatConfigMail]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("service")) {
      mailStore.typeService = query.get("service")
        ? String(query.get("service"))
        : "mailgun";

      mailStore.dataFormConfigMail.using = query.get("service")
        ? String(query.get("service"))
        : "mailgun";
      mailStore.getConfigMail();
    } else {
      mailStore.typeService = "mailgun";
      mailStore.dataFormConfigMail.using = "mailgun";
      mailStore.getConfigMail();
    }
  }, [location.search]);

  return (
    <LayoutSettings keyActive={"cau-hinh-mail"}>
      <div
        className={`ConfigMail-container ${
          mailStore.isLoading ? "disable-screen" : ""
        }`}
      >
        <div className="AdminPage-header">
          <h1 className="title">Cấu Hình Mail</h1>
        </div>

        <div className="ConfigMail-body">
          <div className="ConfigMail-sendMail">
            <SelectCustom
              list={[
                { id: "mailgun", name: "Mailgun" },
                { id: "mailer", name: "Mailer" },
              ]}
              placeholder="Nhà cung cấp dịch vụ"
              showSearch
              onChange={(value: any) => {
                mailStore.dataFormConfigMail.using = value;
                updateUrlParams();
              }}
              selected={() => {
                return mailStore.dataFormConfigMail.using || "mailer";
              }}
              getLabel={(item: any) => item.name}
            ></SelectCustom>

            {/* <div className="btn-send">
              <i className="fas fa-paper-plane"></i>
            </div> */}
          </div>
          <Divider />

          {mailStore.isLoading ? (
            <Loading />
          ) : (
            <Form
              form={form}
              onFinish={async (values: any) => {
                mailStore.dataFormConfigMail.username = values.username;
                mailStore.dataFormConfigMail.password = values.password;
                mailStore.dataFormConfigMail.domain = values.domain;
                mailStore.dataFormConfigMail.secret_key = values.secret_key;
                mailStore.dataFormConfigMail.mail_address = values.mail_address;
                mailStore.dataFormConfigMail.mail_name = values.mail_name;
                mailStore.dataFormConfigMail.mailer = values.mailer;
                mailStore.dataFormConfigMail.host = values.host;

                await mailStore.updateConfigMail();
              }}
            >
              <div className="form-configMail">
                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="Mailer"
                  name="mailer"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="Host"
                  name="host"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    // {
                    //   type: "email",
                    //   message: "Định dạng email không hợp lệ!",
                    // },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="Domain"
                  name="domain"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                {mailStore.dataFormConfigMail.using === "mailgun" && (
                  <Form.Item<TFormConfigMail>
                    className="item-form"
                    label="Secret Key"
                    name="secret_key"
                  >
                    <Input placeholder="Vui lòng nhập" />
                  </Form.Item>
                )}

                <div className="item-form">
                  <Form.Item<TFormConfigMail> label="Encription"
                    >
                    <SelectCustom
                      list={[
                        { id: "tls", name: "TLS(Mã Hóa Tiêu Chuẩn)" },
                        { id: "s/mime", name: "S/MIME(Mã Hóa Nâng Cao)" },
                      ]}
                      placeholder="---Chọn---"
                      showSearch
                      onChange={(value: any) => {
                        mailStore.dataFormConfigMail.encryption = value;
                      }}
                      selected={() => {
                        return mailStore.dataFormConfigMail.encryption || "tls";
                      }}
                      getLabel={(item: any) => item.name}
                    ></SelectCustom>
                  </Form.Item>
                </div>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="From Address "
                  name="mail_address"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    {
                      type: "email",
                      message: "Định dạng email không hợp lệ!",
                    },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <Form.Item<TFormConfigMail>
                  className="item-form"
                  label="From Name"
                  name="mail_name"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                    { max: 255, message: "Tối đa 255 ký tự" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className={`submitForm`}
                  disabled={mailStore.isLoadingBtn}
                  type="submit"
                >
                  {mailStore.isLoadingBtn ? "Vui Lòng Chờ..." : "Cập nhật"}
                </button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </LayoutSettings>
  );
};

export default observer(ConfigMailPage);
