import {getRequest, postRequest} from "../../../common/helpers/RequestHelper"

class PaymentService{
    apiLocation = "api/v1/pos/ecommerce/order/save_order"
    apiGenerateQr = "api/v1/payment/pos/generateQr"
    public fetchSaveOrderPosEcommerce(params:any): Promise<any>{
        return postRequest(this.apiLocation,params);
    }
    public fetchGenerateQr(params:any): Promise<any>{
        return getRequest(this.apiGenerateQr,params);
    }
}

export const paymentService = new PaymentService();