import React, { useEffect } from "react";
import viVN from "antd/es/locale/vi_VN";
import "dayjs/locale/vi"; // Locale cho Day.js
import dayjs from "dayjs";
import "./styles/DetailMachinePos.scss";
import LayoutSessionDetails from "../components/LayoutSessionDetails";
import BoxFilterSessionDetails from "../components/BoxFilterSessionDetails";
import { Avatar, ConfigProvider, DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { detailMachinePosStore } from "./DetailMachinePosStore";
import { observer } from "mobx-react";
import ReactPaginate from "react-paginate";
import {
    handleKeyPressSpecialCharacters,
    utils,
} from "../../../common/utils/Utils";
import { toastUtils } from "../../../common/utils/Toastutils";
const { RangePicker } = DatePicker;

const DetailMachinePosPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const start = dayjs().subtract(3, "month").format("DD-MM-YYYY");
    const end = dayjs().endOf("day").format("DD-MM-YYYY");

    useEffect(() => {
        detailMachinePosStore.fetchAllStaffList();
    }, []);

    // const handleChangeDate: RangePickerProps["onChange"] = (
    //     date,
    //     dateString
    // ) => {
    //     const start: string = dateString[0];
    //     const end: string = dateString[1];
    //     detailMachinePosStore.dataFilterDetailMachinePos.start_date = start;
    //     detailMachinePosStore.dataFilterDetailMachinePos.end_date = end;
    //     handleSelectChange(true);
    // };

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        const start = dateString[0];
        const end = dateString[1];

        if (start && end) {
            const startDate = dayjs(start, "DD-MM-YYYY");
            const endDate = dayjs(end, "DD-MM-YYYY");

            // Kiểm tra nếu ngày kết thúc vượt quá 3 tháng kể từ ngày bắt đầu
            const maxEndDate = startDate.add(3, "month"); // Ngày kết thúc tối đa là 3 tháng sau ngày bắt đầu

            if (endDate.isAfter(maxEndDate)) {
                toastUtils.warning(
                    "Thời gian tìm kiếm không được quá 3 tháng !"
                );

                detailMachinePosStore.dataFilterDetailMachinePos.end_date = "";

                return;
            }
        }

        detailMachinePosStore.dataFilterDetailMachinePos.start_date = start;
        detailMachinePosStore.dataFilterDetailMachinePos.end_date = end;

        handleSelectChange();
    };

    const getDefaultPickerValue = () => {
        const start = dayjs().subtract(3, "month").startOf("day");
        const end = dayjs().endOf("day");
        return [start, end];
    };

    const dataStaff =
        detailMachinePosStore.dataAllStaff?.length > 0
            ? detailMachinePosStore.dataAllStaff.map((item: any) => ({
                  id: item.id,
                  name: item.name,
              }))
            : [];

    const updateUrlParams = () => {
        const searchParams = new URLSearchParams();

        if (detailMachinePosStore.dataFilterDetailMachinePos.search) {
            searchParams.set(
                "search",
                detailMachinePosStore.dataFilterDetailMachinePos.search
            );
        }
        if (detailMachinePosStore.dataFilterDetailMachinePos.status) {
            searchParams.set(
                "status",
                detailMachinePosStore.dataFilterDetailMachinePos
                    .status as string
            );
        }
        if (detailMachinePosStore.dataFilterDetailMachinePos.user_id) {
            searchParams.set(
                "user_id",
                detailMachinePosStore.dataFilterDetailMachinePos
                    .user_id as string
            );
        }
        if (detailMachinePosStore.dataFilterDetailMachinePos.start_date) {
            searchParams.set(
                "start_date",
                detailMachinePosStore.dataFilterDetailMachinePos.start_date
            );
        }

        if (detailMachinePosStore.dataFilterDetailMachinePos.end_date) {
            searchParams.set(
                "end_date",
                detailMachinePosStore.dataFilterDetailMachinePos.end_date
            );
        }
        detailMachinePosStore.staticDetailMachinePos.page = 0;

        navigate({ search: searchParams.toString() }, { replace: true });
    };

    const handleSearch = () => {
        updateUrlParams();
    };

    const handleSelectChange = () => {
        updateUrlParams();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        detailMachinePosStore.dataFilterDetailMachinePos.search =
            searchParams.get("search") || "";
        detailMachinePosStore.dataFilterDetailMachinePos.status =
            Number(searchParams.get("status")) || null;
        detailMachinePosStore.dataFilterDetailMachinePos.user_id =
            Number(searchParams.get("user_id")) || null;
        detailMachinePosStore.dataFilterDetailMachinePos.start_date =
            searchParams.get("start_date") || start;
        detailMachinePosStore.dataFilterDetailMachinePos.end_date =
            searchParams.get("end_date") || end;
        detailMachinePosStore.staticDetailMachinePos.page = searchParams.get(
            "page"
        )
            ? Number(searchParams.get("page")) - 1
            : 0;
        detailMachinePosStore.fetchAllDetailsSessionMachine(id);
    }, [location.search]);

    const handleRemoveFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        if (
            detailMachinePosStore.dataFilterDetailMachinePos.search ||
            detailMachinePosStore.dataFilterDetailMachinePos.status ||
            detailMachinePosStore.dataFilterDetailMachinePos.user_id ||
            searchParams.get("start_date") ||
            searchParams.get("end_date")
        ) {
            detailMachinePosStore.dataFilterDetailMachinePos.search = "";
            detailMachinePosStore.dataFilterDetailMachinePos.status = null;
            detailMachinePosStore.dataFilterDetailMachinePos.user_id = null;
            detailMachinePosStore.dataFilterDetailMachinePos.start_date = start;
            detailMachinePosStore.dataFilterDetailMachinePos.end_date = end;
            detailMachinePosStore.staticDetailMachinePos.page = 0;

            navigate({ search: "" }, { replace: true });
        }
    };

    const handleChangePage = (e: any) => {
        detailMachinePosStore.staticDetailMachinePos.page = e.selected;
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(
            "page",
            (detailMachinePosStore.staticDetailMachinePos.page + 1).toString()
        );
        navigate({
            search: searchParams.toString(),
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        detailMachinePosStore.dataFilterDetailMachinePos.start_date = start;
        detailMachinePosStore.dataFilterDetailMachinePos.end_date = end;
    }, []);

    return (
        <LayoutSessionDetails
            name={`${
                detailMachinePosStore?.metadata?.pos_name
                    ? `Phiên máy ${detailMachinePosStore?.metadata?.pos_name}`
                    : `Phiên máy Máy Pos ${id}`
            }`}
            onClickBack={() => {
                navigate("/danh-sach-pos");
            }}
        >
            <div
                className={`DetailMachinePosContainer ${
                    detailMachinePosStore.isLoading ? "disableScreen" : ""
                }`}
            >
                <BoxFilterSessionDetails
                    handleRemoveFilter={handleRemoveFilters}
                >
                    <form
                        className="filter-search"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                    >
                        <input
                            type="text"
                            name="keyword"
                            onKeyPress={handleKeyPressSpecialCharacters}
                            value={
                                detailMachinePosStore.dataFilterDetailMachinePos
                                    .search
                            }
                            id="keyword"
                            onChange={(e: any) => {
                                detailMachinePosStore.dataFilterDetailMachinePos.search =
                                    e.currentTarget.value;
                            }}
                            placeholder="Tìm kiếm theo tên hoặc mã đơn"
                        />
                        <button type="submit">
                            <img src="/assets/icon/search.svg" alt="search" />
                        </button>
                    </form>

                    <RangePicker
                        placeholder={["Bắt đầu", "Kết thúc"]}
                        style={{
                            cursor: "pointer",
                        }}
                        format="DD-MM-YYYY"
                        value={[
                            detailMachinePosStore.dataFilterDetailMachinePos
                                .start_date
                                ? dayjs(
                                      detailMachinePosStore
                                          .dataFilterDetailMachinePos
                                          .start_date,
                                      "DD-MM-YYYY"
                                  )
                                : null,
                            detailMachinePosStore.dataFilterDetailMachinePos
                                .end_date
                                ? dayjs(
                                      detailMachinePosStore
                                          .dataFilterDetailMachinePos.end_date,
                                      "DD-MM-YYYY"
                                  )
                                : null,
                        ]}
                        defaultValue={
                            getDefaultPickerValue() as [
                                dayjs.Dayjs,
                                dayjs.Dayjs
                            ]
                        } // Chỉ định kiểu cho TypeScript
                        onChange={handleChangeDate}
                    />

                    <SelectCustom
                        list={dataStaff && dataStaff}
                        placeholder="Nhân viên"
                        showSearch
                        allowClear
                        onChange={(value: any) => {
                            detailMachinePosStore.dataFilterDetailMachinePos.user_id =
                                Number(value);
                            handleSelectChange();
                        }}
                        selected={() => {
                            return (
                                detailMachinePosStore.dataAllStaff?.find(
                                    (item) =>
                                        item.id ===
                                        detailMachinePosStore
                                            .dataFilterDetailMachinePos.user_id
                                )?.id || null
                            );
                        }}
                        getLabel={(item: any) => item.name}
                    ></SelectCustom>

                    <SelectCustom
                        list={[
                            { id: 1, name: "Đóng" },
                            { id: 2, name: "Mở" },
                        ]}
                        placeholder="Trạng Thái"
                        onChange={(value: any) => {
                            detailMachinePosStore.dataFilterDetailMachinePos.status =
                                value;
                            handleSelectChange();
                        }}
                        allowClear
                        selected={() => {
                            return (
                                detailMachinePosStore.dataFilterDetailMachinePos
                                    .status || null
                            );
                        }}
                        getLabel={(item: any) => item.name}
                    />
                </BoxFilterSessionDetails>
                <div className="table-container">
                    <div className="table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        ID phiên
                                    </th>
                                    <th
                                        style={{
                                            width: "16%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Điểm bán hàng
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Được mở bởi
                                    </th>
                                    <th
                                        style={{
                                            width: "10%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Doanh thu
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày mở
                                    </th>
                                    <th
                                        style={{
                                            width: "15%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Ngày đóng
                                    </th>
                                    <th
                                        style={{
                                            width: "14%",
                                            textAlign: "left",
                                        }}
                                    >
                                        Trạng thái
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {detailMachinePosStore.isLoading ? (
                                    <tr>
                                        <td
                                            colSpan={7}
                                            style={{ textAlign: "center" }}
                                        >
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : detailMachinePosStore.dataDetailsMachinePos
                                      .length <= 0 ? (
                                    <tr>
                                        <td colSpan={7}>
                                            {" "}
                                            <NoContent />{" "}
                                        </td>
                                    </tr>
                                ) : (
                                    detailMachinePosStore.dataDetailsMachinePos
                                        .length > 0 &&
                                    detailMachinePosStore.dataDetailsMachinePos.map(
                                        (item) => (
                                            <tr
                                                key={item.id}
                                                className="data-row"
                                                onClick={() =>
                                                    navigate(
                                                        `/pos/${item.pos_id}/danh-sach-don-hang-phien-may/${item.id}`
                                                    )
                                                }
                                            >
                                                <td>
                                                    <div className="d-flex flex-column ">
                                                        {item.session_name}
                                                    </div>
                                                </td>
                                                <td>{item.location_name}</td>
                                                <td>
                                                    <div className="staff d-flex align-items-center gap-2">
                                                        <img
                                                            src={
                                                                item.staff
                                                                    .avatar
                                                                    ? item.staff
                                                                          .avatar
                                                                    : "/assets/icon/icon_user.svg"
                                                            }
                                                            alt="img-default"
                                                        />
                                                        {item.staff.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    {utils.formatCurrency(
                                                        item.revenue
                                                    )}
                                                </td>
                                                <td>
                                                    {item.created_at ? (
                                                        <div className="d-flex align-items-center gap-1">
                                                            {new Date(
                                                                item.created_at
                                                            ).toLocaleDateString(
                                                                "en-GB"
                                                            )}
                                                            <span>
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleTimeString(
                                                                    "en-GB",
                                                                    {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                    }
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                                <td>
                                                    {" "}
                                                    {item.end_working
                                                        ? new Date(
                                                              item.end_working
                                                          ).toLocaleDateString(
                                                              "en-GB"
                                                          )
                                                        : ""}
                                                </td>
                                                <td>
                                                    <div
                                                        className={`status ${
                                                            !item.end_working
                                                                ? "status--open"
                                                                : "status--close"
                                                        }`}
                                                    >
                                                        {!item.end_working
                                                            ? "Mở"
                                                            : "Đóng"}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {(detailMachinePosStore.metadata.total_page as number) > 1 && (
                <ReactPaginate
                    previousLabel={
                        <img
                            src="/assets/icon/fi-rr-angle-small-left.svg"
                            alt="right"
                        />
                    }
                    nextLabel={
                        <img
                            src="/assets/icon/fi-rr-angle-small-right.svg"
                            alt="right"
                        />
                    }
                    breakLabel={"..."}
                    pageCount={
                        detailMachinePosStore.metadata.total_page as number
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handleChangePage}
                    forcePage={
                        detailMachinePosStore.staticDetailMachinePos.page
                    }
                />
            )}
        </LayoutSessionDetails>
    );
};

export default observer(DetailMachinePosPage);
