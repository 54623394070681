import {getRequest, postRequest} from "../../../common/helpers/RequestHelper";
class FilesService {
    apiSubdirectory = 'api/v1/medias' ;
    apiDelete = 'api/v1/medias/remove-folder';
    apiDeleteImage = 'api/v1/medias/deletefile';
    apiAddFolder = 'api/v1/medias/create-folder';
    apiChangeNameImage = 'api/v1/medias/change-name-image';
    // apiUpload = 'api/v1/medias/upload';

    public fetchMediaList(ParamFiles: any): Promise<any> {
        const queryParams = [
            ParamFiles.path ? `path=${ParamFiles.path}` : "",
            ParamFiles.page ? `page=${ParamFiles.page}` : "",
            ParamFiles.search ? `search=${ParamFiles.search}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSubdirectory}?${queryParams}`);
    }

    public deleteFile(fileData: any): Promise<any> {
        return postRequest(`${this.apiDelete}`, fileData);
    }

    public addFolder(folderData: any): Promise<any> {
        return postRequest(`${this.apiAddFolder}`, folderData);
    }

    public changeNameImage(data: any): Promise<any> {
        return postRequest(`${this.apiChangeNameImage}`, data);
    }

    // public uploadFile(data: any): Promise<any> {
    //     return postRequest(`${this.apiUpload}`, data);
    // }

    public deleteFileImage(data: any): Promise<any> {
        return postRequest(`${this.apiDeleteImage}`, data);
    }

}

export const filesService = new FilesService()