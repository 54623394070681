import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { useState} from "react";
import { Collapse } from "react-bootstrap";
import { inventoryStore } from "../InventoryStore";

const FilterProductType = () => {
    const [filterOpen, setFilterOpen] = useState(true);

    return (
        <>
            <div className="filter-tab">
                <Button className="filter-collapse-btn" onClick={() => setFilterOpen(!filterOpen)}
                        aria-controls="example-collapse-text" aria-expanded={filterOpen}>
                    <span>Loại sản phẩm</span>
                    <i className="fas fa-chevron-down"></i>
                </Button>
                {inventoryStore.isLoading === true ? (<></>) : (
                    <Collapse in={filterOpen}>
                        <div className="filter-tab__body">
                            <div className="filter-tab__option">
                                <input
                                    checked={inventoryStore.selectedProductType === 1}
                                    value={1}
                                    type="radio"
                                    name="product_type"
                                    id="variant"
                                    onClick={() => {
                                        inventoryStore.handleProductTypeSelect(1);
                                    }}
                                />
                                <label className="radio-button"
                                       style={{
                                           overflow: "hidden",
                                           textOverflow: "ellipsis",
                                           whiteSpace: "nowrap",
                                       }}
                                       htmlFor="variant">
                                    Sản phẩm biến thể
                                </label>
                            </div>
                            <div className="filter-tab__option">
                                <input
                                    checked={inventoryStore.selectedProductType === 0}
                                    value={0}
                                    type="radio"
                                    name="product_type"
                                    id="simple"
                                    onClick={() => {
                                        inventoryStore.handleProductTypeSelect(0);
                                    }}
                                />
                                <label className="radio-button"
                                       style={{
                                           overflow: "hidden",
                                           textOverflow: "ellipsis",
                                           whiteSpace: "nowrap",
                                       }}
                                       htmlFor="simple">
                                    Sản phẩm đơn giản
                                </label>
                            </div>
                        </div>
                    </Collapse>
                )}
            </div>
        </>
    );
}

export default observer(FilterProductType);
