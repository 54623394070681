import { observer } from "mobx-react"
import { posStore } from "./PosStore";
import { Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Switch} from "antd";
import "./styles/pos.css"
import {createSearchParams, useLocation, useNavigate} from "react-router-dom";
import ActionModal from "./components/ActionModal";
import ReactPaginate from "react-paginate";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
import {showDelete} from "../../../shared/components/showDelete";
import LayoutSettings from "../components/layout/LayoutSettings";
import FilterAdmin from "./components/FilterAdmin";

const Pos = ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    function onSubmitSearch(event:any){
        const searchKeyword = posStore.staticParamPos.search;
        event.preventDefault();
        const params: any = {}
        if(searchKeyword !== ''){
            params.keyword = `${searchKeyword}`;
            console.log(params)
        }
        navigate({
            search: createSearchParams(params).toString()
        })
        posStore.getPosWithRequirement(true)
    }
    function handleSelect(value: boolean, name: string) {
        if (value) {
            posStore.selectedPosInTable = ([...posStore.selectedPosInTable, name]);
        } else {
            posStore.selectedPosInTable = (posStore.selectedPosInTable.filter((item) => item !== name));
        }
    }
    function selectAllTargetInTable(value: boolean){
        if (value) {
            posStore.selectedPosInTable = (posStore.listPos.map((item: any) => item.id));
        } else {
            posStore.selectedPosInTable = ([]);
        }
    }
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (posStore.staticParamPos.search !== "")
            params.keyword =
                posStore.staticParamPos.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        posStore.staticParamPos.page = selectedPage;
        posStore.getPosWithRequirement(true);
    };
    function onHandleKeyDown(event:any){
        if(event.key === 'Enter'){
            onSubmitSearch(event)
        }
    }
    useEffect(()=>{
        posStore.getStoreAll().then()

    },[])
    useEffect(()=>{
        const query = new URLSearchParams(window.location.search);
        posStore.staticParamPos.search = query.get("search")
            ? String(query.get("search"))
            : "";
        posStore.searchTemp = query.get("search")
            ? String(query.get("search"))
            : "";
        posStore.staticParamPos.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        posStore.staticParamPos.status = query.get("status")
            ? String(query.get("status"))
            : null;
        posStore.staticParamPos.store_id = query.get("store_id")
            ? Number(query.get("store_id"))
            : null;
        posStore.getPosWithRequirement().then()
    },[location.search])
    return(
        <>
            <LayoutSettings keyActive={"may-pos"}>
                <div className="AdminPage">
                    <div className="AdminPage-header">
                        <h1 className="title">Danh sách máy pos</h1>
                        <FilterAdmin/>
                    </div>
                    <div className="AdminPage-body">
                        <div className="table-container">
                            <div className="table-responsive">
                                <table className="table table-striped" id="table-2">
                                    <thead>
                                    <tr>
                                        <th style={{width: '30%'}}>Tên máy pos</th>
                                        <th style={{width: '30%'}}>Địa điểm</th>
                                        <th style={{width: '10%'}}>Trạng thái</th>
                                        <th style={{width: '15%'}}>Ngày tạo</th>
                                        <th style={{width: "15%"}}>Thao tác</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {posStore.loadingDataTable === true ? (<tr>
                                            <td colSpan={6}><Loading/></td>
                                        </tr>) :
                                        (<>
                                            {posStore.listPos.length === 0 ? (<tr>
                                                <td colSpan={5}><NoContent/></td>
                                            </tr>) : (<>
                                                {posStore.listPos.map(
                                                    (item: any, index: number) => (
                                                        <>
                                                            <tr key={index}>
                                                                <td>
                                                                    <span className="text-truncate d-block" style={{maxWidth: "300px"}} title={item.name}>{item.name}</span>
                                                                </td>
                                                                <td>{posStore.listStore.find((store) => store.id === item.location_id)?.name}</td>
                                                                <td>
                                                                    <Switch size="default"
                                                                            defaultChecked={item.status === 1 ? true : false}
                                                                            onChange={(event) => {
                                                                                posStore.handleSwitchStatusPos(item, event);
                                                                                item.status = (event === true ? 1 : 0)
                                                                            }}/>
                                                                </td>
                                                                <td>
                                                                    <p>{new Date(item.created_at).toLocaleDateString('en-GB')}</p>
                                                                </td>
                                                                <td>
                                                                    <Dropdown align={"start"}
                                                                              className="action-table">
                                                                        <Dropdown.Toggle>
                                                                            Thao tác
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => {
                                                                                posStore.isCreating = false;
                                                                                posStore.modalName = 'Sửa máy POS'
                                                                                posStore.staticDataPos.name = item.name;
                                                                                posStore.staticDataPos.id = String(item.id);
                                                                                posStore.staticDataPos.status = String(item.status);
                                                                                posStore.staticDataPos.store_id = String(item.location_id);
                                                                                posStore.modalOpen = true;
                                                                            }}>
                                                                                <i className="fa fa-edit"></i>
                                                                                Sửa
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onClick={() => {
                                                                                showDelete.showDeleteConfirm('Xác nhận xoá thuộc tính', `Bạn chắc chắn muốn xoá máy POS "${item.name}" không?`, () => {
                                                                                    posStore.deletePos()
                                                                                });
                                                                                posStore.staticDataPos.id = item.id
                                                                            }}>
                                                                                <i className="fa fa-trash"></i>
                                                                                Xóa
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                            </>)}
                                        </>)}

                                    </tbody>
                                </table>
                            </div>
                                {posStore.loadingDataTable
                                    ? ""
                                    : Math.ceil(
                                    posStore.metadata.total_page
                                ) > 1 && (
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-left.svg"
                                                alt="right"
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src="/assets/icon/fi-rr-angle-small-right.svg"
                                                alt="right"
                                            />
                                        }
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(
                                            posStore.metadata
                                                .total_page
                                        )}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        forcePage={
                                            posStore.staticParamPos.page
                                        }
                                    />
                                )}
                        </div>
                    </div>
                </div>
                <ActionModal/>
            </LayoutSettings>
        </>
    )
}
export default observer(Pos)
