import React, { useEffect } from "react";
import "../../../styles/FormPaymentSettings.scss";
import { Form, Input, Switch } from "antd";
import { observer } from "mobx-react";
import { paymentSettingsStore } from "../../../PaymentSettingsStore";
const FormMomo = ({ itemForm }: { itemForm: any }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      partner_code: itemForm.data.partner_code,
      access_key: itemForm.data.access_key,
      secret_key: itemForm.data.secret_key,
    });
    paymentSettingsStore.dataFormMomo.status = itemForm.status;
  }, [form, itemForm]);

  return (
    <Form
      form={form}
      onFinish={async (values: any) => {
        paymentSettingsStore.idPayment = itemForm.id;
        paymentSettingsStore.dataFormMomo.partner_code = values.partner_code;
        paymentSettingsStore.dataFormMomo.access_key = values.access_key;
        paymentSettingsStore.dataFormMomo.secret_key = values.secret_key;
        await paymentSettingsStore.updatePayment("Momo");
      }}
      className="FormPaymentSettings"
    >
      <Form.Item<any>
        className="item-form"
        label="Partner Code"
        name="partner_code"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <Form.Item<any>
        className="item-form"
        label="Access Key"
        name="access_key"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>
      <Form.Item<any>
        className="item-form"
        label="Secret Key"
        name="secret_key"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <div className="FormPaymentSettings-switch">
        <h1>Trạng thái</h1>
        <Switch
          size="default"
          defaultChecked={itemForm.status === 1 ? true : false}
          //   disabled={item.super_admin === 1}
          onChange={async (e) => {
            paymentSettingsStore.dataFormMomo.status = e ? 1 : 0;
          }}
        />
      </div>

      <button
        className={`updateForm`}
        disabled={paymentSettingsStore.isLoadingMomo}
        type="submit"
      >
        {paymentSettingsStore.isLoadingMomo ? "Vui lòng chờ..." : " Cập nhật"}
      </button>
    </Form>
  );
};

export default observer(FormMomo);
