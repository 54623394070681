import { observer } from 'mobx-react';
import React from 'react';

const NoPos = () => {
    return (
        <div className='no-content__container'>
            <img src="/assets/images/Search-none.svg" alt="none" />
            <div>Địa điểm này chưa có máy Pos</div>
        </div>
    );
}

export default observer(NoPos);