import React, {useEffect, useState} from "react";
import {useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import {roomStore} from "../RoomStore";
import useDebounce from "../../../common/customHook/useDebounce";
import SelectCustom from "../../../shared/components/form/SelectCustom";
const FilterRoom = () => {
    const [filteredRooms, setFilteredRooms] = useState(roomStore.listRoomAll);
    const navigate = useNavigate();
    const debouncedSearchStaff = useDebounce(roomStore.searchTemp, 300);

    useEffect(() => {
        const updateFilteredRooms = () => {
            if (roomStore.staticParamRoom.store_id) {
                setFilteredRooms(
                    roomStore.listRoomAll.filter(
                        (room: any) => room.location_id === roomStore.staticParamRoom.store_id
                    )
                );
            } else {
                setFilteredRooms(roomStore.listRoomAll);
            }
        };
        updateFilteredRooms();
    }, [roomStore.staticParamRoom.store_id, roomStore.listRoomAll]);

    const updateUrlParams = (isSearchTriggered:boolean) => {
        const searchParams = new URLSearchParams();

        if (debouncedSearchStaff) {
            searchParams.set("search", debouncedSearchStaff);
        }
        if (roomStore.staticParamRoom.parent_id) {
            searchParams.set("parent_id", roomStore.staticParamRoom.parent_id);
        }
        if (roomStore.staticParamRoom.store_id) {
            searchParams.set("store_id", `${roomStore.staticParamRoom.store_id}`);
        }
        roomStore.staticParamRoom.page = 0;
        navigate({ search: searchParams.toString() }, { replace: true });

        if (isSearchTriggered) {
            roomStore.getRoomWithRequirement();
        }
    };

    useEffect(() => {
        // roomStore.allowSearch: khi load lại trang không bay vào đây tránh mất search trên url
        if (roomStore.allowSearch && debouncedSearchStaff !== roomStore.staticParamRoom.search) {
            roomStore.staticParamRoom.search = debouncedSearchStaff;
            updateUrlParams(true);
        }
    }, [debouncedSearchStaff]);

    const handleSelectChange = (isSearchTriggered:boolean) => {
        updateUrlParams(isSearchTriggered);
    };
    return (
        <div className="box-filter">
            <div className="search">
                <img src="/assets/icon/searchModel.svg" alt="searchModel" />
                <input
                    type="text"
                    placeholder="Tìm kiếm theo tên ..."
                    value={roomStore.searchTemp}
                    onChange={(e: any) => {
                        roomStore.searchTemp = e.target.value
                        roomStore.allowSearch = true
                    }}
                />
            </div>
            <SelectCustom
                list={roomStore.listStore && roomStore.listStore}
                placeholder="Chọn địa điểm"
                showSearch
                allowClear
                onChange={(value: any) => {
                    roomStore.staticParamRoom.store_id = Number(value);
                    handleSelectChange(true);
                }}
                selected={() => {
                    return  roomStore.staticParamRoom.store_id ? roomStore.staticParamRoom.store_id : 'Chọn địa điểm'
                }}
                getLabel={(item: any) => item.name}
            ></SelectCustom>

            <div className="select-floor">
                <SelectCustom
                    list={filteredRooms}
                    placeholder="Chọn tầng"

                    onChange={(value: any) => {
                        roomStore.staticParamRoom.parent_id = value;
                        handleSelectChange(true);
                    }}
                    selected={() => {
                        return  roomStore.staticParamRoom.parent_id ? roomStore.staticParamRoom.parent_id : 'Chọn tầng'
                    }}
                    getLabel={(room) => room.name}
                    allowClear
                    showSearch
                    disabled={false}
                />
            </div>

            <div className="action--add" onClick={() => {
                roomStore.modalOpen = true;
                roomStore.isCreating = true;
                roomStore.handleClearContent();
                roomStore.modalName = "Thêm mới tầng/phòng"
            }}>
        <span>
          <img src="/assets/icon/plus.svg" alt="add" />
          Thêm mới
        </span>
            </div>
        </div>
    );
};

export default observer(FilterRoom);
