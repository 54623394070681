import React, { useEffect } from "react";
import "../login/styles/Login.css";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { loginStore } from "../login/LoginStore";
import { observer } from "mobx-react";
import Loading from "../../shared/components/loading/Loading";
const ForgotPass = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(window.location.search);

  const statusSendMail = query.get("sendMail")
  const mail = query.get("mail")

  useEffect(()=>{
    loginStore.dataFormResetPass.email = mail
  },[mail, query])
  return (
    <div className="ForgotPass login d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="loginForm">
          <div className="loginFormSignIn d-flex">
            <div className="loginFormSigninLeft d-flex flex-column">
              <p
                className="back"
                onClick={() => {
                  if(statusSendMail === "success"){
                      navigate("/quen-mat-khau")
                      loginStore.mailResetPass = ""
                  }else{
                    navigate("/dang-nhap")
                  }
                }}
                style={{
                  color: "#0e72ed",
                }}
              >
                Trở lại
              </p>

              <h2 className="icon">
                <img src="/assets/images/logo.svg" alt="logo" />
              </h2>

              <div className="heading headingForgot">
                <p>{statusSendMail === "success" ? "Đặt Lại Mật Khẩu" : "Quên Mật Khẩu"}</p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {localStorage.getItem("idDomain")}
                </p>
              </div>

              {!statusSendMail && (
                <Form className={`idTenant d-flex flex-column`}>
                  <div className="formIdTenant d-flex align-items-center">
                    <div
                      className="formUser d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        id="inputEmail"
                        aria-describedby="usernameHelpBlock"
                        placeholder="Nhập email của bạn"
                        style={{
                          paddingLeft: "0px",
                        }}
                        onChange={(e) => {
                          loginStore.mailResetPass = e.target.value;
                        }}
                      />
                    </div>
                  </div>

                  <button
                    className="btnSubmit d-flex align-items-center justify-content-center"
                    type="submit"
                    disabled={loginStore.isLoadingBtn}
                    onClick={async (e) => {
                      e.preventDefault();
                      await loginStore.sendMailResetPass((mail: string)=>{navigate(`/quen-mat-khau?sendMail=success&mail=${mail}`)});
                    }}
                  >
                    {loginStore.isLoadingBtn ? (
                      <Loading color="#fff" />
                    ) : (
                      "Đặt lại mật khẩu"
                    )}
                  </button>
                </Form>
              )}

              {statusSendMail === "success" && (
                <Form className={`idTenant d-flex flex-column`}>
                  <div className="formIdTenant formIdTenant--forgot d-flex align-items-center">
                    <div
                      className="formUser d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        id="inputEmail"
                        aria-describedby="usernameHelpBlock"
                        placeholder="Nhập email của bạn"
                        style={{
                          paddingLeft: "0px",
                        }}
                        value={loginStore.dataFormResetPass.email || mail}
                        disabled
                        // onChange={(e) => {
                        //     loginStore.dataFormResetPass.email = loginStore.saveEmail
                        // }}
                      />
                    </div>
                  </div>

                  <div className="formIdTenant formIdTenant--forgot d-flex align-items-center">
                    <div
                      className="formUser d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type="text"
                        id="inputCode"
                        aria-describedby="usernameHelpBlock"
                        placeholder="Nhập mã xác nhận"
                        style={{
                          paddingLeft: "0px",
                        }}
                        onChange={(e) => {
                            loginStore.dataFormResetPass.verification_code = e.target.value
                        }}
                      />
                    </div>
                  </div>

                  <div className="formIdTenant formIdTenant--forgot d-flex align-items-center">
                    <div
                      className="formUser d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type={`${loginStore.isOpenEyeForgotPass ? "text" : "password"}`}
                        id="inputCode"
                        aria-describedby="usernameHelpBlock"
                        placeholder="Nhập mật khẩu mới"
                        style={{
                          paddingLeft: "0px",
                        }}
                        onChange={(e) => {
                            loginStore.dataFormResetPass.password = e.target.value
                        }}
                      />
                    </div>
                    {loginStore.isOpenEyeForgotPass ? 
                    <span style={{cursor: "pointer"}} onClick={()=>{loginStore.isOpenEyeForgotPass = false}}>
                      <i className="far fa-eye"></i>  
                    </span>:
                    <span style={{cursor: "pointer"}} onClick={()=>{loginStore.isOpenEyeForgotPass = true}}>
                      <i className="far fa-eye-slash"></i>
                    </span>
                  }
                  </div>

                  <div className="formIdTenant formIdTenant--forgot d-flex align-items-center">
                    <div
                      className="formUser d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        type={`${loginStore.isOpenEyePassForgotConfirmPass ? "text" : "password"}`}
                        id="inputCode"
                        aria-describedby="usernameHelpBlock"
                        placeholder="Xác nhận mật khẩu mới"
                        style={{
                          paddingLeft: "0px",
                        }}
                        onChange={(e) => {
                            loginStore.dataFormResetPass.password_confirmation = e.target.value
                        }}
                      />
                    </div>
                    {loginStore.isOpenEyePassForgotConfirmPass ? 
                    <span style={{cursor: "pointer"}} onClick={()=>{loginStore.isOpenEyePassForgotConfirmPass = false}}>
                      <i className="far fa-eye"></i>  
                    </span>:
                    <span style={{cursor: "pointer"}} onClick={()=>{loginStore.isOpenEyePassForgotConfirmPass = true}}>
                      <i className="far fa-eye-slash"></i>
                    </span>
                }
                  </div>

                  <button
                    className="btnSubmit d-flex align-items-center justify-content-center"
                    type="submit"
                    disabled={loginStore.isLoadingBtn}
                    onClick={async (e) => {
                      e.preventDefault();
                      await loginStore.resetPassword(()=>(navigate("/dang-nhap")));
                    }}
                    style={{ marginTop: "6px" }}
                  >
                    {loginStore.isLoadingBtn ? (
                      <Loading color="#fff" />
                    ) : (
                      "Xác Nhận"
                    )}
                  </button>
                </Form>
              )}
            </div>

            <div className="loginFormSigninRight d-flex align-items-center justify-content-center flex-column">
              <div
                className="imgWrapper"
                style={{
                  maxWidth: "100%",
                }}
              >
                <img
                  style={{
                    width: "max-content",
                  }}
                  src="/assets/images/Login_img.svg"
                  alt=""
                />
              </div>

              <div
                className="imgHeading d-flex flex-column align-items-center"
                style={{
                  paddingTop: "14px",
                  gap: "0",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  Tăng tốc doanh thu,
                  <br /> quản lý đơn hàng dễ dàng
                </p>

                <button
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    marginTop: "14px",
                    fontSize: "16px",
                    fontWeight: "700",
                    padding: "4px 24px",
                    height: "36px",
                    width: "258px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>Hỗ trợ đăng ký 0916 509 299</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ForgotPass);
