import { getRequest, putRequest, deleteRequest, postRequest } from "../../common/helpers/RequestHelper"

class RoomService{
    apiSub = "api/v1/floor_room"
    apiFloor = "api/v1/floor_room/list_all"
    public fetchRoomGettingAll(ParamRoom: any): Promise<any>{
        const queryParams = [
            ParamRoom.offset ? `offset=${ParamRoom.offset}` : "",
            ParamRoom.order_by ? `order_by=${ParamRoom.order_by}` : "",
            ParamRoom.page ? `page=${ParamRoom.page}` : "",
            ParamRoom.parent_id ? `parent_id=${ParamRoom.parent_id}` : "",
            ParamRoom.per_page ? `per_page=${ParamRoom.per_page}` : "",
            ParamRoom.search ? `search=${ParamRoom.search}` : "",
            ParamRoom.status ? `status=${ParamRoom.status}` : "",
            ParamRoom.store_id ? `store_id=${ParamRoom.store_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSub}?${queryParams}`);
    }
    public fetchRoomGettingAllFloor(): Promise<any>{
        return getRequest(`${this.apiFloor}`);
    }
    public fetchRoomAdding(roomData:any): Promise<any>{
        return postRequest(`${this.apiSub}`, roomData);
    }
    public fetchRoomEditing(roomData:any): Promise<any>{
        return putRequest(`${this.apiSub}/${roomData.id}`, roomData);
    }
    public fetchRoomDeleting(roomID:any): Promise<any>{
        return deleteRequest(`${this.apiSub}/${roomID}`);
    }
    public fetchRoomWithRequirement(ParamRoom: any): Promise<any>{
        const queryParams = [
            ParamRoom.search ? `search=${ParamRoom.search}` : "",
            ParamRoom.page ? `page=${ParamRoom.page}` : "",
            ParamRoom.per_page ? `per_page=${ParamRoom.per_page}` : "",
            ParamRoom.store_id ? `store_id=${ParamRoom.store_id}` : "",
            ParamRoom.parent_id? `parent_id=${ParamRoom.parent_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSub}?${queryParams}`);
    }

}
export const roomService = new RoomService();