import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Form, Modal} from 'antd';
import {paymentPosStore} from "../PaymentPosStore";
import { Input } from 'antd';
import {roomStore} from "../../../room/RoomStore";
import {useParams} from "react-router-dom";
import StorageService from "../../../../common/service/StorageService";
import {values} from "mobx";
const ModalNote = () => {
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const { postId } = useParams<{ postId: string }>();
    useEffect(() => {
        form.setFieldsValue({
            note: paymentPosStore.notePos,
        });
    }, [form,paymentPosStore.notePos]);
    return (
        <>
            <Modal className="modalNote" title="Ghi chú" closable={false} open={paymentPosStore.isModalNoteOpen} footer={null}>
                <Form
                    form={form}
                    name="room-form"
                    onFinish={(values) => {
                        values.note ? StorageService.setLocalStore(`notePos${postId}`,values.note) : StorageService.removeLocalStore(`notePos${postId}`)
                        paymentPosStore.notePos = values.note ? values.note : ''
                        paymentPosStore.handleCancelNote()
                    }}
                >
                    <div className="form-modal">
                        <Form.Item
                            className="item-form"
                            name="note"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>
                    </div>
                    <div className="footer-note">
                        <Button className="btnCloseNote" onClick={() => paymentPosStore.handleCancelNote()}>
                            Hủy bỏ
                        </Button>
                        <Button type="primary" htmlType="submit" className="btnAddNotePay">
                            Thêm ghi chú
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
export default observer(ModalNote)