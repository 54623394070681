import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Form, Modal} from 'antd';
import {useNavigate, useParams} from "react-router-dom";
import {paymentDebtStore} from "../PaymentDebtStore";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import {handleFile} from "../../../../../common/helpers/UploadFileHelper";
import { numberByDateNow } from "../../../../../common/utils/Utils";

const ModalImageQr = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    useEffect(() => {
        form.setFieldsValue({
            note: paymentDebtStore.notePos,
        });
    }, [form,paymentDebtStore.notePos]);
    return (
        <>
            <Modal className="modalNote" title="Chuyển khoản" closable={false} open={paymentDebtStore.isModalImageQrOpen} footer={null}>
                <Form
                    form={form}
                    name="room-form"
                    onFinish={(values) => {
                        paymentDebtStore.fetchSaveOrderPosEcommerce((route) => navigate(route));
                    }}
                >
                    <div className="form-modal-image">
                        {paymentDebtStore.imageQr !== '' ? (
                            <>
                                <img src={paymentDebtStore.imageQr} alt="Qr"/>
                            </>
                        ) : (
                            <>
                                <p>Phương thức thanh toán chuyển khoản chưa được kích hoạt</p>
                            </>
                        )}
                    </div>
                    <UploadFile
                        text={`ảnh chuyển khoản`}
                        file={paymentDebtStore.imageBank}
                        onIconRemove={paymentDebtStore.handleRemoveImageBank}
                        onFileChange={(e) => handleFile(e, paymentDebtStore.handleSetFileImageBank)}
                        onChooseImg={(url: string)=>{
                            paymentDebtStore.imageBank = url
                        }}
                        code={numberByDateNow()}
                        onResetState={()=>{ paymentDebtStore.imageBank = ""}}
                    />
                    <div className="footer-note">
                        <Button className="btnCloseNote" onClick={() => paymentDebtStore.handleCancelImageQr()}>
                            Hủy bỏ
                        </Button>
                        <Button type="primary" htmlType="submit" className="btnAddNotePay">
                            Xác nhận
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
export default observer(ModalImageQr)