import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { ordersSessionMachineService } from "./OrdersSessionMachineService";
import { ImetaData } from "../detailMachinePos/DetailMachinePosStore";

interface StaticParamOrdersSessionMachine {
    offset: number | null;
    page: number;
    per_page: number;
}

interface IdataFilterOrdersSessionMachineStore{
    search?: string,
    status?:number | string | null,
    user_id?: number | string | null,
    order_type?: number | string | null,
    start_date?: string | null,
    end_date?: string | null
}

class OrdersSessionMachineStore {

    isLoading: boolean = false;
    dataOrdersSessionMachine : any[] = []

    staticOrdersSessionMachine: StaticParamOrdersSessionMachine = {
        offset: 0,
        page: 1,
        per_page: 10,
    };

    metadata: ImetaData = {
        total: null,
        page: 1,
        total_page: null,
        size: null,
    };

    dataFilterOrdersSessionMachine: IdataFilterOrdersSessionMachineStore = {
        search: "",
        status: null,
        order_type: null,
        user_id: null,
        start_date: null,
        end_date: null
    }

    constructor() {
        makeAutoObservable(this);
    }

    async fetchAllOrdersSessionMachine(idPos: any, idSession: any, run?: boolean) {
        let { offset, page, per_page} = this.staticOrdersSessionMachine;
        let {search,
            status,
            user_id,
            order_type,
            start_date,
            end_date } = this.dataFilterOrdersSessionMachine;
        const paramsTagProduct = {
            offset: offset,
            page: page + 1,
            per_page: per_page,
            search: search,
            status: status,
            user_id: user_id,
            order_type: order_type,
            start_date: start_date,
            end_date: end_date
        };
        if (this.dataOrdersSessionMachine.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await ordersSessionMachineService.fetchOrderSessionMachinePos(idPos,idSession, paramsTagProduct);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataOrdersSessionMachine = result.body.data;
            this.metadata = result.body.metadata;
        }else{
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }


}

export const ordersSessionMachineStore = new OrdersSessionMachineStore();