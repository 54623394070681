import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";

class StoreBranchService {
    apiLocation = "api/v1/location/list";
    apiCreateLocation = "api/v1/location/store";
    apiUpDelLocation = "api/v1/location";
    apiStaff = "api/v1/staff/list";
    apiCreateStaff = "api/v1/staff/store";
    apiProvinceId = "api/v1/provinces";
    apidistrictId = "api/v1/districts";
    apiwardId = "api/v1/wards";
    apiGetStaffRoles = "api/v1/staff/roles";

    public fetchStoreBranchLocation(params: any): Promise<any> {
        const queryParam = [
            params.order ? `order=${params.order}` : "",
            params.paginate ? `paginate=${params.paginate}` : "",
            params.status ? `status=${params.status}` : "",
            params.page ? `page=${params.page}` : "",
            params.search ? `search=${params.search}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiLocation}?${queryParam}`);
    }
    public fetchStoreBranchLocationCreate(params: any): Promise<any> {
        return postRequest(`${this.apiCreateLocation}`, params);
    }
    public fetchStoreBranchLocationUpdate(
        id: number,
        params: any
    ): Promise<any> {
        return putRequest(`${this.apiUpDelLocation}/${id}/update`, params);
    }

    public fetchStoreBranchLocationDelete(id: number): Promise<any> {
        return deleteRequest(`${this.apiUpDelLocation}/${id}/destroy`);
    }

    public fetchStoreBranchStaffRoles(): Promise<any> {
        return getRequest(`${this.apiGetStaffRoles}`);
    }
    public fetchStoreBranchStaff(params: any): Promise<any> {
        const queryParam = [
            params.order ? `order=${params.order}` : "",
            params.paginate ? `paginate=${params.paginate}` : "",
            params.status ? `status=${params.status}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiStaff}?${queryParam}`);
    }
    public fetchStoreBranchCreateStaff(params: any): Promise<any> {
        return postRequest(`${this.apiCreateStaff}`, params);
    }

    public fetchProvinceId() {
        return getRequest(`${this.apiProvinceId}`);
    }
    public fetchdistrictId(params: string) {
        return getRequest(`${this.apidistrictId}?province_id=${params}`);
    }
    public fetchWardId(params: string) {
        return getRequest(`${this.apiwardId}?district_id=${params}`);
    }
}

export const storebranchService = new StoreBranchService();
