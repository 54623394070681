import { Modal, Form as AntdForm, Input } from "antd";
import { observer } from "mobx-react";
import UploadFile from "../../../shared/components/form/UploadFile";
import { attributeStore } from "./AttributeStore";
import { handleFile } from "../../../common/helpers/UploadFileHelper";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { title } from "process";
import Loading from "../../../shared/components/loading/Loading";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toastUtils } from "../../../common/utils/Toastutils";

const AttributeModal = () => {
    const { TextArea } = Input;
    const validBrandSchema = yup.object().shape({
        attribute_name: yup
            .string()
            .required("Vui lòng nhập Tên thuộc tính")
            .max(100, "Tên thuộc tính quá dài tối đa 100 kí tự")
            .matches(
                /^[^<>(){}[\]`\/!@#$%^&*~"'.,:?|\\]+$/,
                "Tên không được chứa các ký tự đặc biệt"
            ),
    });
    const navigate = useNavigate();

    return (
        <>
            <Modal
                className="modal-form"
                title={attributeStore.modalName}
                footer={null}
                open={attributeStore.openModal}
                onCancel={() => {
                    attributeStore.openModal = false;
                }}
            >
                {attributeStore.isLoadingModal ? (
                    <div className="loading__container">
                        <Loading />
                    </div>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                attribute_name:
                                    attributeStore.staticDataAttribute.name,
                            }}
                            validationSchema={validBrandSchema}
                            enableReinitialize={true}
                            onSubmit={(values: any, { resetForm }) => {
                                attributeStore.staticDataAttribute.name =
                                    values.attribute_name;
                                attributeStore.isCreating
                                    ? attributeStore.createAttribute()
                                    : attributeStore.modifyAttribute();
                                if (attributeStore.isCreating === true) {
                                    const params: any = {};
                                    params.page = 1;
                                    navigate({
                                        search: createSearchParams(
                                            params
                                        ).toString(),
                                    });
                                }
                                resetForm();
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form id="attribute-modal-form">
                                    <div className="item-form">
                                        <label htmlFor="attribute_name">
                                            Tên <span>*</span>
                                        </label>
                                        <Field
                                            as={Input}
                                            name="attribute_name"
                                            placeholder="Vui lòng nhập"
                                            maxLength={150}
                                            onInput={(e: any) => {
                                                if (
                                                    e.target.value.length ===
                                                    149
                                                ) {
                                                    toastUtils.warning(
                                                        "Tên thuộc tính quá dài, tối đa 150 ký tự",
                                                        ""
                                                    );
                                                }
                                            }}
                                        />
                                        <ErrorMessage
                                            name="attribute_name"
                                            component="span"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="attribute-item-field">
                            <div className="item-title">Thuộc tính</div>
                            {(attributeStore.staticDataAttribute.attributes
                                .length > 0 ||
                                attributeStore.dataListAttributeChildren
                                    .length > 0) && (
                                <div className="item-form">
                                    {attributeStore.staticDataAttribute.attributes.map(
                                        (
                                            element: any,
                                            index: any // OLD FIELD
                                        ) => (
                                            <div
                                                className="input-item"
                                                key={index}
                                            >
                                                <div className="input-item__label">
                                                    Thuộc tính con:
                                                </div>
                                                <div className="input-item__content">
                                                    <Input
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Vui lòng nhập"
                                                        value={element.name}
                                                        onChange={(event) => {
                                                            attributeStore.staticDataAttribute.attributes[
                                                                index
                                                            ].name =
                                                                event.target.value;
                                                        }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="deleteItem"
                                                        disabled={
                                                            attributeStore
                                                                .staticDataAttribute
                                                                .attributes
                                                                .length === 1
                                                        }
                                                        onClick={() => {
                                                            attributeStore.inputPosition =
                                                                index;
                                                            attributeStore.handleDeleteInputOld();
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    )}
                                    <>
                                        {attributeStore.dataListAttributeChildren.map(
                                            (element: any, index: any) => (
                                                <div
                                                    className="input-item"
                                                    key={index}
                                                >
                                                    <div className="input-item__label">
                                                        Thuộc tính con:
                                                    </div>
                                                    <div className="input-item__content">
                                                        <Input
                                                            type="text"
                                                            maxLength={200}
                                                            placeholder="Vui lòng nhập"
                                                            value={element}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                attributeStore.dataListAttributeChildren[
                                                                    index
                                                                ] =
                                                                    event.target.value;
                                                            }}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="deleteItem"
                                                            onClick={() => {
                                                                attributeStore.inputPosition =
                                                                    index;
                                                                attributeStore.handleDeleteInput();
                                                            }}
                                                        >
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </>
                                </div>
                            )}
                        </div>
                        <button
                            className="addItem"
                            type="button"
                            onClick={() => {
                                attributeStore.dataListAttributeChildren.push(
                                    ""
                                );
                            }}
                        >
                            <i className="fas fa-plus"></i>
                            Thêm thuộc tính con
                        </button>
                        <div className="btnCreateModal">
                            <button
                                className="cancelModal"
                                type="reset"
                                onClick={() => {
                                    attributeStore.openModal = false;
                                }}
                            >
                                Đóng
                            </button>
                            <button
                                disabled={
                                    attributeStore.waitingText === ""
                                        ? false
                                        : true
                                }
                                className="submitForm"
                                type="submit"
                                form="attribute-modal-form"
                            >
                                {attributeStore.waitingText === ""
                                    ? "Lưu"
                                    : attributeStore.waitingText}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default observer(AttributeModal);
