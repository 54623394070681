import React, { useEffect } from "react";
import "../../../styles/FormPaymentSettings.scss";
import { Form, Input, Switch } from "antd";
import { observer } from "mobx-react";
import { paymentSettingsStore } from "../../../PaymentSettingsStore";
const FormVnPay = ({ itemForm }: { itemForm: any }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      vnp_TmnCode: itemForm.data.vnp_TmnCode,
      vnp_HashSecret: itemForm.data.vnp_HashSecret,
    });
    paymentSettingsStore.dataFormVnpay.status = itemForm.status;
  }, [form, itemForm]);
  return (
    <Form
      form={form}
      onFinish={async (values: any) => {
        paymentSettingsStore.idPayment = itemForm.id;
        paymentSettingsStore.dataFormVnpay.vnp_TmnCode = values.vnp_TmnCode;
        paymentSettingsStore.dataFormVnpay.vnp_HashSecret =
          values.vnp_HashSecret;
        await paymentSettingsStore.updatePayment("VNpay");
      }}
      className="FormPaymentSettings"
    >
      <Form.Item<any>
        className="item-form"
        label="Vnp TmnCode"
        name="vnp_TmnCode"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <Form.Item<any>
        className="item-form"
        label="Vnp HashSecret"
        name="vnp_HashSecret"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <div className="FormPaymentSettings-switch">
        <h1>Trạng thái</h1>
        <Switch
          size="default"
          defaultChecked={itemForm.status === 1 ? true : false}
          //   disabled={item.super_admin === 1}
          onChange={async (e) => {
            paymentSettingsStore.dataFormVnpay.status = e ? 1 : 0;
          }}
        />
      </div>

      <button
        className={`updateForm`}
        disabled={paymentSettingsStore.isLoadingVnpay}
        type="submit"
      >
        {paymentSettingsStore.isLoadingVnpay ? "Vui lòng chờ..." : " Cập nhật"}
      </button>
    </Form>
  );
};

export default observer(FormVnPay);
