import { deleteRequest, getRequest, postRequest, putRequest } from "../../../../common/helpers/RequestHelper";

class DiningTablesService {
    public fetchListDiningTables(params: any): Promise<any> {
        const queryParams = [
            params.order_by ? `order_by=${params.order_by}` : "",
            params.page ? `page=${params.page}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.search ? `search=${params.search}` : "",
            params.store_id ? `store_id=${params.store_id}` : "",
            params.floor_room_id ? `floor_room_id=${params.floor_room_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`api/v1/dining_tables?${queryParams}`);
    }
    public fetchDetailsDiningTables(id: any): Promise<any> {
        return getRequest(`api/v1/dining_tables/${id}`);
    }
    public addDiningTables(data: any): Promise<any> {
        return postRequest(`api/v1/dining_tables`, data);
    }
    public editDiningTables(id: any, data: any): Promise<any> {
        return putRequest(`api/v1/dining_tables/${id}`, data);
    }
    public deleteDiningTables(id: any): Promise<any> {
        return deleteRequest(`api/v1/dining_tables/${id}`);
    }
    public fetchListFloorRoom(store_id: any): Promise<any>{
        return getRequest(`api/v1/floor_room?page=1&per_page=100${store_id ? `&store_id=${store_id}` : ""}`);
    }
    public fetchListLocation(): Promise<any>{
        return getRequest(`api/v1/location/list?page=1&per_page=100`);
    }
}

export const diningTablesService = new DiningTablesService();