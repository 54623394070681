import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { productStore } from "../ProductStore";
import { Link, useParams } from "react-router-dom";
import {Checkbox, Col, theme, Form, GetProp, Input, Row, Space, Button, InputNumber, Modal, Select} from "antd";
import EditProductMenu from "./components/EditProductMenu";
import "../createProduct/styles/create_product.css";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import DetailVariant from "./components/DetailVariant";
import SelectStatusNumber from "../../../../shared/components/form/SelectStatusNumber";
import ModalCreateVariant from "./components/ModalCreateVariant";
import VariantCreateProduct from "../createProduct/components/VariantCreateProduct";
import {dashboardStore} from "../../../dashboard/DashboardStore";
import ModalCreateListVariant from "./components/ModalCreateListVariant";
import {toastUtils} from "../../../../common/utils/Toastutils";
const EditProductVariantPage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = `variant`;
    const onChange = (key: string | string[]) => {};

    useEffect(() => {
        productStore.fetchGetProduct().then();
        productStore.fetchDataStore().then();
    }, []);

    useEffect(() => {
        productStore.fetchListAttribute().then();
    }, [dashboardStore.dataListStore]);

    const items: CollapseProps['items'] = productStore.dataProductListVariant?.map((item: any, index: number) => ({
            key: `${index}`,
            label: item.attribute_compare ? `${item.attribute_compare}` : 'Mặc định',
            children: <DetailVariant index={index} />,
        }));

    useEffect(() => {
        const valuesToSet: { [key: string]: any } = {};
        productStore.dataProductListVariant.forEach((item: any, index: number) => {
                valuesToSet[`price${index}`] = item.price;
                valuesToSet[`sku${index}`] = item.sku;
                valuesToSet[`price_compare${index}`] = item.price_compare;
                valuesToSet[`unit${index}`] = item.unit;
                valuesToSet[`weight${index}`] = item.weight;
                valuesToSet[`height${index}`] = item.height;
                valuesToSet[`length${index}`] = item.length;
                valuesToSet[`width${index}`] = item.width;
                valuesToSet[`status${index}`] = item.status;
                valuesToSet[`allow_inventory${index}`] = item.allow_inventory;
            });

        form.setFieldsValue({
            product_type: productStore.dataProduct.product_type,
            ...valuesToSet,
        });
    }, [productStore.dataProductListVariant, form]);

    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct mainContentEditProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            for (let index = 0; index < productStore.dataProductListVariant.length; index++) {
                                const items = productStore.dataProductListVariant[index];
                                if (!items.thumbnail) {
                                    toastUtils.warning(`Ảnh ${items.attribute_compare} không được trống`, `${index}`);
                                    return false;
                                }
                                const checkSku = productStore.dataProductListVariant.some((itemVariant: any) => itemVariant.sku === items.sku && itemVariant.id !== items.id);
                                if (checkSku) {
                                    toastUtils.warning(`Sku ${items.sku} đã tồn tại`, `${index}`);
                                    return false;
                                }
                            }
                            productStore.fetchUpdateVariantProduct(id);
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="variant"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="item-form">
                                        <Form.Item name="product_type" label="Loại sản phẩm">
                                            <SelectStatusNumber selected={() => productStore.dataProduct.product_type}
                                                                onChange={(value: any) => {
                                                                    productStore.dataProduct.product_type = value;
                                                                    productStore.setdataProductListVariant(value)
                                                                    if(productStore.dataProduct.product_type === 1 && productStore.dataProductListVariant.length === 0) {
                                                                        productStore.arrayAttributeProduct = [];
                                                                        productStore.listGenerateAttributes = [];
                                                                        productStore.staticDataGenerateAttributes.attribute_ids = [];
                                                                        productStore.fetchListAttribute().then();
                                                                    }
                                                                }}
                                                                textOn={`Sản phẩm biến thể`}
                                                                textOff={`Sản phẩm đơn giản`}/>
                                        </Form.Item>
                                    </div>
                                    <Collapse items={items} defaultActiveKey={['0']} onChange={onChange}/>


                                    {productStore.dataProduct.product_type === 1 && productStore.dataProductListVariant.length > 0 ? (
                                        <button
                                            className="modal-form editAttributes"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                productStore.handleOpendModelCreateVariant();
                                            }}
                                        >
                                            <span>+ Thêm biến thể mới</span>
                                        </button>
                                    ) : productStore.dataProduct.product_type === 1 && productStore.dataProductListVariant.length === 0 ? (
                                        <>
                                            <div className="product-variant">
                                                <VariantCreateProduct/>
                                            </div>
                                        </>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Cập nhật
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                    <ModalCreateVariant/>
                    <ModalCreateListVariant/>
                </div>
            </div>
        </>
    );
};
export default observer(EditProductVariantPage);
