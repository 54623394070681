import { makeAutoObservable, toJS } from "mobx";
import { detailOrderPosService } from "./DetailOrderPosService";
import { HttpStatusCode } from "axios";

class DetailOrderPosStore {
    isLoading = false;
    isSearchTriggered = false;
    listStaff: any[] = [];
    listOrderPos: any[] = [];
    listViewDetailOrderPos: any[] = [];
    isSelectedItem: any = null;

    constructor() {
        makeAutoObservable(this);
        // this.initializeParams();
    }

    // initializeParams() {
    //     const query = new URLSearchParams(window.location.search);
    //     this.staticParamsOrderPos.search = query.get("search")
    //         ? String(query.get("search"))
    //         : "";
    //     this.staticParamsOrderPos.page_number = query.get("page_number")
    //         ? Number(query.get("page_number"))
    //         : 0;
    //     this.staticParamsOrderPos.user_id = query.get("user_id")
    //         ? String(query.get("user_id"))
    //         : "";
    //     this.staticParamsOrderPos.order_type = query.get("order_type")
    //         ? String(query.get("order_type"))
    //         : "";
    //     this.staticParamsOrderPos.payment_status = query.get("payment_status")
    //         ? String(query.get("payment_status"))
    //         : "";
    // }

    staticOrderPos: {
        id: string;
        idViewDetail: string;
    } = {
        id: "",
        idViewDetail: "",
    };

    setIsTriggerdPararms = (value: boolean) => {
        this.isSearchTriggered = value;
    };

    metadata: {
        size: number;
        page: number;
        total_page: number;
        total: number;
        pos_name: string;
    } = {
        size: 0,
        page: 0,
        total_page: 0,
        total: 0,
        pos_name: "",
    };

    staticParamsOrderPos: {
        end_date: string;
        start_date: string;
        order_type: string;
        payment_status: string;
        pos_session_id: string;
        search: string;
        user_id: string;
        page_number: number;
        per_page: number;
    } = {
        end_date: "",
        start_date: "",
        order_type: "",
        payment_status: "",
        pos_session_id: "",
        search: "",
        user_id: "",
        page_number: 0,
        per_page: 10,
    };

    clearFilter = () => {
        this.staticParamsOrderPos.end_date = "";
        this.staticParamsOrderPos.start_date = "";
        this.staticParamsOrderPos.order_type = "";
        this.staticParamsOrderPos.page_number = 0;
        this.staticParamsOrderPos.payment_status = "";
        this.staticParamsOrderPos.pos_session_id = "";
        this.staticParamsOrderPos.search = "";
        this.staticParamsOrderPos.user_id = "";
    };

    async getStaff() {
        const result = await detailOrderPosService.getALlStaff();
        if (result.status === HttpStatusCode.Ok) {
            this.listStaff = result.body.data;
        }
    }

    async detaiOrderPos(run?: boolean) {
        let {
            end_date,
            start_date,
            order_type,
            page_number,
            per_page,
            payment_status,
            pos_session_id,
            search,
            user_id,
        } = this.staticParamsOrderPos;
        const params = {
            end_date: end_date,
            start_date: start_date,
            order_type: order_type,
            page_number: page_number + 1,
            per_page: per_page,
            payment_status: payment_status,
            pos_session_id: pos_session_id,
            search: search,
            user_id,
        };
        if (this.listOrderPos.length < 1 || run) {
            this.isLoading = true;
        }
        const result = await detailOrderPosService.getDetailOrderPos(
            this.staticOrderPos.id,
            params
        );
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.listOrderPos = result.body.data;
            this.metadata = result.body.metadata;
        }
    }

    async getViewDetailOrderPos() {
        this.isLoading = true;
        const result = await detailOrderPosService.getViewDetailOrderPos(
            this.staticOrderPos.idViewDetail
        );
        if (result.status === HttpStatusCode.Ok) {
            this.listViewDetailOrderPos = [result.body];
        }
        this.isLoading = false;
    }
}

export const detailOrderPosStore = new DetailOrderPosStore();
