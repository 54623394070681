import { makeAutoObservable } from "mobx";
import { filesStore } from "../../../pages/settings/files/FilesStore";
import { filesService } from "../../../pages/settings/files/FilesService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

class UploadImageStore {
    
    isOpenModalCreateFolder: boolean = false;
    isLoadingBtn : boolean = false;
    isMultipleChoice: boolean = false;
    isBtnChoose: boolean = false
    highLightPath: string = ""

    arrMutipleImg: any[] = []
    arrImgTemp: any[] = []
    nameFolder: string = ""

    typeModal: "single" | "multiple" = "single";
    codeClick: number | null = null;

    async addFolder() {
        let newFolderName  = this.nameFolder;
        let {rootPath} = filesStore.metadata;
        const addFolders = {
            directory:  rootPath + '/' + newFolderName,
        }
        this.isLoadingBtn = true
        const result = await filesService.addFolder(addFolders);
        this.isLoadingBtn = false

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm thư mục thành công", "");
            filesStore.listMedia.push({
                name: result.body.path,
                isDir: true
            })
        }else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export const uploadImageStore = new UploadImageStore();