import { getRequest } from "../../../common/helpers/RequestHelper";
import { detailOrderPosStore } from "./DetailOrderPosStore";

class DetailOrderPosService {
    apiStaff = "api/v1/staff/list";
    apiDetailOrderPos = "api/v1/pos/ecommerce/order";
    apilocation = "api/v1/location/list";
    apiViewDetailOrderPos = "api/v1/pos/ecommerce/order/order_detail";

    public getALlStaff(): Promise<any> {
        return getRequest(`${this.apiStaff}?paginate=1000`);
    }
    public getDetailOrderPos(id: string, params: any): Promise<any> {
        const queryParams = [
            params.end_date ? `end_date=${params.end_date}` : "",
            params.start_date ? `start_date=${params.start_date}` : "",
            params.order_type ? `order_type=${params.order_type}` : "",
            params.page_number ? `page_number=${params.page_number}` : "",
            params.payment_status
                ? `payment_status=${params.payment_status}`
                : "",
            params.pos_session_id
                ? `pos_session_id=${params.pos_session_id}`
                : "",
            params.search ? `search=${params.search}` : "",
            params.user_id ? `user_id=${params.user_id}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
        ]
            .filter(Boolean)
            .join("&");

        return getRequest(`${this.apiDetailOrderPos}/${id}?${queryParams}`);
    }
    public getViewDetailOrderPos(id: string): Promise<any> {
        return getRequest(`${this.apiViewDetailOrderPos}/${id}`);
    }
}

export const detailOrderPosService = new DetailOrderPosService();
