import React, { useEffect, useState } from 'react';
import {observer} from "mobx-react";
import UploadImage from '../uploadImage/UploadImage';
import { uploadImageStore } from '../uploadImage/UploadImageStore';
import { filesStore } from '../../../pages/settings/files/FilesStore';
import { useLocation } from 'react-router-dom';
interface IconUploadProps {
    text: string | null;
    description: string | null;
    file: string[] | null;
    onIconRemove: (index: number) => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChooseMutipleImage?: (arrUrl: string[]) => void,
    onResetState?: () => void
    code?: number | null
}
const UploadFileMultiple: React.FC<IconUploadProps> = ({ text, description, file, onIconRemove, onFileChange, onChooseMutipleImage, code, onResetState }) => {
    const location = useLocation()
    useEffect(() => {
        onResetState && onResetState()
    }, [location.pathname]);
    return (
        <div className="upload-form">
            <label>{text}</label>
            <div className="upload">
                <p>{description}</p>
                <div className="uploadChild" style={{cursor: "pointer"}}>
                    <div onClick={()=>{
                        uploadImageStore.isMultipleChoice = true;
                        uploadImageStore.typeModal = "multiple"
                         uploadImageStore.codeClick = code as number
                         filesStore.staticDataFiles.path = ""
                         filesStore.staticDataFiles.page = 0
                         filesStore.fetchMediaList(true);
                         }}>
                        <img
                            className="icon_upload"
                            width="38"
                            height="38"
                            src="/assets/icon/icon_upload.svg"
                            alt="icon_upload"
                        />
                        <span className="text-upload">Chọn {text}</span>
                        {/* <input
                            onChange={(event)=>{onFileChange(event); handleUploadingStatus(event);}}
                            type="file"
                            accept="image/jpeg, image/jpg, image/png, image/svg+xml, image/webp"
                            multiple
                        /> */}
                    </div>
                </div>
            </div>
            <div className='upload-files-multiple'>
                {
                   file && file.map((item:any, index:number)=>(
                        <div className="upload-item-image" key={index}>
                            <a href={item} target="_blank" rel="noopener noreferrer" className="upload-item-image-value">
                                {item.endsWith('.png') || item.endsWith('.jpg') || item.endsWith('.jpeg') || item.endsWith('.svg') || item.endsWith('.webp') ?
                                    (<img src={item} alt={`uploaded-file-${index}`} width="100" />)
                                    :
                                    (<span style={{wordBreak:'break-all', padding:'10px 10px 0px'}}>{item.split('/').pop()}</span>)}
                            </a>
                            <a  onClick={() => onIconRemove(index)} className="close_image">
                                <img src="/assets/icon/minus-circle.svg" className="icon-action" alt="" title="Xóa" />
                            </a>
                        </div>
                    ))
                }
            </div>
               {uploadImageStore.typeModal === "multiple" && uploadImageStore.codeClick &&
                    <UploadImage multipleChoice={uploadImageStore.isMultipleChoice} onChooseMutipleImage={onChooseMutipleImage} code={code}/>
               }
        </div>
    );
};

export default observer(UploadFileMultiple);
