import { getRequest, postRequest } from "../../../common/helpers/RequestHelper";

class MailService {
    public getConfigMail(service: string): Promise<any> {
        return getRequest(`api/v1/mail/list?service=${service || "mailgun"}`);
    }

    public updateConfigMail(data: any): Promise<any> {
        return postRequest(`api/v1/mail/config`, data);
    }
}

export const mailService = new MailService();