import { Input, Modal, Select } from "antd";
import { storebranchstore } from "../StoreBranchStore";
import { observer } from "mobx-react";
import { Form } from "antd";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import { numberByDateNow } from "../../../../common/utils/Utils";

const AddNewStaffModal = () => {
    const [form] = Form.useForm();

    const listType = [
        {
            id: "1",
            name: "Nhân viên",
            label: "Nhân viên",
            value: "Nhân viên",
        },
        { id: "2", name: "Quản lí", label: "Quản lí", value: "Quản lý" },
    ];

    const onFinish = (values: any) => {
        storebranchstore
            .fetchCreateStaff(() => {
                form.resetFields(); // Reset lại các trường trong form
            })
            .catch((err) => console.log("err", err));
    };

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Thêm nhân viên"
                open={storebranchstore.modalAddNewStaff}
                onOk={storebranchstore.handleOK}
                onCancel={storebranchstore.handleCancel}
                afterClose={storebranchstore.handleCloseAddNewStaff}
            >
                <Form form={form} onFinish={onFinish}>
                    <div className="item-form">
                        <label htmlFor="" className="label-form">
                            Tên nhân viên <span>*</span>
                        </label>

                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    max: 100,
                                    message:
                                        "Không được để trống tên nhân viên",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Điền tên"
                                value={storebranchstore.staticStaff.name}
                                onChange={(e) =>
                                    (storebranchstore.staticStaff.name =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label htmlFor="" className="labl-form">
                            Email <span>*</span>
                        </label>

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    max: 100,
                                    message: "Không được để trống email",
                                },
                            ]}
                        >
                            <Input
                                type="email"
                                placeholder="Nhập email"
                                value={storebranchstore.staticStaff.email}
                                onChange={(e) =>
                                    (storebranchstore.staticStaff.email =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label htmlFor="" className="label-form">
                            Mật khẩu <span>*</span>
                        </label>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống mật khẩu!",
                                },
                                {
                                    min: 9,
                                    message:
                                        "Mật khẩu phải có ít nhất 9 ký tự!",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                type="password"
                                placeholder="Nhập mật khẩu"
                                onChange={(e) =>
                                    (storebranchstore.staticStaff.password =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label htmlFor="" className="label-form">
                            Xác nhận mật khẩu <span>*</span>
                        </label>

                        <Form.Item
                            name="password_confirmation"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Không được để trống xác nhận mật khẩu!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue("password") === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Mật khẩu không giống nhau"
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="password"
                                placeholder="Xác nhận mật khẩu"
                                onChange={(e) =>
                                    (storebranchstore.staticStaff.password_confirmation =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label htmlFor="" className="label-form">
                            Phân quyền
                        </label>

                        <Form.Item name="roles">
                            <SelectCustom
                                list={storebranchstore.listStaffRoles}
                                placeholder="Phân quyền"
                                labelFirst="Không"
                                onChange={(e) => {
                                    if (e === 1) {
                                        storebranchstore.staticStaff.roles = [
                                            "superadmin",
                                        ];
                                    }
                                }}
                                selected={() => {}}
                                getLabel={(item: any) => {
                                    return item.name;
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label htmlFor="" className="label-form">
                            Vai trò cửa hàng <span>*</span>
                        </label>

                        <Form.Item
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    max: 100,
                                    message:
                                        "Không được để trống vai trò nhân viên",
                                },
                            ]}
                        >
                            <Select
                                value={
                                    storebranchstore.staticStaff.type === "1"
                                        ? "Quản lý"
                                        : "Nhân viên"
                                }
                                placeholder="Chọn vai trò"
                                style={{
                                    width: "100%",
                                }}
                                onChange={(value: string) => {
                                    value === "Quản lý"
                                        ? (storebranchstore.staticStaff.type =
                                              "1")
                                        : value === "Nhân viên"
                                        ? (storebranchstore.staticStaff.type =
                                              "2")
                                        : (storebranchstore.staticStaff.type =
                                              "");
                                }}
                                options={listType}
                            />
                        </Form.Item>
                    </div>

                    <UploadFile
                        file={storebranchstore.staticStaff.avatar}
                        description="Hình ảnh nhân viên"
                        onIconRemove={storebranchstore.handleCloseIconStaff}
                        onFileChange={(e) => {
                            handleFile(
                                e,
                                storebranchstore.changeFileImageStaff
                            );
                        }}
                        onChooseImg={(url: string)=>{
                            storebranchstore.staticStaff.avatar = url
                        }}
                        code={numberByDateNow()}
                        onResetState={()=>{  
                            storebranchstore.staticStaff.avatar = ""
                        }}
                    />

                    <button
                        className="btn--addnewstaffmodal"
                        type="submit"
                        style={{
                            border: "none",
                            outline: "none",
                            background: "#6777ef",
                            color: "white",
                            padding: "10px",
                        }}
                        disabled={storebranchstore.isLoadingBtn}
                    >
                        {storebranchstore.isLoadingBtn
                            ? "Vui lòng đợi..."
                            : "Tạo mới"}
                    </button>
                </Form>
            </Modal>
        </>
    );
};

export default observer(AddNewStaffModal);
