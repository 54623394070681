import React, { useEffect, useRef } from "react";
import "./styles/Notification.scss";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { notificationStore } from "./NotificationStore";
import { toJS } from "mobx";
import { Skeleton } from "antd";
const Notifications = () => {
  const containerRef = useRef<any>(null);

  const replaceWithSpan = (text: string) => {
    const regex = /%(.*?)%/g;
    const html = text.replace(regex, "<span>$1</span>");
    return { __html: html };
  };

  //     container.scrollTop: Đây là giá trị khoảng cách tính từ đỉnh của phần tử có thể cuộn đến vị trí hiện tại mà người dùng đã cuộn.
  //     container.clientHeight: Đây là chiều cao hiển thị của phần tử (phần mà người dùng có thể nhìn thấy trên màn hình).
  //     container.scrollHeight: Đây là tổng chiều cao của nội dung bên trong phần tử, bao gồm cả phần đã cuộn và chưa cuộn.
  const handleScroll = () => {
    const container = containerRef.current;
    if (
      Math.ceil(container.scrollTop) + container.clientHeight >=
      container.scrollHeight
    ) {
      if (
        notificationStore.params.page < notificationStore.totalPage &&
        !notificationStore.isLoading
      ) {
        notificationStore.readMore();
      }
    }
  };

  return (
    <div className="NotificationInHeader">
      <div className="header">
        <h1>Thông báo</h1>
        <button
          disabled={notificationStore.countNotice.unRead === 0 || notificationStore.isLoadingUpdateAll ? true : false}
          className={`readAll ${
            notificationStore.countNotice.unRead === 0 ? "unActive" : ""
          }`}
          onClick={async()=> {await notificationStore.UpdateStatusAllNotification()}}
        >
          <p>Đánh dấu đã đọc</p>
          {/* <img src="/assets/icon/fi-rr-done-all.svg" alt="fi-rr-done-all" /> */}
          <i className="fa fa-check-double"></i>
        </button>
      </div>
      <div
        className="body-container"
        onScroll={handleScroll}
        ref={containerRef}
      >
        {notificationStore.listNotice.length > 0 ? (
          notificationStore.listNotice.map((item: any) => (
            <Link
              key={item.id}
              style={{ textDecoration: "none" }}
              to={`/don-hang/${item.meta_data.order_id}`}
              className={`body ${item.status === 1 ? "" : "active"}`}
              onClick={async () => {
                notificationStore.isOpenPopoverNotice = false;
                if(item.status === 0){
                    await notificationStore.UpdateStatusNotification(item.id);
                }
              }}
            >
              <div
                className={`itemNotiIcon ${item.status === 1 ? "read" : ""}`}
              >
                {item.status === 1 ?
                <img src="/assets/icon/fi-rr-bell.svg" alt="fi-rr-bell" />
                :
                <img src="/assets/icon/icon-bellBlue.svg" alt="icon-bellBlue" />
            }
              </div>
              <div className="content-noti">
                <p dangerouslySetInnerHTML={replaceWithSpan(item.title)} />
                {/* <p
                  className="branch"
                  dangerouslySetInnerHTML={replaceWithSpan(item.body)}
                /> */}
                <p className="time">
                  <img src="/assets/icon/fi-rr-clock.svg" alt="fi-rr-clock" />
                  {item.time_create}
                </p>
              </div>
            </Link>
          ))
        ) : notificationStore.isLoading ? (
          <div className="loadNotice d-flex flex-column gap-2 px-3 mt-12px">
            <Skeleton active avatar />
            <Skeleton active avatar />
            <Skeleton active avatar />
            <Skeleton active avatar />
          </div>
        ) : (
          <div className="no-notice">
            <img src="/assets/icon/ico_no_noti.svg" alt="fi-rr-done-all" />
            <p className="w-100 fw-500">Bạn chưa có thông báo!</p>
          </div>
        )}
      </div>
      {notificationStore.isLoading &&
        notificationStore.listNotice.length > 0 && (
          <div className="loadNotice loadNotice-bottom">
            <Skeleton active avatar />
          </div>
        )}
    </div>
  );
};

export default observer(Notifications);
