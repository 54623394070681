import React, { useEffect, useState } from "react";
import "./ModalListCustomer.scss";
import { Modal } from "antd";
import { productInPostStore } from "../../../ProductInPostStore";
import { observer } from "mobx-react";
import AddEditCustomer from "../addEditCustomer/AddEditCustomer";
import { toJS } from "mobx";
import NoContent from "../../../../../../shared/components/NoContent";
import Loading from "../../../../../../shared/components/loading/Loading";
import useDebounce from "../../../../../../common/customHook/useDebounce";
import StorageService from "../../../../../../common/service/StorageService";
import { productInPosCartStore } from "../../../ProductInPosCartStore";
import { useParams } from "react-router-dom";
import { handleKeyPressSpecialCharacters } from "../../../../../../common/utils/Utils";
const ModalListCustomer = () => {
  const debouncedSearchCustomer = useDebounce(
    productInPostStore.dataFilterCustomer.phone,
    500
  );
  const { postId } = useParams<{ postId: string }>();
  useEffect(() => {
    productInPostStore.getListCustomer();
  }, [debouncedSearchCustomer]);

  useEffect(() => {
    productInPostStore.idCustomer = productInPosCartStore.dataCustomer?.id;
  }, [productInPosCartStore.dataCustomer]);

  return (
    <>
      <Modal
        title={
          <div className="header d-flex align-items-center justify-content-between">
            <button
              onClick={() => {
                productInPostStore.isOpenModalListCustomer = false;
                productInPostStore.isOpenModalAddEditCustomer = true;
                productInPostStore.typeModal = "add";
              }}
            >
              Thêm khách hàng mới
            </button>
            <div className="search">
              <img src="/assets/icon/searchModel.svg" alt="searchModel" />
              <input
                type="text"
                onKeyPress={handleKeyPressSpecialCharacters}
                placeholder="Nhập số điện thoại"
                onChange={(e: any) => {
                  productInPostStore.dataFilterCustomer.phone = e.target.value;
                }}
              />
            </div>
          </div>
        }
        open={productInPostStore.isOpenModalListCustomer}
        onCancel={() => {
          productInPostStore.isOpenModalListCustomer = false;
        }}
        closeIcon={
          <img
            src="/assets/icon/icon_closeWhite.svg"
            width={25}
            height={25}
            alt="img"
          ></img>
        }
        footer={[
          <div className="footerListCustomer">
            <button
              key="ok"
              className={`custom-ok-button ${
                productInPosCartStore.dataCustomer?.id ? "" : "setWidth"
              }`}
              onClick={() => {
                if (productInPostStore.idCustomer) {
                  StorageService.setObjectStore(
                    `customerPos${postId}`,
                    productInPostStore.dataCustomer.find(
                      (item: any) => item.id === productInPostStore.idCustomer
                    )
                  );
                  productInPosCartStore.dataCustomer =
                    StorageService.getObjectStore(`customerPos${postId}`);
                }
                productInPostStore.isOpenModalListCustomer = false;
              }}
            >
              OK
            </button>

            {productInPosCartStore.dataCustomer?.id && (
              <button
                key="ok"
                className="custom-cancel-button"
                onClick={() => {
                  StorageService.removeLocalStore(`customerPos${postId}`);
                  productInPosCartStore.dataCustomer = null;
                  productInPostStore.isOpenModalListCustomer = false;
                }}
              >
                Bỏ Chọn
              </button>
            )}
          </div>,
        ]}
        wrapClassName="ModalListCustomer"
      >
        <div className="listCustomer">
          {productInPostStore.isLoading ? (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading />
            </div>
          ) : productInPostStore.dataCustomer.length <= 0 ? (
            <NoContent />
          ) : (
            productInPostStore.dataCustomer.length > 0 &&
            productInPostStore.dataCustomer.map((item) => (
              <div
                key={item.id}
                onClick={() => {
                  productInPostStore.idCustomer = item.id;
                }}
                className={`customer-item ${
                  productInPostStore.idCustomer === item.id ? "active" : ""
                }`}
              >
                <div className="customer-item__infor">
                  <h3>{item.name}</h3>
                  {item.address && <p>{item.address}</p>}
                  {item.email && (
                    <div>
                      <img src="/assets/icon/emaiCustomer.svg" alt="img" />
                      <h4>{item.email}</h4>
                    </div>
                  )}
                  {item.phone && (
                    <div>
                      <img src="/assets/icon/phoneCustomer.svg" alt="img" />
                      <h4>{item.phone}</h4>
                    </div>
                  )}
                </div>
                <div
                  className="customer-item__action"
                  onClick={() => {
                    productInPostStore.typeModal = "edit";
                    productInPostStore.isOpenModalListCustomer = false;
                    productInPostStore.isOpenModalAddEditCustomer = true;
                    productInPostStore.dataFormCustomer = {
                      ...item,
                      province_id: item.city_id || null,
                    };
                  }}
                >
                  <button>Chi tiết</button>
                </div>
              </div>
            ))
          )}
        </div>
      </Modal>

      <AddEditCustomer />
    </>
  );
};

export default observer(ModalListCustomer);
