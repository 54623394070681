import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {createSearchParams, Link} from "react-router-dom";
import './styles/create_product.css';
import {productStore} from "../ProductStore";
import CategoryAndBrand from "./components/CategoryAndBrand";
import PriceAndSku from "./components/PriceAndSku";
import InfoProduct from "./components/InfoProduct";
import ImageCreateProduct from "./components/ImageCreateProduct";
import TypeCreateProduct from "./components/TypeCreateProduct";
import InventoryAndVariantCreateProduct from "./components/InventoryAndVariantCreateProduct";
import {dashboardStore} from "../../../dashboard/DashboardStore";
import {Button, Form, Input, InputNumber, Modal, Select, Space} from 'antd';
import VariantCreateProduct from "./components/VariantCreateProduct";
import {categoryProductStore} from "../../categoryProduct/CategoryProductStore";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import {toastUtils} from "../../../../common/utils/Toastutils";
import {handleKeyPress, sliceText} from "../../../../common/utils/Utils";
import { toJS } from "mobx";
const CreateProductPage = () => {
    const [formAttributes] = Form.useForm();
    const [form] = Form.useForm();
    useEffect(() => {
        productStore.fetchListFeature().then();
        productStore.fetchListCategory().then();
        productStore.fetchListBrand().then();
        productStore.fetchListTags().then();
        productStore.fetchDataStore().then();
        productStore.fetchListAttribute().then();
    }, [dashboardStore.dataListStore])

    useEffect(() => {
        formAttributes.setFieldsValue({
            price_original: productStore.selectedItem?.price_original,
            price_compare_original: productStore.selectedItem?.price_compare_original,
            sku_original: productStore.selectedItem?.sku_original,
            weight_original: productStore.selectedItem?.weight_original,
            height_original: productStore.selectedItem?.height_original,
            length_original: productStore.selectedItem?.length_original,
            width_original: productStore.selectedItem?.width_original,
            status_attribute: productStore.selectedItem?.status_attribute,
            allow_inventory_attribute: productStore.selectedItem?.allow_inventory_attribute,
            qty_attribute: productStore.selectedItem?.qty_attribute,
        });
    }, [productStore.selectedItem, formAttributes]);


    const handleQtyChange = (index:any, delta:any) => {
        const item = productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[index];
        const newQty = Math.max(0, (item.qty || 0) + delta);
        item.qty = newQty;
    };
    const handleInputChange = (index:any, value:any) => {
        const newQty = Math.max(0, value);
        productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[index].qty = newQty;
    };
    return(
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img src="/assets/icon/arrow-left.svg"
                                     alt="fi-sr-caret-down"
                                />
                                THÊM SẢN PHẨM
                            </span>
                        </Link>
                    </div>
                    <Form className="form-create-product"
                        form={form}
                        name="control-hooks"
                          onFinish={(values) => {
                              if(!productStore.staticDataProduct.preview || productStore.staticDataProduct.multi_images.length === 0) {
                                  if (productStore.staticDataProduct.preview === '') {
                                      toastUtils.warning('Hình ảnh sản phẩm không được để trống', '1');
                                  }
                                  if (productStore.staticDataProduct.multi_images.length === 0) {
                                      toastUtils.warning('Hình ảnh bộ sưu tập không được để trống', '2');
                                  }
                                  return false
                              }
                              productStore.staticDataProduct.name = values.name
                              productStore.staticDataProduct.short_description = values.short_description
                              productStore.staticDataProduct.status = values.status
                              productStore.staticDataProduct.show_price = values.show_price
                              productStore.staticDataProduct.featured = values.featured
                              productStore.staticDataProduct.categories = values.categories
                              productStore.staticDataProduct.brand = values.brand
                              productStore.staticDataProduct.tags = values.tags
                              productStore.staticDataProduct.price_origin = values.price_origin
                              productStore.staticDataProduct.price_compare_origin = values.price_compare_origin
                              productStore.staticDataProduct.sku_origin = values.sku_origin
                              productStore.staticDataProduct.unit = values.unit
                              productStore.staticDataProduct.height_origin = values.height_origin
                              productStore.staticDataProduct.width_origin = values.width_origin
                              productStore.staticDataProduct.length_origin = values.length_origin
                              productStore.staticDataProduct.weight_origin = values.weight_origin
                              productStore.staticDataProduct.product_type = values.product_type ? values.product_type : '0';
                              if(values.product_type == 1){
                                let hasEmptyThumbnail = false
                                  productStore.staticDataProduct.product_attribute_sku = productStore.listGenerateAttributes.map((itemGenerateAttributes:any,index:number) => {
                                      if(itemGenerateAttributes.thumbnail === ''){
                                          toastUtils.warning(`Hình ảnh thuộc tính ${itemGenerateAttributes.attribute_name} không được để trống`, `${itemGenerateAttributes.id}${index}`);
                                          hasEmptyThumbnail = true;
                                          return null;
                                      }
                                      return {
                                          'thumbnail' : itemGenerateAttributes.thumbnail,
                                          'sku' : itemGenerateAttributes.sku_original,
                                          'attributeIds': itemGenerateAttributes.attribute_ids,
                                          'width' : itemGenerateAttributes.width_original,
                                          'weight' : itemGenerateAttributes.weight_original,
                                          'height' : itemGenerateAttributes.height_original,
                                          'length' : itemGenerateAttributes.length_original,
                                          'price' : itemGenerateAttributes.price_original,
                                          'priceCompare' : itemGenerateAttributes.price_compare_original,
                                          'status' : itemGenerateAttributes.allow_inventory_attribute,
                                          'allow_inventory' : itemGenerateAttributes.allow_inventory_attribute,
                                          'stock_status' : itemGenerateAttributes.allow_inventory_attribute,
                                          'allow_order' : '1',
                                          'qty' : itemGenerateAttributes.qty_attribute,
                                      }
                                  })
                                  if (hasEmptyThumbnail) return;
                              }else {
                                  productStore.staticDataProduct.qty =  productStore.arrayQtyStore
                              }
                                productStore.fetchStoreProduct(form)
                          }}
                        style={{ maxWidth: 600 }}
                    >
                        <ImageCreateProduct />
                        <InfoProduct />
                        <CategoryAndBrand />
                        <PriceAndSku form={form} />
                        <TypeCreateProduct />
                        {productStore.staticDataProduct.product_type === '1' ? (<VariantCreateProduct />) : (<InventoryAndVariantCreateProduct />)}
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    {productStore.isLoadingStore === false ? 'Tạo sản phẩm' : 'Đang lưu...' }
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                    {productStore.isModalOpen && productStore.selectedItem && (
                        <Modal
                            className=""
                            title="Chỉnh sửa thuộc tính"
                            footer={null}
                            open={productStore.isModalOpen}
                            onCancel={productStore.handleModalClose}
                            afterClose={productStore.handleClose}
                        >
                            <Form className="form-create-edit"
                                  form={formAttributes}
                                  name="control-hooks-edit"
                                  onFinish={(values) => {
                                      // const index = productStore.selectedItem.index;
                                      // const selectedItem = productStore.listGenerateAttributes[index];
                                      // selectedItem.price_original = values.price_original;
                                      // selectedItem.price_compare_original = values.price_compare_original;
                                      // selectedItem.sku_origin = values.sku_origin;
                                      // selectedItem.weight_original = values.weight_original;
                                      // selectedItem.height_origin = values.height_origin;
                                      // selectedItem.length_origin = values.length_origin;
                                      // selectedItem.width_origin = values.width_origin;
                                      // selectedItem.status_attribute = values.status_attribute;
                                      // selectedItem.allow_inventory_attribute = values.allow_inventory_attribute;
                                      productStore.setIsModalOpen(false);
                                  }}
                            >
                                <div className="item-form">
                                    <Form.Item
                                        name="price_original"
                                        label="Giá bán"
                                        rules={[{required: true, message: "Không được để trống!"}]}
                                    >
                                        <InputNumber
                                            onKeyPress={handleKeyPress}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as unknown as number}
                                            defaultValue={productStore.selectedItem.price_original}
                                            onChange={(value) => {
                                                productStore.listGenerateAttributes[productStore.selectedItem.index].price_original = `${value}`;
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="item-form">
                                    <Form.Item
                                        name="price_compare_original"
                                        label="Giá gốc"
                                        rules={[{required: true, message: "Không được để trống!"},
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    const priceOrigin = getFieldValue('price_original');
                                                    if (value >= 0 && priceOrigin && priceOrigin > value) {
                                                        return Promise.reject(new Error('Giá gốc không được nhỏ hơn giá bán!'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            onKeyPress={handleKeyPress}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as unknown as number}
                                            defaultValue={productStore.selectedItem.price_compare_original}
                                            onChange={(value) => {
                                                productStore.listGenerateAttributes[productStore.selectedItem.index].price_compare_original = `${value}`;
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="item-form">
                                    <Form.Item
                                        name="sku_original"
                                        label="SKU"
                                        rules={[{required: true, message: "Không được để trống!"},{min: 4, message: "Sku phải từ 4 ký tự trở lên"}]}
                                    >
                                        <Input
                                            defaultValue={productStore.selectedItem.sku_original}
                                            onChange={(event) => {
                                                productStore.listGenerateAttributes[productStore.selectedItem.index].sku_original = event.target.value;
                                            }}/>
                                    </Form.Item>
                                </div>
                                <div className="grid-2 gap-20">
                                    <div className="item-form">
                                        <Form.Item
                                            name="weight_original"
                                            label="Cân nặng (gram)"
                                            rules={[{required: true, message: "Không được để trống!"}]}
                                        >
                                            <Input
                                                onKeyPress={handleKeyPress}
                                                type="text"
                                                defaultValue={productStore.selectedItem.weight_original}
                                                onChange={(event) => {
                                                    productStore.listGenerateAttributes[productStore.selectedItem.index].weight_original = event.target.value;
                                                }}/>
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="height_original"
                                            label="Chiều cao (cm)"
                                            rules={[{required: true, message: "Không được để trống!"}]}
                                        >
                                            <Input
                                                onKeyPress={handleKeyPress}
                                                type="text"
                                                defaultValue={productStore.selectedItem.height_original}
                                                onChange={(event) => {
                                                    productStore.listGenerateAttributes[productStore.selectedItem.index].height_original = event.target.value;
                                                }}/>
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="length_original"
                                            label="Chiều dài (cm)"
                                            rules={[{required: true, message: "Không được để trống!"}]}
                                        >
                                            <Input
                                                onKeyPress={handleKeyPress}
                                                type="text"
                                                defaultValue={productStore.selectedItem.length_original}
                                                onChange={(event) => {
                                                    productStore.listGenerateAttributes[productStore.selectedItem.index].length_original = event.target.value;
                                                }}/>
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="width_original"
                                            label="Chiều rộng (cm)"
                                            rules={[{required: true, message: "Không được để trống!"}]}
                                        >
                                            <Input
                                                onKeyPress={handleKeyPress}
                                                type="text"
                                                defaultValue={productStore.selectedItem.width_original}
                                                onChange={(event) => {
                                                    productStore.listGenerateAttributes[productStore.selectedItem.index].width_original = event.target.value;
                                                }}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="item-form">
                                    <Form.Item name="status_attribute" label="Tình trạng" rules={[{required: true, message: 'Không được để trống!'}]}>
                                        <SelectStatus selected={() => productStore.listGenerateAttributes[productStore.selectedItem.index].status_attribute}
                                                      onChange={(value: any) => productStore.listGenerateAttributes[productStore.selectedItem.index].status_attribute = value}
                                                      textOn={`Còn hàng`} textOff={`Hết hàng`}/>
                                    </Form.Item>
                                </div>
                                <div className="item-form">
                                    <Form.Item name="allow_inventory_attribute" label="Loại tồn kho" rules={[{required: true, message: 'Không được để trống!'}]}>
                                        <SelectStatus selected={() => productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute}
                                                      onChange={(value: any) => productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute = value}
                                                      textOn={`Cho phép quản lý tồn kho`} textOff={`Không cho phép quản lý tồn kho`}/>
                                    </Form.Item>
                                </div>
                                {productStore.listGenerateAttributes[productStore.selectedItem.index].allow_inventory_attribute === '1' && (
                                    <>
                                        {/*<label className="label-allow-order">*/}
                                        {/*    <Checkbox*/}
                                        {/*        name="allow_order_when_out_of_stock"*/}
                                        {/*        onChange={handleCheckboxChange}*/}
                                        {/*        checked={productStore.staticDataProduct.allow_inventory === '1'}*/}
                                        {/*    >*/}
                                        {/*        Cho phép đặt hàng khi sản phẩm hết hàng*/}
                                        {/*    </Checkbox>*/}
                                        {/*</label>*/}
                                        <div className="item-form-inventory">
                                            {productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute.map((itemStore:any, indexStore:number) => (
                                                <div className="item-store" key={indexStore}>
                                                    <p>{sliceText(itemStore.name,30)}</p>
                                                    <div className="qty">
                                                        <div className="minus">
                                                            <button
                                                                type="button"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    handleQtyChange(indexStore, -1);
                                                                }}
                                                            >
                                                                -
                                                            </button>
                                                        </div>
                                                        <Input
                                                            type="number"
                                                            placeholder="0"
                                                            value={productStore.listGenerateAttributes[productStore.selectedItem.index].qty_attribute[indexStore].qty || ""}
                                                            onChange={(e) => handleInputChange(indexStore, parseInt(e.target.value, 10))}
                                                            min="0"
                                                            max="99999"
                                                            maxLength={16}
                                                        />
                                                        <div className="plus">
                                                            <button
                                                                type="button"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    handleQtyChange(indexStore, 1);
                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <Form.Item>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Hoàn tất
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Modal>
                    )}
                </div>
            </div>
        </>
    )
}
export default observer(CreateProductPage);