import React, { useEffect } from "react";
import "./ProductInPosControl.scss";
import { observer } from "mobx-react";
import { productInPostStore } from "../../ProductInPostStore";
import ModalListCustomer from "../modal/modalListCustomer/ModalListCustomer";
import {
  IdataFormAddCart,
  productInPosCartStore,
} from "../../ProductInPosCartStore";
import { utils } from "../../../../../common/utils/Utils";
import StorageService from "../../../../../common/service/StorageService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toJS } from "mobx";
import { toastUtils } from "../../../../../common/utils/Toastutils";

export const dataNumber = [
  { id: 1, content: "1" },
  { id: 2, content: "2" },
  { id: 3, content: "3" },
  { id: "sl", content: "SL" },
  { id: 4, content: "4" },
  { id: 5, content: "5" },
  { id: 6, content: "6" },
  { id: "ck", content: "%CK" },
  { id: 7, content: "7" },
  { id: 8, content: "8" },
  { id: 9, content: "9" },
  { id: "price", content: "Giá" },
  { id: "del1", content: "Xóa" },
  { id: 0, content: "0" },
  { id: "dots", content: "." },
  { id: "del2", content: "", icon: "/assets/icon/deletePayment.svg" },
];

const ProductInPosControl = () => {
  const navigate = useNavigate();
  const { postId } = useParams();

  useEffect(() => {
    if (
      productInPosCartStore.dataListProInCart.length > 0 &&
      !productInPosCartStore.flagActiveVariant
    ) {
      productInPosCartStore.activeVariantProduct =
        productInPosCartStore?.dataListProInCart[0].variant.id;
    }
  }, [productInPosCartStore?.dataListProInCart]);

  useEffect(() => {
    const storedCart = StorageService.getObjectStore(`customerPos${postId}`);
    if (storedCart) {
      productInPosCartStore.dataCustomer = storedCart;
    }
  }, [postId]);

  return (
    <div className="ProductInPosControl">
      <div>
        <div className="ProductInPosControl-header">
          <img src="/assets/icon/barcode.svg" alt="barcode"/>
          <input
              type="text"
              placeholder=""
              value={productInPosCartStore.searchBarcode}
              onChange={(e: any) => {
              productInPosCartStore.searchBarcode = e.target.value;
              productInPosCartStore.getVariantByBarcode(
                productInPosCartStore.searchBarcode
              );
            }}
          />

          <img onClick={() => {
            if(productInPostStore.isBarcode === false){
                productInPostStore.isBarcode = true
            }else{
              productInPostStore.isBarcode = false
            }
          }} src={productInPostStore.isBarcode ? '/assets/icon/camera_close.svg' : '/assets/icon/camera.svg'} className="camera" alt="camera" />
        </div>
        <div className="ProductInPosControl-body">
          {productInPosCartStore.dataListProInCart.length > 0 ? (
            productInPosCartStore.dataListProInCart.map(
              (item: IdataFormAddCart) => (
                <div
                  className={`ProductInPosControl-product ${
                    productInPosCartStore.activeVariantProduct ===
                    item.variant.id
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    productInPosCartStore.activeVariantProduct =
                      item.variant.id;
                  }}
                  key={item.sku}
                >
                  <div className="boxProduct-infor">
                    <h2>
                      {item.productName}{" "}
                      {item.variant.name ? `- ${item.variant.name}` : ""}
                    </h2>
                    <p>
                      {item.quantity} {item.unit} x{" "}
                      {utils.formatCurrency(Number(item.price))}/ {item.unit}
                    </p>
                    {item.discount > 0 ||
                    String(item.discount).includes(".") ? (
                      <p>Chiết khấu {item.discount}% với một sản phẩm</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="boxProduct-total">
                    {utils.formatCurrency(
                      item.discount > 0
                        ? ((item.price as number) *
                            item.quantity *
                            (100 - item.discount)) /
                            100
                        : (item.price as number) * item.quantity
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <div className="emptyCart">
              <img src="/assets/images/emptyCart.svg" alt="" />
              <p>Bắt đầu thêm sản phẩm</p>
            </div>
          )}
        </div>
      </div>
      <div className="ProductInPosControl-footer">
        {productInPosCartStore.dataListProInCart.length > 0 && (
          <div className="total-price ">
            <p>(Giá đã bao gồm VAT)</p>{" "}
            <h3>
              {" "}
              Tổng:{" "}
              {utils.formatCurrency(
                productInPosCartStore.CaculatorTotal(
                  productInPosCartStore.dataListProInCart
                )
              )}
            </h3>
          </div>
        )}
        <div className="action">
          <div
            className="action-item"
            onClick={() => {
              productInPostStore.isOpenModalListCustomer = true;
            }}
          >
            <img src="/assets/icon/customer.svg" alt="" />
            <div className="text-truncate" style={{ maxWidth: "100px" }}>
              {productInPosCartStore.dataCustomer?.name
                ? productInPosCartStore.dataCustomer?.name
                : "Khách hàng"}
            </div>
          </div>
          <div className="action-item" onClick={()=> navigate(`/pos/${postId}/hoan-tien`)}>
            <img src="/assets/icon/refund.svg" alt="" />
            Hoàn tiền
          </div>
          <div
            className="action-item"
            onClick={() => {
              StorageService.removeLocalStore(`cartPos${postId}`);
              productInPosCartStore.dataListProInCart = [];
            }}
          >
            <img src="/assets/icon/deleteAll.svg" alt="" />
            Xóa tất cả
          </div>
        </div>
        <div className="caculator">
          <div className="caculator-number">
            {dataNumber.map((item) => (
              <div
                className={`caculator-number__Minbox ${
                  productInPosCartStore.productControl.type === item.id
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (
                    item.id === "sl" ||
                    item.id === "ck" ||
                    item.id === "price"
                  ) {
                    // Cập nhật cả type và keycap cho các nút đặc biệt
                    productInPosCartStore.ChangeValueProductInfo(
                      item.id, // Cập nhật type
                      item.id, // Cập nhật keycap
                      productInPosCartStore.activeVariantProduct
                    );
                  } else {
                    // Chỉ cập nhật keycap cho các nút khác
                    productInPosCartStore.ChangeValueProductInfo(
                      productInPosCartStore.productControl.type, // Giữ nguyên type
                      item.id, // Cập nhật keycap
                      productInPosCartStore.activeVariantProduct
                    );
                  }
                }}
                key={item.id}
              >
                {item.content}
                {item.icon && <img src={item.icon} alt="img" />}
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              productInPosCartStore.dataListProInCart.length > 0
                ? navigate(`/pos/${postId}/thanh-toan`)
                : toastUtils.warning("Bạn chưa chọn sản phẩm nào");
            }}
            className="caculator-payment"
          >
            <img src="/assets/icon/arrowPayment.svg" alt="arr" />
            Thanh Toán
          </div>
        </div>
      </div>
      <ModalListCustomer />
    </div>
  );
};

export default observer(ProductInPosControl);
