import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";
import { attributeStore } from "./AttributeStore";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
// import "./attribute.css";
import "./attribute.scss";
import Loading from "../../../shared/components/loading/Loading";
import AttributeModal from "./AttributeModal";
import NoContent from "../../../shared/components/NoContent";
import { showDelete } from "../../../shared/components/showDelete";
import { handleKeyPressSpecialCharacters } from "../../../common/utils/Utils";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Attribute = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        attributeStore.staticParamAttribute.search =
            searchParams.get("search") || "";
        attributeStore.staticParamAttribute.page = page || 0;

        attributeStore.getDataListAttribute();
        // attributeStore.getDataListAttributeAll();
    }, [location.search]);

    const handleSearchURL = () => {
        updateUrlParams(true);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();
        const params = {
            search: attributeStore.staticParamAttribute.search,
            page: (attributeStore.staticParamAttribute.page + 1).toString(),
        };

        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });

        navigate({ search: searchParams.toString() }, { replace: true });
    };

    function handleSelect(value: boolean, name: string) {
        // handle select checkbox for each item in the table
        if (value) {
            attributeStore.selectedTargets.push(name);
        } else {
            attributeStore.selectedTargets =
                attributeStore.selectedTargets.filter((item) => item !== name);
        }
    }
    var allSelectedTargetInTable: boolean =
        attributeStore.dataListAttribute === undefined
            ? false
            : attributeStore.selectedTargets.length ===
              attributeStore.dataListAttribute.length;
    function selectAllTargetInTable(value: boolean) {
        //handle select The Header Checkbox in table
        if (value) {
            attributeStore.selectedTargets =
                attributeStore.dataListAttribute.map((item: any) => item.id);
        } else {
            attributeStore.selectedTargets = [];
        }
    }

    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        attributeStore.staticParamAttribute.page = selectedPage;
        handleSearchURL();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    function onSubmitSearch(event: any) {
        event.preventDefault();
        attributeStore.staticParamAttribute.page = 0;
        handleSearchURL();
    }
    function onHandleKeyDown(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch(event);
        }
    }
    return (
        <div className="container">
            <div className="mainContentAttribute">
                <div className="topbar">
                    <div className="topbar__filter">
                        <div className="topbar__title">Thuộc tính</div>
                        <form
                            className="topbar__search"
                            onSubmit={onSubmitSearch}
                        >
                            <input
                                id="search"
                                type="text"
                                // onKeyDown={(event: any) => {
                                //     onHandleKeyDown(event);
                                // }}
                                onKeyPress={handleKeyPressSpecialCharacters}
                                value={
                                    attributeStore.staticParamAttribute.search
                                }
                                onChange={(event) =>
                                    (attributeStore.staticParamAttribute.search =
                                        event.target.value)
                                }
                                placeholder="Nhập tên thuộc tính"
                            />
                            <button type="submit">
                                <img src="/assets/icon/search.svg" alt="" />
                            </button>
                        </form>
                    </div>
                    <button
                        className="btn-add-modal"
                        onClick={() => {
                            attributeStore.openModal = true;
                            attributeStore.isCreating = true;
                            attributeStore.modalName = "Thêm mới thuộc tính";
                            attributeStore.handleResetModal();
                        }}
                    >
                        <span>
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                            Thêm mới
                        </span>
                    </button>
                </div>
                <div className="body-page">
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}>Tên</th>
                                        <th style={{ width: "20%" }}>
                                            Chọn loại
                                        </th>
                                        <th style={{ width: "40%" }}>
                                            Thuộc tính con
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "right",
                                                paddingRight: "22px",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attributeStore.isLoadingTable === true ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="loading__container">
                                                    <Loading />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : attributeStore.dataListAttribute
                                          .length == 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                {" "}
                                                <NoContent />{" "}
                                            </td>
                                        </tr>
                                    ) : (
                                        attributeStore.dataListAttribute.map(
                                            (item: any, index: any) => (
                                                    <tr key={index}>
                                                        <td
                                                            className="name"
                                                            style={{
                                                                width: "25%",
                                                            }}
                                                        >
                                                            <span
                                                                title={
                                                                    item.name
                                                                }
                                                            >
                                                                {item.name}
                                                            </span>
                                                        </td>
                                                        <td
                                                            className="slug"
                                                            style={{
                                                                width: "20%",
                                                            }}
                                                        >
                                                            <span
                                                                title={
                                                                    item.slug
                                                                }
                                                            >
                                                                {item.slug}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            <div className="attr__container">
                                                                {item.attributes.map(
                                                                    (
                                                                        element: any,
                                                                        index: any
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="attr__item"
                                                                        >
                                                                            {
                                                                                element.name
                                                                            }
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "15%",
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Dropdown
                                                                align={"start"}
                                                                className="action-table"
                                                            >
                                                                <Dropdown.Toggle>
                                                                    Thao tác
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            attributeStore.handleResetModal();
                                                                            attributeStore.openModal =
                                                                                true;
                                                                            attributeStore.staticDataAttribute.id =
                                                                                item.id;
                                                                            attributeStore.staticDataAttribute.name =
                                                                                item.name;
                                                                            attributeStore.staticDataAttribute.attributes =
                                                                                item.attributes;
                                                                            attributeStore.isCreating =
                                                                                false;
                                                                            attributeStore.modalName =
                                                                                "Chỉnh sửa thuộc tính";
                                                                            // attributeStore.getDataListAttributeChildren()
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Sửa
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                                <AttributeModal />
                            </table>
                            {attributeStore.isLoadingTable
                                ? ""
                                : Math.ceil(attributeStore.meta.total_page) >
                                      1 && (
                                      <div className="pagination__container">
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              pageCount={Math.ceil(
                                                  attributeStore.meta.total_page
                                              )}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              forcePage={
                                                  attributeStore
                                                      .staticParamAttribute.page
                                              }
                                          />
                                          {/* <div className="pagination__info">
                                              Hiển thị{" "}
                                              {attributeStore.meta.page !=
                                              attributeStore.meta.total_page
                                                  ? attributeStore.meta.size
                                                  : attributeStore.meta.total -
                                                    (attributeStore.meta.page -
                                                        1) *
                                                        attributeStore.meta
                                                            .size}
                                              /Tổng số{" "}
                                              {attributeStore.meta.total} thuộc
                                              tính
                                          </div> */}
                                      </div>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Attribute);
