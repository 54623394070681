import Constants from "../../../common/constants/Constants";
import { getRequest, postRequest, putRequest } from "../../../common/helpers/RequestHelper";
import { productInPostStore, TFormCustomer } from "./ProductInPostStore";

class ProductInPosService {

    public fetchAllCustomer(): Promise<any> {
        return getRequest(`api/v1/customer?page_number=1&per_page=${Constants.maxSize}${productInPostStore.dataFilterCustomer.phone ? `&phone=${productInPostStore.dataFilterCustomer.phone}` : ""}`);
    }

    public createCustomer(data: TFormCustomer): Promise<any> {
        return postRequest(`api/v1/customer`,data);
    }

    public updateCustomer(idCustomer: string, data: TFormCustomer): Promise<any> {
        return putRequest(`api/v1/customer/${idCustomer}`,data);
    }

    public fetchProvices(): Promise<any> {
        return getRequest(`api/v1/provinces`);
    }
    public fetchDistricts(provinceId: string): Promise<any> {
        return getRequest(`api/v1/districts?province_id=${provinceId}`);
    }
    public fetchWards(districtId: string): Promise<any> {
        return getRequest(`api/v1/wards?district_id=${districtId}`);
    }

    // product

    public fetchProductByType (type: string, id: any) : Promise<any> {
        return getRequest(`api/v1/products/${id}?request_type=${type}`);
    }
    public fetchDetailVariantByAttributeId (productId: any, params: any) : Promise<any> {
        return getRequest(`api/v1/products/get_detail_variant_by_attribute_ids?product_id=${productId}`,params);
    }
    public fetchListAttributeByProduct(productId: any) : Promise<any> {
        return getRequest(`api/v1/products/list_attribute_by_product?product_id=${productId}`);
    }

    public fetchVariantByBarcode(barcode: string){
        return getRequest(`api/v1/products/find_variant_by_barcode?barcode=${barcode}`);
    }
}

export const productInPosService = new ProductInPosService();