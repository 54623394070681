import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";
import { brandStore } from "./BrandStore";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import "./styles/brand.scss";
import Loading from "../../../shared/components/loading/Loading";
import BrandModal from "./components/BrandModal";
import NoContent from "../../../shared/components/NoContent";
import { showDelete } from "../../../shared/components/showDelete";
import { handleKeyPressSpecialCharacters } from "../../../common/utils/Utils";

const Brand = () => {
    const [selectedTargets, setSelectedTarget] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = searchParams.get("page");
        const page = pageParam
            ? parseInt(pageParam) > 1
                ? parseInt(pageParam) - 1
                : 0
            : 0;

        brandStore.staticParamBrand.search = searchParams.get("search") || "";
        brandStore.staticParamBrand.page = page || 0;
        brandStore.fetchListBrands();
    }, [location.search]);

    const handleSearchURL = () => {
        updateUrlParams(true);
    };

    const updateUrlParams = (isSearch: boolean) => {
        const searchParams = new URLSearchParams();
        const params = {
            search: brandStore.staticParamBrand.search,
            page: (brandStore.staticParamBrand.page + 1).toString(),
        };
        // Duyệt qua từng key trong params và set nếu có giá trị
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                searchParams.set(key, value);
            }
        });
        navigate({ search: searchParams.toString() }, { replace: true });
        // if (isSearch) {
        //     brandStore.fetchListBrands();
        // }
    };

    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelectedTarget([...selectedTargets, name]);
        } else {
            setSelectedTarget(selectedTargets.filter((item) => item !== name));
        }
    }
    const allSelectedTargetInTable =
        selectedTargets.length === brandStore.dataListBrand.length;
    function selectAllTargetInTable(value: boolean) {
        if (value) {
            setSelectedTarget(
                brandStore.dataListBrand.map((item: any) => item.id)
            );
        } else {
            setSelectedTarget([]);
        }
    }
    const showDeleteConfirm = () => {
        showDelete.showDeleteConfirm(
            "Xác nhận xoá thương hiệu",
            "Bạn chắc chắn muốn xoá thương hiệu này?(Hành động này không thể hoàn tác)",
            async () => {
                await brandStore.fetchDeleteBrand();
            }
        );
    };

    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (brandStore.staticParamBrand.search !== "") {
            params.keyword = `search=${brandStore.staticParamBrand.search}`;
        }
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        brandStore.staticParamBrand.page = selectedPage;
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    function onSubmitSearch(event: any) {
        event.preventDefault();
        handleSearchURL();
        // brandStore.fetchListBrands();
    }
    function onHandleKeyDown(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch(event);
        }
    }

    return (
        <div className="container">
            <div className="mainContentBrand">
                <div className="header">
                    <div className="header__filter">
                        <div className="header__title">Thương hiệu</div>
                        <form
                            className="header__search"
                            onSubmit={onSubmitSearch}
                        >
                            <input
                                id="search"
                                type="text"
                                // onKeyDown={(event: any) => {
                                //     onHandleKeyDown(event);
                                // }}
                                onKeyPress={handleKeyPressSpecialCharacters}
                                value={brandStore.staticParamBrand.search}
                                onChange={(event) =>
                                    (brandStore.staticParamBrand.search =
                                        event.target.value)
                                }
                                placeholder="Nhập tên thương hiệu"
                            />
                            <button type="submit">
                                <img src="/assets/icon/search.svg" alt="" />
                            </button>
                        </form>
                    </div>
                    <button
                        className="btn-add-modal"
                        onClick={() => {
                            brandStore.isModal = true;
                            brandStore.openModal = true;
                            brandStore.isCreating = true;
                            brandStore.handleClearContent();
                            brandStore.handlerName = "Thêm mới thương hiệu";
                        }}
                        style={{
                            width: "130px",
                        }}
                    >
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className="body-page">
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}>Tên</th>
                                        <th style={{ width: "30%" }}>
                                            Ngày tạo
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Nổi bật
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Trạng thái
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "right",
                                                paddingRight: "22px",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {brandStore.isLoading === true ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="loading__container">
                                                    <Loading />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : brandStore.dataListBrand.length ===
                                      0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                {" "}
                                                <NoContent />{" "}
                                            </td>
                                        </tr>
                                    ) : (
                                        brandStore.dataListBrand.map(
                                            (item, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td className="name">
                                                            {item.image ===
                                                                null ||
                                                            item.image ===
                                                                "" ? (
                                                                <img
                                                                    src="/assets/images/no-image.svg"
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        item.image
                                                                    }
                                                                    alt=""
                                                                />
                                                            )}
                                                            <span
                                                                title={
                                                                    item.name
                                                                }
                                                            >
                                                                {item.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {item.featured ===
                                                                1 ||
                                                            item.featured ===
                                                                "1" ? (
                                                                <span className="active">
                                                                    Hoạt động
                                                                </span>
                                                            ) : (
                                                                <span className="inactive">
                                                                    Vô hiệu hóa
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {String(
                                                                item.status
                                                            ) != String(1) ? (
                                                                <span className="inactive">
                                                                    Vô hiệu hoá
                                                                </span>
                                                            ) : (
                                                                <span className="active">
                                                                    Hoạt động
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Dropdown
                                                                align={"start"}
                                                                className="action-table"
                                                                ref={
                                                                    brandStore.dropdownRef
                                                                }
                                                            >
                                                                <Dropdown.Toggle>
                                                                    Thao tác
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            brandStore.openModal =
                                                                                true;
                                                                            brandStore.isCreating =
                                                                                false;
                                                                            brandStore.handlerName =
                                                                                "Chỉnh sửa thương hiệu";
                                                                            brandStore.isModal =
                                                                                false;
                                                                            brandStore.initialValues =
                                                                                {
                                                                                    id: item.id,
                                                                                    name: item.name,
                                                                                    status: item.status,
                                                                                    featured:
                                                                                        item.featured,
                                                                                    description:
                                                                                        item.description,
                                                                                    image: item.image,
                                                                                };
                                                                            // brandStore.staticDataBrand.id =
                                                                            //     item.id;
                                                                            // brandStore.getSpecificBrand();
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Sửa
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            showDeleteConfirm();
                                                                            brandStore.staticDataBrand.id =
                                                                                item.id;
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                        Xóa
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        )
                                    )}
                                </tbody>
                                <BrandModal />
                            </table>
                            {brandStore.isLoading
                                ? ""
                                : Math.ceil(brandStore.metadata.total_page) >
                                      1 && (
                                      <div className="pagination__container">
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              pageCount={
                                                  brandStore
                                                      .filteredDataListBrand
                                                      .length != 0
                                                      ? Math.ceil(
                                                            brandStore
                                                                .filteredDataListBrand
                                                                .length /
                                                                brandStore
                                                                    .staticParamBrand
                                                                    .per_page
                                                        )
                                                      : Math.ceil(
                                                            brandStore.metadata
                                                                .total_page
                                                        )
                                              }
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              forcePage={
                                                  brandStore.staticParamBrand
                                                      .page
                                              }
                                          />
                                      </div>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Brand);
