import { object } from "yup";
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";
import { log } from "console";

class ProductService {
    apiSubdirectory = "api/v1/products";
    apiSubFeature = "api/v1/features";
    apiSubProductCategories = "api/v1/product_categories";
    apiSubBrand = "api/v1/brands";
    apiSubTag = "api/v1/categorytags";
    apiSubAttribute = "api/v1/attributes";
    apiGenerateAttributes = "api/v1/products/generate_variant";
    apiCreateVariant = "api/v1/products/add_new_variant";
    apiRemoveVariant = "api/v1/products/remove_variant";
    apiImportFile = "api/v1/import";
    apiExportFilePro = "api/v1/export/product";
    apiGetProcessFile = "api/v1/import/process"
    public fetchImportFile(objectName: string, params: any): Promise<any> {
        return postRequest(`${this.apiImportFile}/${objectName}`, params);
    }
    public fetchExportFile(): Promise<any> {
        return postRequest(`${this.apiExportFilePro}`, {});
    }
    public fetchProcessFile(objectName: string, params: any) {
        return getRequest(`${this.apiGetProcessFile}/${objectName}`, params);
    }
    public fetchDataImportFile() {
        return getRequest(`${this.apiImportFile}/product`);
    }
    public fetchListFeature(): Promise<any> {
        return getRequest(`${this.apiSubFeature}`);
    }
    public fetchListCategory(): Promise<any> {
        return getRequest(`${this.apiSubProductCategories}`);
    }
    public fetchListBrand(): Promise<any> {
        return getRequest(`${this.apiSubBrand}`);
    }
    public fetchListTags(): Promise<any> {
        return getRequest(`${this.apiSubTag}`);
    }
    public fetchListAttribute(): Promise<any> {
        return getRequest(`${this.apiSubAttribute}`);
    }
    public fetchGenerateAttributes(params: any): Promise<any> {
        return postRequest(`${this.apiGenerateAttributes}`, params);
    }
    public fetchListProduct(params: any): Promise<any> {
        const queryParameters = [
            params.brand_id ? `brand_id=${params.brand_id}` : "",
            params.category_id ? `category_id=${params.category_id}` : "",
            params.offset ? `offset=${params.offset}` : "",
            params.order_column ? `order_column=${params.order_column}` : "",
            params.order_value ? `order_value=${params.order_value}` : "",
            params.page ? `page=${params.page}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.search ? `search=${params.search}` : "",
            params.status ? `status=${params.status}` : "",
            params.tag_id ? `tag_id=${params.tag_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
    }
    public fetchStoreProduct(params: any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`, params);
    }
    public fetchCreateVariant(id: string, params: any): Promise<any> {
        return postRequest(`${this.apiCreateVariant}/${id}`, params);
    }
    public fetchUpdateProduct(id: number, params: any): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${id}`, params);
    }
    public fetchGetProduct(id: string, params: any): Promise<any> {
        return getRequest(
            `${this.apiSubdirectory}/${id}?request_type=${params.request_type}`
        );
    }
    public fetchDeleteVariant(id: string, params: any): Promise<any> {
        return deleteRequest(
            `${this.apiRemoveVariant}/${id}?variant_id=${params.variant_id}`
        );
    }
    public fetchDeleteProduct(id: string): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${id}`);
    }
}

export const productService = new ProductService();
