import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button, Form, Input, Space} from "antd";
import {productStore} from "../ProductStore";
import EditProductMenu from "./components/EditProductMenu";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import {values} from "mobx";
import {handleKeyPress} from "../../../../common/utils/Utils";
import {toastUtils} from "../../../../common/utils/Toastutils";
import "./styles/product_variant.scss"
import { useReactToPrint } from "react-to-print";
const EditProductQrcodePage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "qrcode";
    useEffect(() => {
        productStore.fetchGetQrcodeProduct().then();
    }, []);

    const componentRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    
      const location = useLocation()

    useEffect(()=>{
        form.setFieldsValue({
            quantity: productStore.quantityQrcode,
            height: productStore.heightQrcode,
            width: productStore.widthQrcode
        })
      },[form,productStore.quantityQrcode])


      useEffect(()=>{
        form.setFieldsValue({
            quantity: 1,
            height: 100,
            width: 100
        })
        productStore.errCode = false
      },[location.pathname])
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct mainContentEditProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            productStore.heightQrcode = values.height
                            productStore.widthQrcode = values.width
                            productStore.quantityQrcode = Number(values.quantity)
                            if(values.height > 500){
                                toastUtils.warning('Kích thước chiều cao không được quá 500')
                                return false
                            }
                            if(values.width > 500){
                                toastUtils.warning('Kích thước chiều rộng không được quá 500')
                                return false
                            }
                            productStore.fetchUpdateQrcodeProduct();
                        }}
                        style={{maxWidth: 600}}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="qrcode"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="grid-3-1 gap-20">
                                        <div>
                                            <div className="item-form" style={{marginBottom: '20px'}}>
                                                <Form.Item
                                                    name="height"
                                                    label="Chiều cao hình ảnh QR code"

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Không được để trống!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        value={productStore.heightQrcode}
                                                        // defaultValue={100} 
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="item-form">
                                                <Form.Item
                                                    name="width"
                                                    label="Chiều rộng hình ảnh QR code"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Không được để trống!",
                                                        },
                                                    ]}
                                                >
                                                    <Input  value={productStore.widthQrcode}   onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="item-form" style={{marginTop: "20px"}}>
                                                <Form.Item
                                                    name="quantity"
                                                    label="In số lượng (tối đa 100 mã QR code)"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Đây là trường bắt buộc"
                                                        },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value > 0 && value <= 100 ) {
                                                                    return Promise.resolve();
                                                                }else{
                                                                    return Promise.reject(new Error("Số lượng phải > 0 và <= 100"));
                                                                }
                                                            }
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        value={productStore.quantityQrcode} 
                                                        defaultValue={1}
                                                        onChange={(e: any)=> {
                                                            let value = Number(e.target.value); 

                                                            if(value > 100){
                                                                productStore.errCode = true
                                                            }else if(value < 1){
                                                                productStore.errCode = true
                                                            }else{
                                                                productStore.errCode = false
                                                            }

                                                            productStore.quantityQrcode = value
                                                         }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-form item-form-qr-code">
                                            <div className="image-barcode">
                                                <img
                                                    src={productStore.dataProductQrcode ? productStore.dataProductQrcode : '/assets/images/empty_img.png'}
                                                    alt="qrcode"/>

                                            </div>
                                        </div>
                                    </div>

                                    <Form.Item style={{marginTop: "20px", marginBottom: "20px"}}>
                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                Tạo mới
                                            </Button>

                                            <Button className={`btn-printPro ${productStore.errCode || !productStore.dataProductQrcode ? "deActive" : ""}`} onClick={ ()=> {handlePrint()}} >
                                                <span className="fa fa-print"></span>
                                                In
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
            <div className="print-componentPro"  ref={componentRef} >
                 <div className={`print-container nonePrint`}>
                     <div className={`qrcode-container`} >
                    {productStore.quantityQrcode > 0 && productStore.quantityQrcode <=100 &&  Array(productStore.quantityQrcode).fill(null).map((item, index: number)=>(
                        <div key={index} className="Boximage-qrcode" style={{width: `${productStore.widthQrcode}px`, height: `${productStore.heightQrcode}px`}}>
                            <img
                                src={productStore.dataProductQrcode ? productStore.dataProductQrcode : '/assets/images/empty_img.png'}
                            alt="qrcode"/>
                        </div>
                    ))}
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default observer(EditProductQrcodePage)