import React, { useEffect } from "react";
import { observer } from "mobx-react";
import LayoutSettings from "../components/layout/LayoutSettings";
import "./styles/PaymentsPage.scss";
import BoxPayment from "./components/boxPayment/BoxPayment";
import { paymentSettingsStore } from "./PaymentSettingsStore";
import Loading from "../../../shared/components/loading/Loading";
const PaymentsPage = () => {
  useEffect(() => {
    paymentSettingsStore.getListPayment();
  }, []);

  return (
    <LayoutSettings keyActive={"thanh-toan"}>
      <div className="PaymentsSettingPage">
        {/* <div className="AdminPage-header">
          <h1 className="title">Thanh toán</h1>
        </div> */}
        <div className="PaymentsSettingPage-body">

          {paymentSettingsStore.isLoading ? <Loading/> :  paymentSettingsStore.dataPayment.length > 0 &&
            paymentSettingsStore.dataPayment.map((item) => (
              <div key={item.id}>
                <BoxPayment itemPayment={item} />
                {item.name !== "POS" &&
                 <div className="dividerPayment"></div>
                }
              </div>
            ))}
        </div>
      </div>
    </LayoutSettings>
  );
};
export default observer(PaymentsPage);
