import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import {permissionsStore} from "./PermissionsStore";
import LayoutSettings from "../components/layout/LayoutSettings";
import {Form, Input} from "antd";
import './styles/index.scss'
import {productStore} from "../../products/product/ProductStore";
const PermissionsEditPage = () => {

    const { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    useEffect(() => {
        permissionsStore.fetchListPermissions().then()
        permissionsStore.fetchPermissionByRole(id,(route) => navigate(route))
    }, [id]);
    function handleCheckboxItem(value: boolean, idPermission: string) {
        if (value) {
            permissionsStore.dataDetailListPermissionRole.push(idPermission);
        } else {
            permissionsStore.dataDetailListPermissionRole = permissionsStore.dataDetailListPermissionRole.filter((item) => item !== idPermission);
        }
    }
    function handleCheckboxItemAll(value: boolean, group: string) {
        if (value) {
            const dataDetailPermission = permissionsStore.dataListPermission.filter((item:any) => item.group === group)
            dataDetailPermission[0].permissions.map((item:any) => {
                if(permissionsStore.dataDetailListPermissionRole.includes(item.id) === false){
                    permissionsStore.dataDetailListPermissionRole.push(item.id)
                }
            })
        } else {
            const dataDetailPermission = permissionsStore.dataListPermission.filter((item:any) => item.group === group)
            dataDetailPermission[0].permissions.map((item:any) => {
                permissionsStore.dataDetailListPermissionRole = permissionsStore.dataDetailListPermissionRole.filter((itemPermission) => itemPermission !== item.id);
            })
        }
    }
    function handleCheckboxAll() {
        permissionsStore.dataListRoleDetail.map((item:any) => {
            if(permissionsStore.dataDetailListPermissionRole.includes(item.id) === false){
                permissionsStore.dataDetailListPermissionRole.push(item.id)
            }
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            name: permissionsStore.dataDetailPermissionRole?.role?.name,
        });
    }, [permissionsStore.dataDetailPermissionRole,permissionsStore.dataDetailListPermissionRole, form]);
    return (
        <>
            <LayoutSettings keyActive={"quyen"}>
                <div className="AdminPage">
                    <div className="AdminPage-header">
                        <h1 className="title">Sửa quyền</h1>
                    </div>
                    <div className="AdminPage-body">
                        <div className="table-container">
                            <Form
                                form={form}
                                onFinish={(values: any) => {
                                    permissionsStore.staticDataStoreEdit.name = values.name
                                    permissionsStore.staticDataStoreEdit.permissions = permissionsStore.dataDetailListPermissionRole
                                    permissionsStore.fetchUpdateRole(id)
                                }}
                            >
                                <Form.Item
                                    className="item-form"
                                    label="Tên nhóm quyền"
                                    name="name"
                                    rules={[{required: true, message: "Vui lòng nhập"}, {
                                        max: 200,
                                        message: "Tên không được quá 200 ký tự"
                                    }]}
                                >
                                    <Input placeholder="Vui lòng nhập"/>
                                </Form.Item>
                                <div className="button-all">
                                    {permissionsStore.dataListRoleDetail.length === permissionsStore.dataDetailListPermissionRole.length ? (
                                        <span onClick={() => {
                                            permissionsStore.dataDetailListPermissionRole = []
                                        }}>Bỏ chọn</span>
                                    ) : (
                                        <span onClick={() => {
                                            handleCheckboxAll()
                                        }}>Chọn tất cả</span>
                                    )}

                                </div>
                                <div className="list-permissions">
                                    {permissionsStore.dataListPermission.map((item:any,index:number) => (
                                        <div className="item-permissions" key={index}>
                                            <label className="radio-button">
                                                <input type="checkbox" name="category_product" value="all"
                                                       // checked={allSelected}
                                                       onChange={(e) => handleCheckboxItemAll(e.target.checked,item.group)}
                                                />
                                                <span className="radio-tick"></span>
                                                <span className="title-permissions">{item.trans_group}</span>
                                            </label>
                                            <div className="list">
                                                {item.permissions.map((itemChild: any, index: number) => (
                                                    <label className="radio-button" key={index}>
                                                        <input type="checkbox"
                                                               name="category_product[]"
                                                               value={itemChild.id}
                                                                checked={permissionsStore.dataDetailListPermissionRole.includes(itemChild.id)}
                                                            onChange={(e) => handleCheckboxItem(e.target.checked, itemChild.id)}
                                                        />
                                                        <span className="radio-tick"></span>
                                                        <p>{itemChild.trans_name}</p>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className={`submitFormPermissions`}
                                    type="submit"
                                >
                                    {permissionsStore.isSave ? 'Đang lưu' : 'Lưu'}
                                </button>
                            </Form>
                        </div>
                    </div>

                </div>
            </LayoutSettings>
        </>
    )
}
export default observer(PermissionsEditPage)