import { makeAutoObservable } from "mobx";
import { tagproductservice } from "./TagProductService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";

interface StaticParamTagProduct {
    offset: number | null;
    page: number;
    per_page: number;
    search: string | undefined;
    status: string | null;
}

class TagProductStore {
    //* Kiểm tra nếu mà có dữ liệu trả về true
    isLoading = false;
    //* True sẽ mở modal thêm mới
    isModalOpen = false;
    isModalCustom = false;
    isModalDelete = false;
    isLoadingBtn = false;
    isSavingBtn = false;
    isFirstLoad = true;
    dataListProductPage: any[] = [];
    //* Khi lấy được data sẽ push vào đây
    dataListTagProduct: any[] = [];

    metadata: any = {
        total: "",
        page: 0,
        total_page: "",
        size: "",
    };

    staticParamTagProduct: StaticParamTagProduct = {
        offset: 0,
        page: 0,
        per_page: 10,
        search: "",
        status: "",
    };

    staticDataTagProduct: {
        id: number;
        name: string;
        slug: string;
        status: string | null;
        is_featured: string | null;
        deleted_at: number | null;
        created_at: string;
        updated_at: string;
    } = {
        id: 1,
        name: "",
        slug: "",
        status: "1",
        is_featured: "1",
        deleted_at: null,
        created_at: "",
        updated_at: "",
    };

    initialValues: {
        id: number;
        name: string;
        is_featured: string;
        status: string;
    } = {
        id: 1,
        name: "",
        is_featured: "",
        status: "",
    };
    //*Khi gọi đến showModal sẽ set lại
    setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen;
    };
    showModal = () => {
        this.setIsModalOpen(true);
    };
    handleOK = () => {
        this.setIsModalOpen(false);
        this.setIsModalCustomOpen(false);
        this.setIsModalDeleteOpen(false);
    };
    handleCancel = () => {
        this.setIsModalOpen(false);
        this.setIsModalCustomOpen(false);
        this.setIsModalDeleteOpen(false);
    };
    handleClose = () => {
        console.log("close");
        this.setIsModalOpen(false);
        this.setIsModalCustomOpen(false);
        this.setIsModalDeleteOpen(false);
        this.initialValues = {
            id: 1,
            name: "",
            is_featured: "",
            status: "",
        };
    };
    setIsModalCustomOpen = (isOpen: boolean) => {
        this.isModalCustom = isOpen;
    };
    showModalCustom = () => {
        this.setIsModalCustomOpen(true);
    };
    setIsModalDeleteOpen = (isOpen: boolean) => {
        this.isModalDelete = isOpen;
    };
    setIsName = (name: string) => {
        this.staticDataTagProduct.name = name;
    };
    showModalDelete = () => {
        this.setIsModalDeleteOpen(true);
    };
    constructor() {
        // this.initializeParams();
        makeAutoObservable(this);
    }
    // initializeParams() {
    //     const query = new URLSearchParams(window.location.search);
    //     this.staticParamTagProduct.search = query.get("search")
    //         ? String(query.get("search"))
    //         : "";
    //     this.staticParamTagProduct.page = query.get("page")
    //         ? Number(query.get("page")) - 1
    //         : 0;
    // }

    async fetchAllTagProductList(run?: boolean): Promise<void> {
        let { offset, page, per_page, search, status } =
            this.staticParamTagProduct;
        const paramsTagProduct = {
            offset: offset,
            page: page + 1,
            per_page: per_page,
            search: search,
            status: status,
        };

        if (this.dataListTagProduct.length < 1 || run) {
            this.isLoading = true;
        }

        const result = await tagproductservice.fetchTagProduct(
            paramsTagProduct
        );

        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            if (result.body.data.length !== this.dataListTagProduct) {
                this.dataListTagProduct = result.body.data;
                this.metadata = result.body.metadata;
            }
        }
    }

    async fetchCreateTagProduct(): Promise<void> {
        let { name, is_featured, status } = this.staticDataTagProduct;
        const paramsCreate = {
            name: name,
            status: status,
            is_featured: is_featured,
        };
        this.isLoadingBtn = true;

        const result = await tagproductservice.fetchCreateNewTagProduct(
            paramsCreate
        );

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm mới thành công", "");
            this.handleCancel();
            this.staticDataTagProduct.name = "";
            this.staticDataTagProduct.is_featured = "1";
            this.staticDataTagProduct.status = "1";
            this.dataListTagProduct.unshift(result.body.data);
            if (this.dataListTagProduct.length > 10) {
                this.dataListTagProduct.pop();
            }
        } else if (result.status === HttpStatusCode.BadRequest) {
            toastUtils.warning("Không được để trống tên thẻ", "");
        } else {
            toastUtils.error("Lỗi thêm mới", "");
        }
        this.isLoadingBtn = false;
    }

    async putTagProduct(): Promise<void> {
        this.isLoadingBtn = true;
        let { id, name, is_featured, status } = this.initialValues;
        const body = {
            id: id,
            name: name,
            is_featured: is_featured,
            status: status,
        };
        const result = await tagproductservice.fetchUpdateTagProduct(body);
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            // this.staticDataTagProduct.name = "";
            const updateDataTagProduct = this.dataListTagProduct.map(
                (item: any) =>
                    item.id === this.initialValues.id
                        ? {
                              ...item,
                              name: this.initialValues.name,
                              is_featured: this.initialValues.is_featured,
                              status: this.initialValues.status,
                          }
                        : item
            );
            this.dataListTagProduct = updateDataTagProduct;
            this.handleCancel();
            toastUtils.success("Sửa thành công", "");
        } else if (result.status === HttpStatusCode.BadRequest) {
            toastUtils.warning("Không được để trống tên thẻ", "");
        } else {
            toastUtils.error("Lỗi sửa thẻ", "");
        }
    }

    async fetchDeleteTagProduct() {
        let { id } = this.staticDataTagProduct;
        const result = await tagproductservice.fetchDeleteTagProduct(id);
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công", "");
            this.dataListTagProduct = this.dataListTagProduct.filter(
                (item: any) => item.id !== this.staticDataTagProduct.id
            );

            if (this.dataListTagProduct.length === 0) {
                this.staticParamTagProduct.page = 0;
                this.fetchAllTagProductList();
            }
        }
    }
}

export const tagProductStore = new TagProductStore();
