import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
class ShowDelete {
    public showDeleteConfirm(title: string, content: string, onOk?: () => void): void {
        const { confirm } = Modal;
        confirm({
            title: title, // Sử dụng tham số title
            icon: <ExclamationCircleOutlined />, // Sử dụng biểu tượng từ Ant Design
            content: content, // Sử dụng tham số content
            okText: 'Xác nhận',
            okType: 'danger',
            cancelText: 'Huỷ',
            async  onOk() {
                if (onOk) {
                  await onOk();  // Thực thi hàm onOk nếu được truyền vào
                }
            }
        });
    }
}

export const showDelete = new ShowDelete();
