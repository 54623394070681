import {getRequest} from "../../../../common/helpers/RequestHelper";
class CustomerDebtDetailService {
    api = "api/v1/pos/ecommerce/order/order_debt"
    public fetchDetailCustomerDebt(params:any,id:any): Promise<any>{
        const queryParameters = [
            params.pos_id ? `pos_id=${params.pos_id}` : '',
            params.end_date ? `end_date=${params.end_date}` : '',
            params.page_number ? `page_number=${params.page_number}` : '',
            params.per_page ? `per_page=${params.per_page}` : '',
            params.start_date ? `start_date=${params.start_date}` : '',
            params.transaction_type ? `transaction_type=${params.transaction_type}` : '',
        ].filter(Boolean).join('&');
        return getRequest(`${this.api}/${id}?${queryParameters}`);
    }
}

export const customerDebtDetailService = new CustomerDebtDetailService()