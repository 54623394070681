import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
} from "../../../common/helpers/RequestHelper";
class AttributeService {
    apiSubdirectory = "api/v1/attributes";
    public fetchAttributes(paramAttribute: any) {
        const queryParameters = [
            paramAttribute.search ? `search=${paramAttribute.search}` : "",
            paramAttribute.offset ? `offset=${paramAttribute.offset}` : "",
            paramAttribute.page ? `page=${paramAttribute.page}` : "",
            paramAttribute.per_page
                ? `per_page=${paramAttribute.per_page}`
                : "",
            paramAttribute.parent_id
                ? `parent_id=${paramAttribute.parent_id}`
                : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
        // return  getRequest(`${this.apiSubdirectory}?limit=${paramAttribute.limit}&offset=${paramAttribute.offset}&order_by=${paramAttribute.order_by}&page_number=${paramAttribute.page_number}&page_size=${paramAttribute.page_size}&search=${paramAttribute.search}`);
    }
    public fetchAttributesAll() {
        return getRequest(`${this.apiSubdirectory}?per_page=10`);
    }
    public fetchCreateNewAttribute(paramsCreate: any): Promise<any> {
        // console.log(paramsCreate)
        return postRequest(`${this.apiSubdirectory}`, paramsCreate);
    }
    public fetchSpecificAttribute(paramAttribute: any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}/${paramAttribute.id}`);
    }
    public fetchModifyAttribute(paramsModify: any): Promise<any> {
        return putRequest(
            `${this.apiSubdirectory}/${paramsModify.id}`,
            paramsModify
        );
    }
    public fetchDeteleAttribute(id: number) {
        return deleteRequest(`${this.apiSubdirectory}/${id}`);
    }
}
export const attributeService = new AttributeService();
