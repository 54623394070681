import {makeAutoObservable} from "mobx";
import {number} from "yup";
import {paymentService} from "../../paymentPos/PaymentService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../../common/utils/Toastutils";
import {productInPosCartStore} from "../../productInPos/ProductInPosCartStore";
import StorageService from "../../../../common/service/StorageService";
import {paymentDebtService} from "./PaymentDebtService";


class PaymentDebtStore{
    isLoading: boolean = false
    isSave: boolean = false
    posid: string | undefined
    dataCustomer: any
    isModalNoteOpen: boolean = false
    isModalImageQrOpen: boolean = false
    priceTotal: number = 0 // Tổng tiền thanh toán
    priceTotalAfterPayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    pricePayment: number = 0 // Tổng tiền còn lại sau khi thanh toán
    imageQr: string = '' // Tổng tiền còn lại sau khi thanh toán
    idPayTypeActive:number = 0;
    listPayType: any = []
    notePos: string | null | undefined;
    imageBank: string | null | undefined ;
    constructor() {
        makeAutoObservable(this)
    }
    /**
     * @description Tổng tiền thanh toán
     */
    handlePricePayment = () => {
        this.pricePayment = 0
        this.listPayType.forEach((item:any) =>{
            this.pricePayment += item.price
        })
        if(this.priceTotal - this.pricePayment > 0){
            this.priceTotalAfterPayment = this.priceTotal - this.pricePayment;
        }else{
            this.priceTotalAfterPayment = 0;
        }
    }
    handleNumber = (idPayType:number,numberPay:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                if(item.close === 1){
                    item.price = numberPay;
                    item.close = 2;
                }else{
                    item.price = parseInt(String(item.price) + String(numberPay));
                }
            }
            return item;
        })
        this.handlePricePayment();
    }
    handleRemove = (idPayType:number) => {
        this.listPayType = this.listPayType.map((item:any) => {
            if(item.id === idPayType){
                item.price = 0;
                item.close = 2;
            }
            return item;
        })
        this.handlePricePayment();
    }
    async refreshAmountDebtOfCustomer(){
        const params = {
            pos_id: this.posid
        }
        const result = await paymentDebtService.fetchRefreshAmountDebtOfCustomer(this.dataCustomer.id,params);
        if (result.status === HttpStatusCode.Ok) {
            if(result.body.amount_debt !== this.dataCustomer.amount_debt){
                this.priceTotal = result.body.amount_debt;
                this.priceTotalAfterPayment = result.body.amount_debt;
                this.dataCustomer.amount_debt = result.body.amount_debt;
                StorageService.setObjectStore(`customerDebt${this.posid}`,this.dataCustomer);
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }
    async generateQr(){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)
            const params = {
                description: 'Thanh toán tiền',
                money: bank_amount
            }
            const result = await paymentService.fetchGenerateQr(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                this.imageQr = result.body?.qr ? result.body.qr : ''
                this.showModalImageQr()
            }
            else{
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
    }
    async fetchSaveOrderPosEcommerce(navigate: (route: string) => void){
        if(this.isSave === false){
            this.isSave = true
            var bank_amount = 0;
            var cash_amount = 0;
            this.listPayType.filter((item:any) => item.type === 1).map((item:any) => cash_amount += item.price)
            this.listPayType.filter((item:any) => item.type === 2).map((item:any) => bank_amount += item.price)

            const params = {
                note_pay: this.notePos,
                pos_id: this.posid,
                transfer_image: this.imageBank,
                price_cash: cash_amount,
                price_bank: bank_amount,
                customer_id: this.dataCustomer.id,
            }
            const result = await paymentDebtService.fetchPayDebtsToCustomer(params);
            this.isSave = false
            if (result.status === HttpStatusCode.Ok) {
                StorageService.removeLocalStore(`customerDebt${this.posid}`)
                StorageService.removeLocalStore(`noteDebt${this.posid}`)
                this.listPayType = []
                this.notePos = ''
                navigate(`/pos/thanh-toan-cong-no-thanh-cong/${result.body.id}`);
            }
            else{
                toastUtils.error('Đã có lỗi xảy ra!', '');
            }
        }
        // this.openModal = false;
    }
    /**
     *
     * @param type // 1: là tiền mặt. 2: là chuyển khoản
     * @description Thêm kiểu thanh toán
     */
    handlePushPayType = (type:number) => { // 1: là tiền mặt. 2: là chuyển khoản
        let idPayType = Date.now();
        const data = {
            id : idPayType,
            price: this.priceTotalAfterPayment,
            type: type,
            close: 1, // 1 là xóa hết 1 lần, 2 là xóa lần lượt
        }
        this.idPayTypeActive = idPayType;
        this.listPayType.unshift(data)
        this.handlePricePayment()
    };
    /**
     *
     * @param id
     * @description Xóa kiểu thanh toán
     */
    handleRemovePayType = (id:number) => {
        this.listPayType = this.listPayType.filter((item:any) => item.id != id)
        this.handlePricePayment();
    };
    showModalNote = () => {
        this.isModalNoteOpen = true;
    };
    handleCancelNote = () => {
        this.isModalNoteOpen = false;
    };

    showModalImageQr = () => {
        this.isModalImageQrOpen = true;
    };
    handleCancelImageQr = () => {
        this.isModalImageQrOpen = false;
    };

    handleSetFileImageBank = (pathFile: string) => {
        this.imageBank = pathFile;
    };
    handleRemoveImageBank = () => {
        this.imageBank = '';
    };
}

export const paymentDebtStore = new PaymentDebtStore()