import React, { useEffect } from "react";
import "./BoxFilterDiningTables.scss";
import { observer } from "mobx-react";
import { diningTablesStore } from "../../DiningTableStore";
import { useLocation, useNavigate } from "react-router-dom";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";
import useDebounce from "../../../../../../common/customHook/useDebounce";
import { handleKeyPressSpecialCharacters } from "../../../../../../common/utils/Utils";

const BoxFilterDiningTables = () => {
  const navigate = useNavigate();
  const debouncedSearchTable= useDebounce(diningTablesStore.searchTemp, 300);

  const updateUrlParams = () => {
    const searchParams = new URLSearchParams();

    if (debouncedSearchTable) {
      searchParams.set(
        "search",
        debouncedSearchTable
      );
    }
    if (diningTablesStore.dataFilterDiningTables.store_id) {
      searchParams.set(
        "store_id",
        diningTablesStore.dataFilterDiningTables.store_id
      );
    }
    if (diningTablesStore.dataFilterDiningTables.floor_room_id) {
      searchParams.set(
        "floor_room_id",
        diningTablesStore.dataFilterDiningTables.floor_room_id
      );
    }


    diningTablesStore.staticParamDiningTable.page = 0;

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  // const handleSearch = () => {
  //   updateUrlParams(); 
  // };

  const handleSelectChange = () => {
    updateUrlParams(); 
  };

  useEffect(()=>{
    diningTablesStore.fetchAllfloorRoom("filter");
  },[diningTablesStore.storeIdFilter])

  const handleRemoveFilters = () => {
    diningTablesStore.dataFilterDiningTables.search = "";
    diningTablesStore.dataFilterDiningTables.store_id = null;
    diningTablesStore.dataFilterDiningTables.floor_room_id = null;
    diningTablesStore.storeIdFilter = null
    navigate({ search: "" }, { replace: true });

    diningTablesStore.fetchAllDiningTablesList();
    // diningTablesStore.fetchAllfloorRoom("filter")
  };

  const dataFloorRoomFilter =
  diningTablesStore.dataFloorRoomFilter?.length > 0
    ? diningTablesStore.dataFloorRoomFilter?.map((item: any) => ({
        id: item.id,
        name: `${item.parent ? `${item?.parent?.name} / ` : ""}${item?.name}`,
      }))
    : [];

const dataLocation =
  diningTablesStore.dataLocation?.length > 0
    ? diningTablesStore.dataLocation?.map((item: any) => ({
        id: item.id,
        name: item.name,
      }))
    : [];

    useEffect(() => {
      // diningTablesStore.allowSearch: khi load lại trang không bay vào đây tránh mất search trên url
      if (diningTablesStore.allowSearch && debouncedSearchTable !== diningTablesStore.dataFilterDiningTables.search) {
        diningTablesStore.dataFilterDiningTables.search = debouncedSearchTable;
        updateUrlParams();
      }
    }, [debouncedSearchTable]);

    

  return (
    <div className="BoxFilterDiningTables">
      {/* <form
        className="filter-search"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <input
          type="text"
          name="keyword"
          value={diningTablesStore.dataFilterDiningTables.search}
          id="keyword"
          onChange={(e: any) => {
            diningTablesStore.dataFilterDiningTables.search =
              e.currentTarget.value;
          }}
          placeholder="Nhập tên bàn"
        />
        <button type="submit">
          <img src="/assets/icon/search.svg" alt="search" />
        </button>
      </form> */}

      <div className="search">
        <img src="/assets/icon/searchModel.svg" alt="searchModel" />
        <input
          type="text"
            placeholder="Nhập tên bàn"
            onKeyPress={handleKeyPressSpecialCharacters}
            value={diningTablesStore.searchTemp}
          onChange={(e: any) => {
            diningTablesStore.searchTemp = e.target.value
            diningTablesStore.allowSearch = true
          }}
        />
      </div>

      <SelectCustom
        list={dataLocation && dataLocation}
        placeholder="Chọn địa điểm"
        showSearch
        allowClear
        onChange={(value: any) => {
          diningTablesStore.dataFilterDiningTables.store_id =
          Number(value);
          diningTablesStore.dataFilterDiningTables.floor_room_id = null; 
          diningTablesStore.storeIdFilter = Number(value);
          handleSelectChange();
        }}
        selected={() => {
          return diningTablesStore.dataLocation?.find((item)=> item.id === diningTablesStore.dataFilterDiningTables.store_id)?.id || null;
        }}
        getLabel={(item: any) => item.name}
      ></SelectCustom>

      
      <SelectCustom
          list={dataFloorRoomFilter && dataFloorRoomFilter}
          placeholder="Chọn tầng phòng"
          showSearch
          allowClear
          onChange={(value: any) => {
            diningTablesStore.dataFilterDiningTables.floor_room_id =
            Number(value);
            handleSelectChange(); 
          }}
          selected={() => {
            return diningTablesStore.dataFloorRoomFilter?.find((item)=> item.id ===  diningTablesStore.dataFilterDiningTables.floor_room_id)?.id || null;

          }}
          getLabel={(item: any) => item.name}
      ></SelectCustom>

          <div
              className="addTablet"
              onClick={() => {
                diningTablesStore.openModal = true;
                diningTablesStore.typeModal = "add";
                diningTablesStore.storeId = null;
              }}
            >
              <span>
                Thêm mới
                <img src="/assets/icon/plus.svg" alt="add" />
              </span>
            </div>

      {(diningTablesStore.dataFilterDiningTables.search ||
        diningTablesStore.dataFilterDiningTables.store_id ||
        diningTablesStore.dataFilterDiningTables.floor_room_id) && (
        <div className="remove-filter" onClick={handleRemoveFilters}>
          <span>Xóa Lọc</span>
          <img src="/assets/icon/icon_close.svg" width={14} alt="delete" />
        </div>
      )}
    </div>
  );
};

export default observer(BoxFilterDiningTables);
