import {getRequest, putRequest} from '../../common/helpers/RequestHelper';
import React from "react";
class DashboardService {
    apiSubdirectory = 'api/v1/dashboard/' ;
    public fetchDataStore(): Promise<any> {
        return getRequest(`api/v1/storeList`);
    }
    public fetchDataRevenue(params: any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}revenue?startDate=${params.startDate}&endDate=${params.endDate}&store=${params.store}&type=${params.type}`);
    }
    public fetchDataCustomer(params: any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}customer?startDate=${params.startDate}&endDate=${params.endDate}&store=${params.store}&type=${params.type}`);
    }
    public fetchDataTopSellers(params: any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}topSellers?startDate=${params.startDate}&endDate=${params.endDate}&store=${params.store}&order=${params.type}`);
    }
    public fetchDataNotifications(params: any): Promise<any> {
        return getRequest(`api/v1/notifications?page=${params.page}&size=${params.size}`);
    }
    public fetchUpdateStatusNotification(id: number|undefined): Promise<any> {
        return putRequest(`api/v1/notifications/${id}`,'');
    }
    public fetchUpdateStatusAllNotification(): Promise<any> {
        return putRequest(`api/v1/notifications/`,'');
    }
}

export const dashboardService = new DashboardService()