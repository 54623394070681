import { getRequest } from "../../../common/helpers/RequestHelper";

class RefundOrderService { 
    public fetchListRefundOrder(params: any): Promise<any> {
        const queryParams = [
            params.page ? `page_number=${params.page}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.keyword ? `keyword=${params.keyword}` : "",
            params.pos_id ? `pos_id=${params.pos_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`api/v1/pos/ecommerce/order/order_refund?${queryParams}`);
    }

    public fetchDetailsRefundOrder(id: string | number): Promise<any> {
        return getRequest(`api/v1/pos/ecommerce/order/order_detail/${id}`);
    }

}

export const refundOrderService = new RefundOrderService();