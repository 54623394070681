import { Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
interface SelectStatusProps {
    onChange: (value: any) => void;
    selected: () => any;
    textOn?: string;
    textOff?: string;
    placeholder?: string;
}

const SelectStatusNumber: React.FC<SelectStatusProps> = ({
    onChange,
    selected,
    textOn,
    textOff,
    placeholder,
}) => {
    return (
        <Select
            showSearch
            placeholder={placeholder ? placeholder : 'Trạng thái'}
            onChange={onChange}
            value={selected()}
            filterOption={(input, option) =>
                (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            options={[
                { value: 1, label: textOn ? textOn : "Hoạt động" },
                { value: 0, label: textOff ? textOff : "Vô hiệu hóa" },
            ]}
        />
    );
};
export default observer(SelectStatusNumber);
