import React, { ReactNode } from 'react';
import "../styles/LayoutSessionDetails.scss"
interface LayoutSessionDetailsProps {
  children: ReactNode;
  name?: string;
  onClickBack?: () => void;
}

const LayoutSessionDetails: React.FC<LayoutSessionDetailsProps> = ({ children, name = "Name...", onClickBack = () => {}}) => {
  return (
    <div className='LayoutSessionDetails'>
     <h1 className="title">POS</h1>
     <div className='LayoutSessionDetails-head'>
        <div className='LayoutSessionDetails-back' onClick={onClickBack}>
          <img src="/assets/icon/icon-back.svg" alt="icon-back" />
            Quay lại
        </div>
        <h1 className='LayoutSessionDetails-name'>{name}</h1>
        <div></div>
     </div>
      {children}
    </div>
  );
};

export default LayoutSessionDetails;
