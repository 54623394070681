import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import {productStore} from "../../ProductStore";
import {createSearchParams, useNavigate} from "react-router-dom";
import StorageService from "../../../../../common/service/StorageService";
const FilterGroupProduct = () => {
    const navigate = useNavigate();
    const [openFilterBrand, setopenFilterBrandProduct] = useState(true);
    const [selectedBrand, setSelected] = useState<string[]>(StorageService.getArrayFromLS("arrBrandPro") || []);

    useEffect(()=>{
        productStore.staticDataListProduct.brand_id = StorageService.getArrayFromLS("arrBrandPro") || []
    },[])

    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selectedBrand, name]);
            productStore.staticDataListProduct.brand_id = [...selectedBrand, name];
            StorageService.saveArayLS("arrBrandPro", [...selectedBrand, name])
        } else {
            setSelected(selectedBrand.filter((item) => item !== name));
            productStore.staticDataListProduct.brand_id = selectedBrand.filter((item) => item !== name);
            StorageService.saveArayLS("arrBrandPro", selectedBrand.filter((item) => item !== name))
        }
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct(true)
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listBrand.map((item: any) => item.id));
            const brandIdArray:any[] = []
            productStore.listBrand.map((item: any) => {
                brandIdArray.push(item.id)
            });
            productStore.staticDataListProduct.brand_id = brandIdArray;
            StorageService.saveArayLS("arrBrandPro", brandIdArray)
        } else {
            setSelected([]);
            productStore.staticDataListProduct.brand_id = [];
            StorageService.saveArayLS("arrBrandPro", [])
        }
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct(true)
    }
    const allSelected = selectedBrand.length === productStore.listBrand.length;
    return (
        <div className="filter-category-product filter-goods-manager">
            <Button onClick={() => setopenFilterBrandProduct(!openFilterBrand)}
                    aria-controls="example-collapse-text" aria-expanded={openFilterBrand}>
                Thương hiệu
            </Button>
            <Collapse in={openFilterBrand}>
                <div className="listBrandProduct">
                    <label className="radio-button">
                        <input type="checkbox" name="category_product" value="all"  checked={allSelected}
                               onChange={(e) => selectAll(e.target.checked)}/>
                        <span className="radio-tick"></span>
                        Tất cả
                    </label>
                    {productStore.listBrand.map((item:any,index:number) => (
                        <label className="radio-button">
                            <input type="checkbox"
                                   name="category_product[]"
                                   value={item.id}
                                   checked={selectedBrand.includes(item.id)}
                                   onChange={(e) => handleSelect(e.target.checked, item.id)}
                            />
                            <span className="radio-tick"></span>
                            <p>{item.name}</p>
                        </label>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
export default observer(FilterGroupProduct);