import axios from "axios";
import { getRequest, putRequest } from "../../../common/helpers/RequestHelper";

class PaymentSettingsService {
    public fetchListPayment(): Promise<any> {
        return getRequest(`api/v1/payment`);
    }

    public fetchListBank(): Promise<any> {
        return axios.get(`https://api.vietqr.io/v2/banks`);
    }

    public updatePayment(id: string, data: any): Promise<any> {
        return putRequest(`api/v1/payment/${id}`, data);
    }
}

export const paymentSettingsService = new PaymentSettingsService()