import React, { ReactNode } from "react";
import "../styles/BoxFilterSessionDetails.scss";
interface LayoutSessionDetailsProps {
    children?: ReactNode;
    handleRemoveFilter ?: () => void
  }
  

const BoxFilterSessionDetails: React.FC<LayoutSessionDetailsProps> = ({children, handleRemoveFilter=()=>{}}) => {
  return (
    <div className="BoxFilterSessionDetails">
      <div className="left">
        {children}
      </div>
      <div className="right">
        <div className="remove-filter" onClick={handleRemoveFilter}>
          <span>Xóa Lọc</span>
          <img src="/assets/icon/icon_close.svg" width={14} alt="delete" />
        </div>
      </div>
    </div>
  );
};

export default BoxFilterSessionDetails;
