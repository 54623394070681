import React, { ReactNode } from 'react';
import Sidebar from '../sidebar/Sidebar';
import "../../styles/LayoutSettings.scss"
interface LayoutSettingsProps {
  children: ReactNode;
  keyActive?: string | null;
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({ children, keyActive="quan-tri-vien" }) => {
  return (
    <div className='LayoutSettings'>
      <div className='box-sidebar'>
        <Sidebar keyActive={keyActive as string} />
       </div>
       <div className='box-body'>
        {children}
       </div>
    </div>
  );
};

export default LayoutSettings;
