import {makeAutoObservable} from "mobx";
import {customerDebtService} from "../listCustomerDebt/CustomerDebtService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../../common/utils/Toastutils";
import {number} from "yup";
import {customerDebtDetailService} from "./CustomerDebtDetailService";
import dayjs from "dayjs";
import {detailMachinePosStore} from "../../detailMachinePos/DetailMachinePosStore";
interface StaticParam {
    page_number: number | 0;
    per_page: number;
    keyword: string | undefined;
    start_date: any;
    end_date: any;
    transaction_type: string;
}

class CustomerDebtDetalStore{
    isLoading: boolean = false
    postId: any
    customerId: any
    listCustomerDebtDetail: any[] = []
    staticParam: StaticParam = {
        page_number: 0,
        per_page: 20,
        keyword: "",
        start_date: "",
        end_date: "",
        transaction_type: "",
    };
    metadata: any = {
        size: "",
        page: 0,
        total_page: "",
        total: "",
    };
    constructor() {
        this.initializeDates()
        makeAutoObservable(this)

    }
    initializeDates() {
        const start = dayjs().subtract(92, "day").format("DD-MM-YYYY");
        const end = dayjs().endOf("day").format("DD-MM-YYYY");
        this.staticParam.start_date = start
        this.staticParam.end_date = end
    }
    async fetchDetailCustomerDebt(run?:boolean){
        if(this.listCustomerDebtDetail.length < 1 || run){
            this.isLoading = true
        }
        const params = {
            pos_id: this.postId,
            end_date: this.staticParam.end_date,
            start_date: this.staticParam.start_date,
            page_number: this.staticParam.page_number + 1,
            per_page: this.staticParam.per_page,
            transaction_type: this.staticParam.transaction_type,
        }
        const result = await customerDebtDetailService.fetchDetailCustomerDebt(params,this.customerId);
        this.isLoading = false
        if (result.status === HttpStatusCode.Ok) {
            this.listCustomerDebtDetail = result.body.data
            this.metadata = result.body.metadata
        } else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }
}

export const customerDebtDetalStore = new CustomerDebtDetalStore()