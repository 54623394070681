import React from 'react';
import "./BoxPayment.scss"
import FormMomo from '../form/formMomo/FormMomo';
import FormVnPay from '../form/formVNPay/FormVnPay';
import FormPos from '../form/formPOS/FormPos';
const BoxPayment = ({itemPayment}: {itemPayment : any}) => {
  
    return (
        <div className='BoxPayment'>
           <div className='BoxPayment-infor'>
             <img src={itemPayment.logo} alt="img" />
             <div className='BoxPayment-infor__content'>
                <h1>{itemPayment.name}</h1>
                <p>
                  {itemPayment.instruction}
                </p>
             </div>
           </div>
           <div className='BoxPayment-form'>
            {
              itemPayment.name === "Momo" ? <FormMomo itemForm={itemPayment} /> :  itemPayment.name === "VNpay" ? <FormVnPay itemForm={itemPayment}/> : itemPayment.name === "POS" ? <FormPos itemForm={itemPayment}/> : ""
            }
           </div>
        </div>
    );
};

export default BoxPayment;