import {deleteRequest, getRequest, postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class PermissionsService {
    apiSubdirectory = 'api/v1/permission/role' ;
    apiSubRole = 'api/v1/permission/permission' ;
    public fetchListRole(params:any): Promise<any> {
        const queryParam = [
            params.per_page ? `per_page=${params.per_page}` : '',
            params.page_number ? `page_number=${params.page_number}` : '',
            params.search ? `search=${params.search}` : '',
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiSubdirectory}?${queryParam}`);
    }
    public fetchPermissionByRole(id:string): Promise<any> {
        return getRequest(`${this.apiSubRole}/${id}`);
    }
    public fetchListPermissions(): Promise<any> {
        return getRequest(`${this.apiSubRole}`);
    }
    public fetchStoreRole(params:any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`,params);
    }
    public fetchUpdateRole(params:any,id:string): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${id}`,params);
    }
    public fetchDeleteRole(idRole:number): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${idRole}`);
    }
}
export const permissionsService = new PermissionsService()