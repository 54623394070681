import React from "react";
import "./styles/CrmIntegration.scss";
import LayoutSettings from "../components/layout/LayoutSettings";
import { Checkbox, CheckboxProps, Form, Input } from "antd";
const CrmIntegrationPage = () => {
  const [form] = Form.useForm();

  const onChange: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <LayoutSettings keyActive={"tich-hop-crm"}>
      <div className="CrmIntegration">
        <div className="CrmIntegration-body">
          <div className="Box">
            <div className="Box-infor">
              <img
                src={
                  "/assets/images/Logo_CRM.svg"
                }
                alt="img"
              />
              <div className="Box-infor__content">
                <h1>Ommani CRM</h1>
                <p>
                  Ommani CRM mang đến giải pháp phần mềm quản lý và chăm sóc
                  khách hàng toàn diện, chuyên nghiệp, giúp tối ưu việc chăm sóc
                  khách hàng, hỗ trợ quản lý tương tác giữa các phòng ban
                  Marketing - Sales - CSKH trên một nền tảng.
                </p>
              </div>
            </div>
            <div className="Box-form">
              <Form
                form={form}
                onFinish={async (values: any) => {
                  console.log("🚀 ~ onFinish={ ~ values:", values)
                  // paymentSettingsStore.idPayment = itemForm.id;
                  // paymentSettingsStore.dataFormVnpay.vnp_TmnCode = values.vnp_TmnCode;
                  // paymentSettingsStore.dataFormVnpay.vnp_HashSecret =
                  //   values.vnp_HashSecret;
                  // await paymentSettingsStore.updatePayment("VNpay");
                }}
                className="FormPaymentSettings"
              >
                <Form.Item<any>
                  className="item-form"
                  label="API KEY"
                  name="key"
                  rules={[
                    { required: true, message: "Vui lòng nhập vào trường này" },
                  ]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </Form.Item>

                <button
                  className={`updateForm`}
                  // disabled={paymentSettingsStore.isLoadingVnpay}
                  type="submit"
                >
                  {/* {paymentSettingsStore.isLoadingVnpay ? "Vui lòng chờ..." : " Cập nhật"} */}
                  Cập Nhật
                </button>
              </Form>
            </div>
          </div>
          
          <div className="dividerCrm"></div>

          <div className="Box">
            <div className="Box-infor" style={{width: "485px"}}>
              <div className="Box-infor__content">
                <h1>Đồng bộ dữ liệu</h1>
              </div>
            </div>
            <div className="Box-form Box-form--crm ">
                 <div>
                    <p>Chọn dữ liệu muốn đồng bộ</p>
                    <div className="d-flex flex-column" style={{rowGap: "12px"}}>
                        <Checkbox onChange={onChange}>Dữ liệu khách hàng từ form liên hệ</Checkbox>
                        <Checkbox onChange={onChange}>Dữ liệu khách hàng</Checkbox>
                    </div>

                    <button
                  className={`updateForm`}
                  // disabled={paymentSettingsStore.isLoadingVnpay}
                  type="submit"
                >
                  {/* {paymentSettingsStore.isLoadingVnpay ? "Vui lòng chờ..." : " Cập nhật"} */}
                  Xác nhận
                </button>
                 </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutSettings>
  );
};

export default CrmIntegrationPage;
