import React, { useEffect } from "react";
import "../../../styles/FormPaymentSettings.scss";
import { Form, Input, Switch } from "antd";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";
import { paymentSettingsStore } from "../../../PaymentSettingsStore";
import { observer } from "mobx-react";
const FormPos = ({ itemForm }: { itemForm: any }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    paymentSettingsStore.getListBank();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      bank_number: itemForm.data.bank_number,
      name: itemForm.data.name,
      bank_code: itemForm.data.bank_code,
      bankId: itemForm.data.bankId,
    });
    paymentSettingsStore.dataFormPOS.status = itemForm.status;
  }, [form, itemForm]);

  const dataBank =
    paymentSettingsStore.dataListBank.length > 0
      ? paymentSettingsStore.dataListBank.map((item: any) => ({
          id: Number(item.bin),
          name: `${item.bin} - ${item.shortName} - ${item.name}`,
        }))
      : [];
      
  return (
    <Form
      form={form}
      onFinish={async (values: any) => {
        paymentSettingsStore.idPayment = itemForm.id;
        paymentSettingsStore.dataFormPOS.bankId = values.bankId;
        paymentSettingsStore.dataFormPOS.bank_number = values.bank_number;
        paymentSettingsStore.dataFormPOS.name = values.name;
        paymentSettingsStore.dataFormPOS.bank_code = values.bank_code;
        await paymentSettingsStore.updatePayment("POS");
      }}
      className="FormPaymentSettings"
    >
      <Form.Item<any>
        className="item-form"
        label="Mã ngân hàng"
        name="bankId"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn mã ngân hàng",
          },
        ]}
      >
        <SelectCustom
          list={dataBank && dataBank}
          placeholder="Chọn mã ngân hàng"
          showSearch
          onChange={(value: any) => {
            paymentSettingsStore.dataFormPOS.bankId = value;
          }}
          selected={() => {
            return (
              paymentSettingsStore.dataFormPOS.bankId ||
              Number(itemForm.data.bankId) ||
              null
            );
          }}
          getLabel={(item: any) => item.name}
        ></SelectCustom>
      </Form.Item>

      <Form.Item<any>
        className="item-form"
        label="Bank Number"
        name="bank_number"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input type="number" placeholder="Vui lòng nhập" />
      </Form.Item>

      <Form.Item<any>
        className="item-form"
        label="Name"
        name="name"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <Form.Item<any>
        className="item-form"
        label="Bank Code"
        name="bank_code"
        rules={[{ required: true, message: "Vui lòng nhập vào trường này" }]}
      >
        <Input placeholder="Vui lòng nhập" />
      </Form.Item>

      <div className="FormPaymentSettings-switch">
        <h1>Trạng thái</h1>
        <Switch
          size="default"
          defaultChecked={itemForm.status === 1 ? true : false}
          //   disabled={item.super_admin === 1}
          onChange={async (e) => {
            paymentSettingsStore.dataFormPOS.status = e ? 1 : 0;
          }}
        />
      </div>

      <button
        className={`updateForm`}
        disabled={paymentSettingsStore.isLoadingPos}
        type="submit"
      >
        {paymentSettingsStore.isLoadingPos ? "Vui lòng chờ..." : " Cập nhật"}
      </button>
    </Form>
  );
};

export default observer(FormPos);
