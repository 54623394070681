import { makeAutoObservable, toJS } from "mobx";
import { diningTablesService } from "./DiningTablesService";
import { HttpStatusCode } from "axios";
import {toastUtils} from "../../../../common/utils/Toastutils";

interface StaticParamDiningTable {
  offset: number | null;
  page: number;
  per_page: number;
}

interface IdataDiningTables {
  name: string;
  quantity_people: number | null;
  ordering: number | null;
  store_id: number | null;
  floor_room_id: number | null;
  nameStore?: string;
  nameFloorRoom?: string;
}

class DiningTablesStore {
  openModal: boolean = false;
  typeModal: string = "add";
  holdModal: boolean = false;
  idDiningTable: number | null = null;
  storeId: number | null = null;
  storeIdFilter: number | null = null;
  searchTemp: string = "";

  isLoading: boolean = false;
  isLoadingBtn: boolean = false;
  isLoadingRoom: boolean = false;
  allowSearch: boolean = false;

  dataDiningTables: any[] = [];
  dataFloorRoom: any[] = [];
  dataFloorRoomFilter: any[] = [];
  dataLocation: any[] = [];

  formDataDiningTables: IdataDiningTables = {
    name: "",
    quantity_people: null,
    ordering: 1,
    store_id: null,
    floor_room_id: null,
    nameStore: "",
    nameFloorRoom: "",
  };

  dataFilterDiningTables: any = {
    search: "",
    store_id: null,
    floor_room_id: null,
  };

  staticParamDiningTable: StaticParamDiningTable = {
    offset: 0,
    page: 1,
    per_page: 20,
  };

  metadata: any = {
    total: null,
    page: 1,
    total_page: null,
    size: null,
  };
  clearFormDiningTables() {
    this.formDataDiningTables = {
      name: "",
      quantity_people: null,
      ordering: 1,
      store_id: null,
      floor_room_id: null,
    };
  }

  constructor() {
    // this.initializeParams();
    makeAutoObservable(this);
  }

  // initializeParams() {
  //   const query = new URLSearchParams(window.location.search);
  //   this.dataFilterDiningTables.search = query.get("search")
  //     ? String(query.get("search"))
  //     : "";
  //   this.staticParamDiningTable.page = query.get("page")
  //     ? Number(query.get("page")) - 1
  //     : 0;
  //   this.dataFilterDiningTables.store_id = query.get("store_id")
  //     ? Number(query.get("store_id"))
  //     : null;
  //   this.dataFilterDiningTables.floor_room_id = query.get("floor_room_id")
  //     ? Number(query.get("floor_room_id"))
  //     : null;
  //   this.storeIdFilter = query.get("store_id")
  //     ? Number(query.get("store_id"))
  //     : null;
  // }

  async fetchAllDiningTablesList(run?: boolean) {
    let { offset, page, per_page } = this.staticParamDiningTable;
    let { search, store_id, floor_room_id } = this.dataFilterDiningTables;
    const paramsTagProduct = {
      offset: offset,
      page: page + 1,
      per_page: per_page,
      search: search,
      store_id: store_id,
      floor_room_id: floor_room_id,
    };
    if (this.dataDiningTables.length < 1 || run) {
      this.isLoading = true;
    }
    const result = await diningTablesService.fetchListDiningTables(
      paramsTagProduct
    );
    this.isLoading = false;
    if (result.status === HttpStatusCode.Ok) {
      this.dataDiningTables = result.body.data;
      this.metadata = result.body.metadata;
    } else {
      toastUtils.error("Đã có lỗi xảy ra", "");
    }
  }

  async createDiningTable({ form }: { form: any }) {
    const {
      name,
      quantity_people,
      ordering,
      store_id,
      floor_room_id,
      nameStore,
      nameFloorRoom,
    } = this.formDataDiningTables;

    const data = {
      name: name.trim(),
      quantity_people: quantity_people,
      ordering: ordering,
      store_id: store_id,
      floor_room_id: floor_room_id,
    };
    this.isLoadingBtn = true;
    this.openModal = true;
    this.holdModal = true;
    const result = await diningTablesService.addDiningTables(data);
    this.isLoadingBtn = false;
    this.holdModal = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Thêm mới thành công", "");
      form.resetFields();
      this.clearFormDiningTables();
      this.dataDiningTables.unshift({
        ...result.body.data,
        floor_room: { id: floor_room_id, name: nameFloorRoom },
        store: { id: store_id, name: nameStore },
      });

      if(this.dataDiningTables.length > this.staticParamDiningTable.per_page){
        this.dataDiningTables.pop();
      }
      this.openModal = false;
      this.holdModal = false;
    } else {
      if (
        result.body.message?.floor_room_id ===
        "the_floor_room_id_field_is_required"
      ) {
        toastUtils.error("Tầng phòng không được để trống");
      }

      toastUtils.error(result.body.message);
    }
  }

  async updateDiningTable(values: IdataDiningTables) {
    const data = {
      name: values.name.trim(),
      quantity_people: values.quantity_people,
      ordering: values.ordering,
      store_id: values.store_id,
      floor_room_id: this.formDataDiningTables.floor_room_id,
    };
    this.holdModal = true;
    this.isLoadingBtn = true;
    this.openModal = true;
    const result = await diningTablesService.editDiningTables(
      this.idDiningTable,
      data
    );
    this.isLoadingBtn = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Cập nhật thành công", "");
      this.openModal = false;
      this.holdModal = false;
      const updatedArrDiningTable = this.dataDiningTables.map((item) =>
        item.id === this.idDiningTable
          ? {
              ...item,
              ...data,
              store: {
                ...item.store,
                id: data.store_id,
                name: this.formDataDiningTables.nameStore,
              },
              floor_room: {
                ...item.floor_room,
                id: data.floor_room_id,
                name: this.formDataDiningTables.nameFloorRoom,
              },
              floor_room_parent: {}, // vì khi chọn select floor_room nó bao gồm cả parent rồi
            }
          : item
      );

      this.dataDiningTables = updatedArrDiningTable;
      this.clearFormDiningTables();
    } else {
      if (
        result.body.message?.floor_room_id ===
        "the_floor_room_id_field_is_required"
      ) {
        toastUtils.error("Tầng phòng không được để trống");
      }
      toastUtils.error(result.body.message, "2");
    }
  }

  async deleteDiningTable(id: number) {
    this.isLoadingBtn = true;
    const result = await diningTablesService.deleteDiningTables(id);
    this.isLoadingBtn = false;
    if (result.status === HttpStatusCode.Ok) {
      toastUtils.success("Xóa thành công", "");

      this.dataDiningTables = this.dataDiningTables.filter(
        (item: any) => item.id !== id
      );

      if (this.dataDiningTables.length === 0) {
        this.fetchAllDiningTablesList();
      }
    } else {
      toastUtils.error(result.body.message);
    }
  }

  // async getDetailsDiningTable(){
  //     this.isLoading = true;

  //     const result = await diningTablesService.fetchDetailsDiningTables(this.idDiningTable);
  //     if (result.status === HttpStatusCode.Ok) {
  //         this.isLoading = false;
  //         this.formDataDiningTables = result.body
  //     }
  // }

  async fetchAllfloorRoom(type: string) {
    if (type === "modal") {
      this.isLoadingRoom = true
      const result = await diningTablesService.fetchListFloorRoom(
        this.storeId || this.formDataDiningTables.store_id
      );
      this.isLoadingRoom = false
      if (result.status === HttpStatusCode.Ok) {
        this.dataFloorRoom = result.body.data;
      } else {
        this.isLoadingRoom = false
        this.dataFloorRoom = [];
      }
    } else {
        this.isLoadingRoom = true
      const result = await diningTablesService.fetchListFloorRoom(
        this.storeIdFilter
      );
      this.isLoadingRoom = false
      if (result.status === HttpStatusCode.Ok) {
        this.dataFloorRoomFilter = result.body.data;
      } else {
        this.isLoadingRoom = false
        this.dataFloorRoomFilter = [];
      }
    }
  }

  async fetchAllLocation() {
    const result = await diningTablesService.fetchListLocation();
    if (result.status === HttpStatusCode.Ok) {
      this.dataLocation = result.body.data;
    } else {
      this.dataLocation = [];
    }
  }
}

export const diningTablesStore = new DiningTablesStore();
