import {deleteRequest, getRequest, postRequest, putRequest} from "../../../common/helpers/RequestHelper";
class PosService{
    apiSub = "api/v1/machine_pos"
    apiSubStore = "api/v1/storeList"
    public fetchPosGettingAll(): Promise<any>{
        return getRequest(`${this.apiSub}`);
    }
    public fetchPosWithRequirement(posParam:any): Promise<any>{
        const queryParam = [
            posParam.offset ? `offset=${posParam.offset}` : '',
            posParam.order_by ? `order_by=${posParam.order_by}` : '',
            posParam.page ? `page=${posParam.page}` : '',
            posParam.per_page ? `per_page=${posParam.per_page}` : '',
            posParam.search ? `search=${posParam.search}` : '',
            posParam.status ? `status=${posParam.status}` : '',
            posParam.store_id ? `store_id=${posParam.store_id}` : '',
        ].filter(Boolean).join('&')
        return getRequest(`${this.apiSub}?${queryParam}`);
    }
    public fetchPosCreating(posData:any): Promise<any>{
        return postRequest(`${this.apiSub}`, posData);
    }
    public fetchPosModifying(posData:any): Promise<any>{
        return putRequest(`${this.apiSub}/${posData.id}`, posData);
    }
    public fetchPosDeleting(posID:any): Promise<any>{
        return deleteRequest(`${this.apiSub}/${posID}`);
    }
    public fetchPosGettingSpecific(posID:any): Promise<any>{
        return getRequest(`${this.apiSub}/${posID}`);
    }
    public fetchStoreGettingAll(){
        return getRequest(`${this.apiSubStore}`);
    }
}
export const posService = new PosService();