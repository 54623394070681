import { observer } from "mobx-react";
import { Modal, Form, Input } from "antd";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { labelstore } from "../LabelStore";
import { useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { toastUtils } from "../../../../common/utils/Toastutils";
import { numberByDateNow } from "../../../../common/utils/Utils";

const PutLabel = () => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        labelstore
            .fetchUpdate()
    };

    useEffect(() => {
        form.setFieldsValue(labelstore.initialValues);
    }, [labelstore.initialValues, form]);

    return (
        <>
            <Modal
                className="modal-form555"
                footer={null}
                title="Sửa nhãn"
                open={labelstore.isModalCustom}
                onOk={labelstore.handeOk}
                onCancel={labelstore.handleCancel}
                afterClose={labelstore.handleClose}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={labelstore.initialValues}
                >
                    <div className="item-form item-selected">
                        <label className="label-form">Tên nhãn</label>

                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống",
                                },
                                {
                                    max: 150,
                                    message:
                                        "Tên nhãn quá dài, tối đa 150 kí tự",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Nhập tên nhãn"
                                type="text"
                                defaultValue={labelstore.initialValues.name}
                                maxLength={150}
                                onChange={(e) => {
                                    if (e.target.value.length > 149) {
                                        toastUtils.warning(
                                            "Tên nhãn quá dài, tối đa 150 ký tự",
                                            ""
                                        );
                                        return;
                                    }
                                    labelstore.initialValues.name =
                                        e.target.value.trim();
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form">
                        <label className="label-form">Mô tả ngắn</label>

                        <TextArea
                            rows={4}
                            maxLength={1000}
                            placeholder="Mô tả ngắn"
                            onChange={(e) => {
                                labelstore.initialValues.description =
                                    e.target.value.trim();
                            }}
                            // value={labelstore.initialValues.description}
                            style={{
                                border: "none",
                                outline: "none",
                                boxShadow: "none",
                            }}
                        />
                    </div>

                    <div className="item-form item-selected">
                        <label className="label-form">Trạng thái</label>
                        <SelectStatus
                            onChange={(value: any) => {
                                labelstore.initialValues.status = value;
                            }}
                            selected={() => labelstore.initialValues.status}
                            textOn="Hoạt động"
                            textOff="Vô hiệu hóa"
                        />
                    </div>

                    <UploadFile
                        description={`Hình ảnh nhãn`}
                        file={labelstore.initialValues.image}
                        onIconRemove={labelstore.handleCloseIcon}
                        onFileChange={(e) => {
                            handleFile(e, labelstore.changeFileImage);
                        }}
                        onChooseImg={(url: string)=>{
                            labelstore.initialValues.image = url
                        }}
                        code={numberByDateNow()}
                    />

                    <div className="btnCreateModal">
                        <button
                            className="cancelModal"
                            type="reset"
                            onClick={() => labelstore.handleCancel()}
                        >
                            Đóng
                        </button>
                        <button
                            className="submitForm"
                            type="submit"
                            disabled={labelstore.isLoadingBtn}
                        >
                            {labelstore.isLoadingBtn
                                ? "Vui lòng đợi..."
                                : "Lưu"}
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(PutLabel);
