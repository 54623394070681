import { HttpStatusCode } from "axios";
import { posService } from "./PosService";
import { makeAutoObservable } from "mobx";
import {toastUtils} from "../../../common/utils/Toastutils";

class PosStore{
    searchTemp: string = "21312";
    allowSearch:boolean = false
    isSave: boolean = false;
    modalOpen: boolean = false;
    loadingDataAll: boolean= true;
    loadingDataTable: boolean= true;
    loadingListStore: boolean = true;
    waitingText: string = '';
    isCreating: boolean= true;
    modalName: string = '';
    detailedDataPos:{}={}
    listPos: any[] = [];
    listStore: any[] = [];
    statusFilterActiveStatus: boolean = false;
    statusFilterInactiveStatus: boolean = false;
    locationFilterValue: number = -1;
    selectedPosInTable: any[] = [];
    neededActionPosValue: string = '';
    constructor(){

        this.initParam();
        makeAutoObservable(this);
    }
    staticParamPos :{offset: number, order_by: string, page: number, per_page: number, search: string|null, status: string|null, store_id: number | null} = {
        offset: 0,
        order_by: 'id', //Only accept order_by : 'id', 'name'
        page: 0,
        per_page: 20,
        search: '',
        status: '', //Only accept status : '1', '0'
        store_id: 0
    }
    staticDataPos :{id:string, name: string, store_id: string, status: string}={
        id: '',
        name: '',
        store_id: '',
        status: '1',
    }
    metadata: {total: number, page: number, total_page: number, size: number}={
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }
    initParam(){
        const queryParam = new URLSearchParams(window.location.search)
        this.staticParamPos.page = queryParam.get('page') ? Number(queryParam.get('page')) - 1 : 0;
        this.staticParamPos.search = queryParam.get('search') ? String(queryParam.get('search')) : '';
        this.searchTemp = queryParam.get('search') ? String(queryParam.get('search')) : '';
        this.staticParamPos.store_id = queryParam.get('store_id') ? Number(queryParam.get('store_id')) : 0;
        this.staticParamPos.status = queryParam.get('store_id') ? String(queryParam.get('store_id')) : '';
    }

    async createPos(){
        if(this.isSave === false){
            this.isSave = true
            this.waitingText = 'Vui lòng chờ...'
            let {name, store_id, status} = this.staticDataPos;
            const createdDataPos = {
                name: name,
                store_id: store_id,
                status: status
            }
            const result = await posService.fetchPosCreating(createdDataPos);
            this.waitingText = ''
            this.isSave = false;
            if(result.status === HttpStatusCode.Ok){
                toastUtils.success('Thêm thành công!', '');
                this.listPos.unshift(result.body.data)
                if(this.listPos.length >= this.staticParamPos.per_page){
                    this.listPos.pop()
                }
                this.handleClearContent()
            }
            else{
                toastUtils.error('Đã có lỗi xảy ra!','')
            }
            this.modalOpen = false;
        }

    }
    async modifyPos(){
        this.waitingText = 'Vui lòng chờ...'
        let {id, name, store_id, status} = this.staticDataPos;
        const modifiedDataPos = {
            id: id,
            name: name,
            store_id: store_id,
            status: status
        }
        const result = await posService.fetchPosModifying(modifiedDataPos);
        this.waitingText = '';
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Sửa thành công!', `${id}`);
            this.modalOpen = false;
            this.listPos = this.listPos.map((itemData:any) => {
                if(itemData.id === this.staticDataPos.id){
                    itemData.name = this.staticDataPos?.name
                    itemData.location_id = parseInt(this.staticDataPos?.store_id)
                    itemData.status = this.staticDataPos?.status
                }
                return itemData;
            })
            this.handleClearContent()
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
    }
    async deletePos(){
        let {id} = this.staticDataPos;
        const result = await posService.fetchPosDeleting(id);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Xóa thành công!', '');
            this.listPos = this.listPos.filter((itemData:any) => itemData.id != id)
            if(this.listPos.length === 0) {
                this.getPosWithRequirement();
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
    }
    async getPosSpecific(){
        let {id} = this.staticDataPos;
        const result = await posService.fetchPosGettingSpecific(id);
        if(result.status === HttpStatusCode.Ok){
            this.detailedDataPos = result.body.data
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
    }
    async getPosAll(){
        this.loadingDataAll = true;
        this.loadingDataTable = false;
        const result = await posService.fetchPosGettingAll();
        if(result.status === HttpStatusCode.Ok){
            this.listPos = result.body.data;
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','');
        }
        this.loadingDataAll = false;
    }
    async getStoreAll(){
        if(this.listStore.length < 1) {
            this.loadingListStore = true;
        }
        const result = await posService.fetchStoreGettingAll();
        this.loadingListStore = false;
        if(result.status === HttpStatusCode.Ok){
            if(this.listStore != result.body.data){
                this.listStore = result.body.data;
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }

    }
    async getPosWithRequirement(run?:boolean){
        if(this.listPos.length < 1 || run){
            this.loadingDataTable = true;
        }
        let {search, page, per_page, status, store_id} = this.staticParamPos;
        const paramPos = {
            search: search ? search : '',
            page: page + 1,
            per_page: per_page ? per_page : 10,
            status: status === 'active' ? '1' : status === 'deactive' ? '0' : '',
            store_id: store_id ? store_id : '',
        }
        const result = await posService.fetchPosWithRequirement(paramPos);
        this.loadingDataTable = false;
        if(result.status === HttpStatusCode.Ok){
            if(this.listPos != result.body.data){
                this.listPos = result.body.data
                this.metadata = result.body.metadata
            }
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }

    }
    handleClearContent(){
        this.staticDataPos.id = '';
        this.staticDataPos.name = '';
        this.staticDataPos.store_id = '';
        this.staticDataPos.status = '1';
        this.modalName = '';
        this.locationFilterValue = -1;
        this.statusFilterActiveStatus = false;
        this.statusFilterInactiveStatus = false;
    }
    handleCheckOnStatusActiveOption(){
        if(this.statusFilterActiveStatus === false){
            this.statusFilterActiveStatus = true;
            this.staticParamPos.status = '1';
            if(this.statusFilterInactiveStatus === true){
                this.statusFilterInactiveStatus = false;
            }
            this.getPosWithRequirement();
        }
        else{
            this.statusFilterActiveStatus = false;
            this.staticParamPos.status = null;
            this.getPosWithRequirement();
        }
    }
    handleCheckOnStatusInactiveOption(){
        if(this.statusFilterInactiveStatus === false){
            this.statusFilterInactiveStatus = true;
            this.staticParamPos.status = '0';
            if(this.statusFilterActiveStatus === true){
                this.statusFilterActiveStatus = false;
            }
            this.getPosWithRequirement(true);
        }
        else{
            this.statusFilterInactiveStatus = false;
            this.staticParamPos.status = null;
            this.getPosWithRequirement(true);
        }
    }
    async handleCheckOnLocationOption(value:any){
        value = Number(value)
        if(this.locationFilterValue === value){
            toastUtils.warning('Remove Filter', '')
            this.locationFilterValue = -1;
            this.staticParamPos.store_id = null;
        }
        else{
            this.locationFilterValue = value;
            this.staticParamPos.store_id = value;
        }
        this.getPosWithRequirement(true);
    }
    async handleSwitchStatusPos(item:any, status: boolean){
        this.staticDataPos.id = item.id;
        this.staticDataPos.store_id = item.location_id;
        this.staticDataPos.status = (status === true ? '1' : '0');
        this.staticDataPos.name = item.name;
        return this.modifyPos();
    }
    async handlePosActionFilter() {
        const neededActionPosList = this.listPos.filter((item: any) =>
            this.selectedPosInTable.includes(item.id)
        );
        if(neededActionPosList.length === 0){
            toastUtils.warning('Vui lòng chọn máy POS!', '')
            return;
        }
    
        switch (this.neededActionPosValue) {
            case 'active':
            case 'inactive':
                await Promise.all(
                    neededActionPosList.map(item =>
                        this.handleSwitchStatusPos(item, this.neededActionPosValue === 'active')
                    )
                );
                await this.getPosWithRequirement();
                break;
    
            case 'delete':
                await Promise.all(
                    neededActionPosList.map(item => {
                        this.staticDataPos.id = item.id;
                        return this.deletePos(); // Nhớ trả về Promise từ hàm deletePos nếu chưa có
                    })
                );
                break;
    
            default:
                toastUtils.error('Vui lòng chọn hành động!', '');
                return;
        }
        this.neededActionPosValue = '';
    }
}
export const posStore = new PosStore();