import React, { useEffect } from "react";
import { Form, Modal } from "antd";
import { adminStore, TFormStaff } from "../../../AdminStore";
import { observer } from "mobx-react";
import "./EditPermission.scss";
import SelectCustom from "../../../../../../shared/components/form/SelectCustom";
const EditPermission = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      roles: adminStore.dataFormStaff?.roles,
    });
  }, [form, adminStore.dataFormStaff]);

  return (
    <Modal
      title={`Sửa phân quyền`}
      open={adminStore.openModalChangePermission}
      footer={null}
      onCancel={() => {
        adminStore.openModalChangePermission = adminStore.isLoadingBtn;
        adminStore.typeModal === "editPermission" &&
          adminStore.resetFormStaff();
      }}
      wrapClassName={`modalEditPermission ${
        adminStore.isLoadingBtn ? "disabled-modal" : ""
      } `}
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          await adminStore.updateStaff({});
        }}
      >
        <Form.Item<TFormStaff>
          className="item-form"
          label="Chọn nhóm quyền"
          name="roles"
          rules={[{ required: true, message: "Vui lòng chọn nhóm quyền!" }]}
        >
          <SelectCustom
            list={adminStore.dataListRole && adminStore.dataListRole}
            placeholder=""
            showSearch
            onChange={(value: any) => {
              adminStore.dataFormStaff.roles = Number(value);
            }}
            selected={() => {
              return (
                adminStore.dataListRole?.find(
                  (item) => item.id === adminStore.dataFormStaff.roles
                )?.id || null
              );
            }}
            getLabel={(item: any) => item.name}
          ></SelectCustom>
        </Form.Item>
        <div className="btnCreateModal">
          <button
            className="cancelModal"
            type="button"
            onClick={() => {
              adminStore.openModalChangePermission = false;
            }}
          >
            Hủy bỏ
          </button>
          <button
            className={`submitForm`}
            disabled={adminStore.isLoadingBtn}
            type="submit"
          >
            {adminStore.typeModal === "editPermission" &&
            !adminStore.isLoadingBtn
              ? "Cập nhật"
              : "Vui lòng chờ..."}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default observer(EditPermission);
