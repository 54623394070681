import React, { ReactNode } from "react";
import "./LayoutRefundOrder.scss";
import { refundOrderStore } from "../../RefundOrderStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
interface LayoutRefundOrderProps {
  children: ReactNode;
  onClickBack?: () => void;
}

const LayoutRefundOrder: React.FC<LayoutRefundOrderProps> = ({
  children,
  onClickBack = () => {},
}) => {
  const navigate = useNavigate();
  const updateUrlParams = () => {
    const searchParams = new URLSearchParams();

    if (refundOrderStore.dataFilterRefundOrder.keyword) {
      searchParams.set(
        "keyword",
        refundOrderStore.dataFilterRefundOrder.keyword
      );
    }

    refundOrderStore.staticParamRefundOrder.page = 0;

    navigate({ search: searchParams.toString() }, { replace: true });

    refundOrderStore.fetchAllRefundOrder();
  };

  const handleSearch = () => {
    updateUrlParams();
  };

  const handleBackClick = () => {
    // Xóa search khỏi URL khi quay lại
    refundOrderStore.dataFilterRefundOrder.keyword = "";
    navigate({ search: "" }, { replace: true });
    // refundOrderStore.fetchAllRefundOrder(true)
    refundOrderStore.staticParamRefundOrder.page = 0
    onClickBack();
  };

  return (
    <div className="LayoutRefundOrder">
      <div className="LayoutRefundOrder-head">
        <div className="LayoutRefundOrder-back" onClick={handleBackClick}>
          <img src="/assets/icon/icon-back.svg" alt="icon-back" />
          Quay lại
        </div>

        <form
          className="filter-search"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <input
            type="text"
            name="keyword"
            value={refundOrderStore.dataFilterRefundOrder.keyword}
            id="keyword"
            onChange={(e: any) => {
              refundOrderStore.dataFilterRefundOrder.keyword =
                e.target.value;
            }}
            placeholder="Tìm kiếm theo mã đơn"
          />
          <button type="submit">
            <img src="/assets/icon/search.svg" alt="search" />
          </button>
        </form>
      </div>
      <div className="LayoutRefundOrder-body">{children}</div>
    </div>
  );
};

export default observer(LayoutRefundOrder);
