import { makeAutoObservable } from "mobx";
import { toastUtils } from "../../../common/utils/Toastutils";
import { mailService } from "./MailService";
import { HttpStatusCode } from "axios";

export type TFormConfigMail = {
    mail_name?: string
    username?: string
    password?: string
    domain?: string
    secret_key?: string
    encryption?: string
    mail_address?: string
    using?: string
    mailer?: string;
    host?: string;
  }

  
class MailStore {

    isLoading: boolean = false;
    isLoadingBtn: boolean = false;
    typeService: string = ""
    dataConfigMail: any = {}

    dataFormConfigMail : TFormConfigMail  = {
        mail_name: "",
        username: "",
        password: "",
        domain: "",
        secret_key: "",
        encryption: "",
        mail_address: "",
        using: "",
        mailer: "",
        host: ""
    }

    constructor() {
        makeAutoObservable(this);
    }


    async getConfigMail(run?: boolean) {
        if (Object.keys(this.dataConfigMail).length < 1 || run) {
            this.isLoading = true;
          }
        const results = await mailService.getConfigMail(this.typeService as string);
        this.isLoading = false;
        if (results.status === HttpStatusCode.Ok) {
            this.dataConfigMail = results.body
        }else{
            toastUtils.error(results.body.message);
        }
    }
    
    async updateConfigMail() {
        const {
            mail_name,
            username,
            password,
            domain,
            secret_key,
            encryption,
            mail_address,
            using,
            mailer,
            host
        } = this.dataFormConfigMail;
        
        const data: any = {
            mail_name: mail_name,
            username: username,
            password: password,
            domain: domain,
            secret_key: secret_key,
            encryption: encryption,
            mail_address : mail_address,
            using: using,
            mailer: mailer,
            host: host
        };
    
        this.isLoadingBtn = true
        const result = await mailService.updateConfigMail(
          data
        );
        this.isLoadingBtn = false

        if (result.status === HttpStatusCode.Ok) {
          toastUtils.success("Cập nhật thành công");
        }else{
            toastUtils.error(result.body.message);
        }
      }
    
}

export const mailStore = new MailStore();
