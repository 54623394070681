import {makeAutoObservable} from "mobx";
import {salePointService} from "../SalePointService";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {paymentDebtService} from "../debt/paymentDebt/PaymentDebtService";
import {paymentSuccessService} from "./PaymentSuccessService";
class PaymentSuccessStore{
    isLoading: boolean = false
    transactionPosId: any
    dataTransaction: any

    constructor() {
        makeAutoObservable(this)
    }
    async getBanner(){

    }

    async fetchTransactionDetail(navigate: (route: string) => void){
        this.isLoading = true;
        const result = await paymentSuccessService.fetchTransactionDetail(this.transactionPosId);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataTransaction  = result.body
        } else {
            navigate('danh-sach-pos')
            toastUtils.warning('Giao dịch không tồn tại', '');
        }

    }
}

export const paymentSuccessStore = new PaymentSuccessStore()