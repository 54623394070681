import { makeAutoObservable } from "mobx";
import { dashboardService } from "../../../../pages/dashboard/DashboardService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../../common/utils/Toastutils";

class NotificationStore {
  isLoading: boolean = false;
  isLoadingUpdateAll: boolean = false;
  listNotice: any[] = [];
  totalPage: number = 0;

  isOpenPopoverNotice: boolean = false;

  params: any = {
    page: 1,
    size: 10,
  };

  countNotice: any = {
    read: 0,
    unRead: 0,
    total: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async getNotice() {
    this.isLoading = true;
    const result = await dashboardService.fetchDataNotifications(this.params);
    this.isLoading = false;
    if (result.status === HttpStatusCode.Ok) {
      // noticeStore.page++
      this.listNotice = result.body.data;
      this.totalPage = result.body.metadata.total_page;
      this.countNotice.unRead = result.body.metadata.total_have_not_been_read;
    }
  }

  async readMore() {
    this.isLoading = true;
    this.params.page++;
    const result = await dashboardService.fetchDataNotifications(this.params);
    if (result.status === HttpStatusCode.Ok) {
      this.isLoading = false;
      this.listNotice = [...this.listNotice, ...result.body.data];
      this.totalPage = result.body.metadata.total_page;
      this.countNotice.unRead = result.body.metadata.total_have_not_been_read;
    }
  }

  async UpdateStatusNotification(id: number | undefined) {
    const resultNotifications =
      await dashboardService.fetchUpdateStatusNotification(id);
    if (resultNotifications.status === HttpStatusCode.Ok) {
      if (this.countNotice.unRead > 0) {
        this.countNotice.unRead--;
      }
    } else {
      toastUtils.error(resultNotifications.body.message, "");
    }
  }

  async UpdateStatusAllNotification() {
    this.isLoadingUpdateAll = true;
    const resultNotifications =
      await dashboardService.fetchUpdateStatusAllNotification();
    this.isLoadingUpdateAll = false;
    if (resultNotifications.status === HttpStatusCode.Ok) {
      this.countNotice.unRead = 0;
      const updateStatus =
        this.listNotice.length > 0 &&
        this.listNotice.map((item) => ({
          ...item,
          status: 1, // đã xem
        }));
      this.listNotice = updateStatus as any;
    } else {
      toastUtils.error(resultNotifications.body.message, "");
    }
  }
}

export const notificationStore = new NotificationStore();
