import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { adminStore, TFormStaff } from "../../../AdminStore";
import { observer } from "mobx-react";
import "./ChangePassword.scss";
const ChangePassword = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      password: "",
      password_confirmation: "",
    });
  }, [form, adminStore.dataFormStaff]);

  return (
    <Modal
      title={`Thay mật khẩu đăng nhập`}
      open={adminStore.openModalChangePassword}
      footer={null}
      onCancel={() => {
        adminStore.openModalChangePassword = adminStore.isLoadingBtn;
        adminStore.typeModal === "editPass" &&
          !adminStore.isLoadingBtn &&
          adminStore.resetFormStaff();

        form.resetFields();
      }}
      wrapClassName={`modalChangePassword ${
        adminStore.isLoadingBtn ? "disabled-modal" : ""
      }`}
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          adminStore.dataFormStaff.password = values.password;
          adminStore.dataFormStaff.password_confirmation =
            values.password_confirmation;

          await adminStore.updateStaff({});
        }}
      >
        <Form.Item<TFormStaff>
          className="item-form"
          label="Mật khẩu mới"
          name="password"
          rules={[
            { required: true, message: "Vui lòng nhập vào trường này" },
            {
              min: 8,
              message: "Mật khẩu phải có ít nhất 8 ký tự",
            },
            {
              max: 30,
              message: "Mật khẩu không được vượt quá 30 ký tự",
            },
          ]}
        >
          <Input.Password placeholder="Vui lòng nhập" />
        </Form.Item>
        <Form.Item<TFormStaff>
          className="item-form"
          label="Xác nhận Mật khẩu mới"
          name="password_confirmation"
          rules={[
            { required: true, message: "Vui lòng nhập vào trường này" },
            {
              min: 8,
              message: "Mật khẩu xác nhận phải có ít nhất 8 ký tự",
            },
            {
              max: 30,
              message: "Mật khẩu xác nhận không được vượt quá 30 ký tự",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Mật khẩu xác nhận không khớp!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Vui lòng nhập" />
        </Form.Item>

        <div className="btnCreateModal">
          <button
            className="cancelModal"
            type="button"
            onClick={() => {
              adminStore.openModalChangePassword = false;
            }}
          >
            Hủy bỏ
          </button>
          <button
            className={`submitForm`}
            disabled={adminStore.isLoadingBtn}
            type="submit"
          >
            {adminStore.typeModal === "editPass" && !adminStore.isLoadingBtn
              ? "Cập nhật"
              : "Vui lòng chờ..."}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default observer(ChangePassword);
