import {postLoginRequest, postRequest} from "../../common/helpers/RequestHelper";
import StorageService from "../../common/service/StorageService";

class LoginService {
    apiSubidirectory = "api/v1/oauth/create-token";
    apiRefreshToken = "api/v1/refresh-token";
    apiVerifyDomain = `${process.env.REACT_APP_API_DOMAIN}/api/v1/domain/verify`;

    public verifyDomain(domain_name: string) {
        const params = { domain_name };
        return postLoginRequest(this.apiVerifyDomain, params);
    }

    public createToken(username: string, password: string, endpoint: string) {
        const params = { username, password };
        return postLoginRequest(endpoint + this.apiSubidirectory, params);
    }

    public refreshToken(param:any) {
        return postRequest(`${this.apiRefreshToken}`,param );
    }
    public sendMailresetPass(mail: string) {
        return postRequest(`api/v1/mail/send/reset-password`, {email: mail});
    }
    public resetPass(data: any) {
        return postRequest(`api/v1/mail/update/reset-password`, data);
    }
}

export const loginService = new LoginService();
