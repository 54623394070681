import { Select } from "antd";
import { observer } from "mobx-react";
import React from "react";

interface SelectCustomProps {
    list: any[];
    placeholder: string;
    labelFirst?: string;
    allowClear?: boolean;
    showSearch?: boolean;
    disabled?: boolean;
    onChange: (value: any) => void;
    selected: () => any;
    getLabel: (item: any) => string;
}
const SelectCustom: React.FC<SelectCustomProps> = ({
    list,
    placeholder,
    labelFirst,
    onChange,
    selected,
    getLabel,
    allowClear,
    showSearch,
    disabled,
}) => {
    return (
        <>
            <Select
                placeholder={placeholder}
                filterOption={(input, option) =>
                    (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                value={selected()}
                onChange={onChange}
                allowClear={allowClear}
                showSearch={showSearch}
                disabled={disabled}
                options={[
                    ...(labelFirst ? [{ value: "", label: labelFirst }] : []),
                    ...list.map((item: any) => ({
                        value: item.id,
                        label: getLabel(item),
                    })),
                ]}
            />
        </>
    );
};
export default observer(SelectCustom);
