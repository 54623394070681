import { deleteRequest, getRequest, postRequest, putRequest } from "../../../common/helpers/RequestHelper";

class AdminService{
    public fetchListStaff(params: any): Promise<any> {
        const queryParams = [
            params.order ? `order=${params.order}` : "",
            params.page ? `page=${params.page}` : "",
            params.per_page ? `paginate=${params.per_page}` : "",
            params.search ? `searchName=${params.search}` : "",
            params.status ? `status=${params.status}` : "",
            params.role ? `role=${params.role}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`api/v1/staff/list?${queryParams}`);
    }

    public fetchListRole(): Promise<any> {
        return getRequest(`api/v1/staff/roles`);
    }

    public addStaff(data: any): Promise<any> {
        return postRequest(`api/v1/staff/store`, data);
    }
    public editStaff(id: any, data: any): Promise<any> {
        return putRequest(`api/v1/staff/${id}/update`, data);
    }
    public deleteStaff(id: any): Promise<any> {
        return deleteRequest(`api/v1/staff/${id}/destroy`);
    }
}
export const adminService = new AdminService();
