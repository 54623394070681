import {makeAutoObservable} from "mobx";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();

class LogoutStore{
    constructor() {
        makeAutoObservable(this)
    }
    async getBanner(){

    }
    logout(){
        let domainName = StorageService.getLocalStore('domainName');
        let idDomain = StorageService.getLocalStore('idDomain');
        StorageService.removeToken()
        StorageService.removeRefreshToken()
        localStorage.clear();
        StorageService.setLocalStore('domainName',domainName);
        StorageService.setLocalStore('idDomain',idDomain);
        window.location.href = "/dang-nhap";
    }
}
export const logoutStore = new LogoutStore()