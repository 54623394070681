import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { groupProductService } from "./GroupProductService";
import { productService } from "../product/ProductService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { string } from "yup";

interface StaticParamGroupProduct {
    limit: number | null;
    offset: number | null;
    order_by: string;
    page_number: number;
    per_page: number | null;
    search: string;
    status: string;
}

class GroupProductStore {
    isLoadingBtn = false;
    staticParamGroupProduct: StaticParamGroupProduct = {
        limit: null,
        offset: null,
        order_by: "",
        page_number: 0,
        per_page: 30,
        search: "",
        status: "",
    };
    staticDataGroupProduct: {
        id: number;
        name: string;
        thumbnail: string;
        description: string;
        type: number;
        sort_condition: null;
        sort: number;
        sort_order: string;
        seo_title: string;
        seo_description: string;
        slug: string;
        products: any[];
    } = {
        id: 0,
        name: "",
        thumbnail: "",
        description: "",
        type: 0,
        sort_condition: null,
        sort: 0,
        sort_order: "",
        seo_title: "",
        seo_description: "",
        slug: "",
        products: [],
    };
    metadata = {
        total: 0,
        page: 1,
        total_page: 1,
        size: 0,
    };
    initParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamGroupProduct.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamGroupProduct.page_number = query.get("page_number")
            ? Number(query.get("page_number"))
            : 0;
    }
    constructor() {
        makeAutoObservable(this);
        this.initParams();
    }
    dataListGroupProduct: any[] = []; // List get Group Product
    async getDataListViaParam(run?: boolean) {
        let { limit, offset, order_by, page_number, per_page, search, status } =
            this.staticParamGroupProduct;
        const paramGroupProduct = {
            limit: limit,
            offset: offset,
            order_by: order_by,
            page_number: page_number + 1,
            per_page: per_page,
            search: search,
            status: status,
        };
        if (this.dataListGroupProduct.length < 1 || run) {
            this.isLoadingTable = true;
        }
        const result = await groupProductService.fetchGroupsProduct(
            paramGroupProduct
        );
        console.log("check getDataListViaParam", result);
        this.isLoadingTable = false;
        if (result.status === HttpStatusCode.Ok) {
            if (
                result.body.data.length !== this.dataListGroupProduct.length ||
                JSON.stringify(result.body.data) !==
                    JSON.stringify(this.dataListGroupProduct)
            ) {
                this.dataListGroupProduct = result.body.data;
                this.metadata = result.body.metadata;
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async getDataListProductAll() {
        const param = {
            search: "",
        };
        const response = await productService.fetchListProduct(param);
        console.log("check getDataListProductAll", response);
        if (response.status === HttpStatusCode.Ok) {
            this.dataListProduct = response.body.data;
            this.dataListProductAll = response.body.data;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async getDataListProductViaParam(keyword: string) {
        this.isLoadingProductList = true;
        const param = {
            search: keyword,
        };
        const response = await productService.fetchListProduct(param);
        if (response.status === HttpStatusCode.Ok) {
            this.dataListProduct = response.body.data;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.isLoadingProductList = false;
    }
    async getDataListAll() {
        this.isLoadingTable = true;
        const result = await groupProductService.fetchGroupsProductAll();
        if (result.status === HttpStatusCode.Ok) {
            this.dataListGroupProduct = result.body.data;
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.handleResetModal();
        this.getDataListProductAll();
        this.isLoadingTable = false;
    }
    async addGroupProduct() {
        this.isLoadingBtn = true;
        if (this.idProductList.length === 0) {
            toastUtils.error(
                "Vui lòng chọn ít nhất một sản phẩm vào nhóm!",
                ""
            );
            this.isLoadingBtn = false;
            return -1;
        }
        this.waitingText = "Đang tạo mới";
        this.staticDataGroupProduct.slug = this.handleStringToSlug(
            this.staticDataGroupProduct.name
        );
        let {
            name,
            thumbnail,
            description,
            type,
            slug,
            seo_description,
            seo_title,
        } = this.staticDataGroupProduct;
        const addedGroupProductData = {
            name: name,
            thumbnail: thumbnail,
            description: description,
            type: type,
            slug: slug,
            product_list: this.idProductList,
            sort_condition: this.conditionList,
            order_condition:
                String(this.isApplyConditionAll) === String(true)
                    ? "all"
                    : "some",
            seo_description: seo_description,
            seo_title: seo_title,
        };
        // if (type === 0) {
        //     seo_description = "";
        //     seo_title = "";
        // } else {
        //     if (seo_description === "" || seo_title === "") {
        //         toastUtils.error("Vui lòng điền đầy đủ thông tin SEO!", "");
        //         return -1;
        //     } else if (this.conditionList.length === 0) {
        //         this.conditionList = [
        //             {
        //                 sortTitle: "product-name",
        //                 sortType: "%like%",
        //                 sortValue: "",
        //             },
        //         ];
        //     }
        // }
        const result = await groupProductService.fetchCreatingNewGroupProduct(
            addedGroupProductData
        );
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm thành công!", "");
            this.staticParamGroupProduct.page_number = 0;
            this.staticParamGroupProduct.search = "";
            this.getDataListViaParam();
            this.getDataListProductAll();
            this.handleResetModal();
            this.openModal = false;
        } else if (result.status === HttpStatusCode.UnprocessableEntity) {
            toastUtils.warning("Tên nhóm sản phẩm đã tồn tại", "");
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async modifyGroupProduct() {
        this.isLoadingBtn = true;
        this.staticDataGroupProduct.slug = this.handleStringToSlug(
            this.staticDataGroupProduct.name
        );
        let {
            id,
            name,
            thumbnail,
            description,
            type,
            slug,
            seo_description,
            seo_title,
        } = this.staticDataGroupProduct;
        // if (type === 0) {
        //     seo_description = "";
        //     seo_title = "";
        // } else {
        //     if (seo_description === "" || seo_title === "") {
        //         toastUtils.error("Vui lòng điền đầy đủ thông tin SEO!", "");
        //         return -1;
        //     } else if (this.conditionList.length === 0) {
        //         this.conditionList = [
        //             {
        //                 sortTitle: "product-name",
        //                 sortType: "%like%",
        //                 sortValue: "",
        //             },
        //         ];
        //     }
        // }
        if (this.idProductList.length === 0) {
            toastUtils.error(
                "Vui lòng chọn ít nhất một sản phẩm vào nhóm!",
                ""
            );
            return -1;
        }
        this.waitingText = "Đang sửa đổi";
        const modifiedGroupProductData = {
            id: id,
            thumbnail: thumbnail,
            name: name,
            description: description,
            type: type,
            slug: slug,
            product_list: this.idProductList,
            sort_condition: this.conditionList,
            order_condition:
                String(this.isApplyConditionAll) === String(true)
                    ? "all"
                    : "some",
            seo_description: seo_description,
            seo_title: seo_title,
        };
        const result = await groupProductService.fetchModifyingGroupProduct(
            modifiedGroupProductData
        );
        this.isLoadingBtn = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Sửa thành công!", "");
            this.handleResetModal();
            this.openModal = false;
            this.getDataListProductAll();
            this.getDataListViaParam();
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async deleteGroupProduct() {
        let { id } = this.staticDataGroupProduct;
        const deletedGroupProductData = {
            id: id,
        };
        const result = await groupProductService.fetchDeletingGroupProduct(
            deletedGroupProductData
        );

        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Xóa thành công!", "");
            this.staticParamGroupProduct.page_number = 0;
            this.staticParamGroupProduct.search = "";
            this.dataListGroupProduct = this.dataListGroupProduct.filter(
                (item: any) => item.id !== this.staticDataGroupProduct.id
            );
            if (this.dataListGroupProduct.length === 0) {
                this.getDataListViaParam();
                this.getDataListProductAll();
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
    }
    async getSpecificGroupProduct() {
        this.isLoadingProductList = true;
        let { id } = this.staticDataGroupProduct;
        const specificDataGroupProduct = {
            id: id,
        };
        const result = await groupProductService.fetchSpecificGroupProduct(
            specificDataGroupProduct
        );
        if (result.status === HttpStatusCode.Ok) {
            this.staticDataGroupProduct.products = result.body.products;
            this.staticDataGroupProduct.type = result.body.type;
            this.idProductList = this.staticDataGroupProduct.products.map(
                (item: any) => item.id
            );
            if (this.staticDataGroupProduct.type == 1) {
                this.isRequireSeo = true;
            }
        } else {
            toastUtils.error("Đã có lỗi xảy ra!", "");
        }
        this.isLoadingProductList = false;
    }
    //State
    isLoadingTable: boolean = false; //Loading on the Table while getting all the Group Product
    isLoadingProductList: boolean = true; //Loading on the ProductList while getting product list from api
    isApplyConditionAll: boolean = false;
    isApplyingConditionStatus: boolean = false; // show status of apply condition

    modalName: string = ""; //Modal name for Creating - Modifying
    waitingTextForButton: string = ""; //Context for showing status when fetching data

    openModal: boolean = false; //Modal Status for Modifying -Creating
    openModalProduct: boolean = false; //Modal status for Getting Product

    isCreating: boolean = false; //Behavior Status Creating or Not Creating (Not Creating means Modifying)

    selectedTargets: any[] = []; // Get Selected Targets in the main table;

    dataListProduct: any[] = []; // A list to get all the Product after fetching - also using for show products that is included in each Group Product
    dataListProductAll: any[] = []; // A list to get all the Product after fetching - also using for show products that is included in each Group Product

    dataListProductViaCondition: any[] = []; // A list to get all the Product via condition
    isRequireSeo: boolean = false; //A Boolean val is used to require SEO if type value == 1 (type form staticDataGroupProduct)

    conditionList: any[] = []; // A list to get all the condition value from modal
    conditionPosition: number = 0; // A value to get the condition input position in the modal
    idProductList: number[] = []; // A List to get the ID for Remove Duplicated ID Product
    waitingText: string = "";
    searchKeywordProduct: string = "";

    handleCloseIcon() {
        this.staticDataGroupProduct.thumbnail = "";
    }
    changeFile = (pathFile: string) => {
        this.staticDataGroupProduct.thumbnail = pathFile;
    };
    checkIfHavingCondition() {
        if (this.conditionList.length === 0) {
            this.isApplyingConditionStatus = false;
            this.getDataListProductAll();
        } else {
            this.isApplyingConditionStatus = true;
        }
    }
    handleSortProducts(sort: string) {
        switch (sort) {
            case "A-Z": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) => a.title.localeCompare(b.title)
                );
                break;
            }
            case "Z-A": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) => b.title.localeCompare(a.title)
                );
                break;
            }
            case "PriceIncrease": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) => a.price_origin - b.price_origin
                );
                break;
            }
            case "PriceDecrease": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) => b.price_origin - a.price_origin
                );
                break;
            }
            case "Newest": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) =>
                        new Date(a.updated_at).getTime() -
                        new Date(b.updated_at).getTime()
                );
                break;
            }
            case "Oldest": {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                );
                break;
            }
            default: {
                this.dataListProduct = this.dataListProduct.sort(
                    (a: any, b: any) => a.id - b.id
                );
                break;
            }
        }
    }
    handleRemoveDuplicateProduct() {
        this.idProductList = [];
    }
    async handleApplyCondition() {
        this.isLoadingProductList = true;
        this.dataListProductViaCondition = [];

        for (const item of this.conditionList) {
            if (item.sortType === "%like%") {
                //Including Condition
                await this.getDataListProductViaParam(item.sortValue);
                this.dataListProduct.forEach((product: any) => {
                    this.dataListProductViaCondition.push(product);
                });
            } else if (item.sortType === "not like") {
                //NOT Including Condition
                await this.getDataListProductViaParam(item.sortValue); //fetch Data via Value
                var dummyList = this.dataListProduct; //store data from prev fetching
                const idsInSearch = new Set(dummyList.map((item) => item.id)); //get id from prev fetching
                await this.getDataListProductAll(); //get Data Product all
                this.dataListProduct = this.dataListProduct.filter(
                    (item: any) => !idsInSearch.has(item.id)
                ); //remove item (compare id) from all product which not include from prev searching
                this.dataListProduct.map((item: any) =>
                    this.dataListProductViaCondition.push(item)
                ); //reassign to datalist
            } else {
                console.log("other");
            }
        }
        this.dataListProduct = [...this.dataListProductViaCondition];
        this.dataListProduct = this.handleFilterUniqueById(
            this.dataListProduct
        );
        this.isLoadingProductList = false;
    }

    handleRemoveItemInConditionList() {
        async function handleSpice() {
            groupProductStore.conditionList.splice(
                groupProductStore.conditionPosition,
                1
            );
        }
        handleSpice().then(() => {
            groupProductStore.checkIfHavingCondition();
        });
    }
    handleRemoveItemInIDProductList(index: number) {
        this.idProductList.splice(index, 1);
    }
    handleAddItemInConditionList() {
        this.conditionList.push({
            sortTitle: "empty",
            sortType: "%like%",
            sortValue: "",
        });
    }
    handleStringToSlug(str: any) {
        const from =
            "ÁÀẢÃẠÂẤẦẨẪẬĂẮẰẲẴẶÉÈẺẼẸÊẾỀỂỄỆÍÌỈĨỊÓÒỎÕỌÔỐỒỔỖỘƠỚỜỞỠỢÚÙỦŨỤƯỨỪỬỮỰÝỲỶỸỴĐ" +
            "áàảãạâấầẩẫậăắằẳẵặéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵđ";
        const to =
            "AAAAAAAAAAAAAAAAAEEEEEEEEEEEIIIIIOOOOOOOOOOOOOUUUUUUUUUUYYYYYD" +
            "aaaaaaaaaaaaaaaaaeeeeeeeeeeeiiiiioooooooooooooouuuuuuuuuuuyyyyyd";

        return str
            .split("")
            .map((char: any, i: number) => {
                const index = from.indexOf(char);
                return index !== -1 ? to.charAt(index) : char;
            })
            .join("")
            .toLowerCase() // Chuyển thành chữ thường
            .trim() // Loại bỏ khoảng trắng ở đầu và cuối
            .replace(/[^a-z0-9\s-]/g, "") // Loại bỏ các ký tự không phải là chữ cái, số và dấu gạch ngang
            .replace(/\s+/g, "-") // Thay khoảng trắng bằng dấu gạch ngang
            .replace(/-+/g, "-"); // Loại bỏ các dấu gạch ngang liên tiếp
    }
    handleGetUniqueID(list: any[]) {
        var dummyArray: any[] = [];
        list.forEach((item: any) => {
            if (!dummyArray.includes(item.id)) {
                dummyArray.push(item.id);
            }
        });
        return dummyArray;
    }
    handleGetIDArray(arr: any[]) {
        return arr.map((item: any) => item.id);
    }
    handleFilterUniqueById(arr: any[]) {
        const uniqueMap = new Map();

        arr.forEach((item) => {
            if (!uniqueMap.has(item.id)) {
                uniqueMap.set(item.id, item);
            }
        });

        return Array.from(uniqueMap.values());
    }
    handleResetModal() {
        this.staticDataGroupProduct.name = "";
        this.staticDataGroupProduct.description = "";
        this.staticDataGroupProduct.seo_description = "";
        this.staticDataGroupProduct.thumbnail = "";
        this.staticDataGroupProduct.seo_title = "";
        this.staticDataGroupProduct.slug = "";
        this.staticDataGroupProduct.type = 0;
        this.searchKeywordProduct = "";
        this.isRequireSeo = false;
        this.idProductList = [];
        this.isLoadingProductList = false;
        this.waitingText = "";
        this.isApplyConditionAll = false;
        this.conditionList = [];
        this.isApplyingConditionStatus = false;
        this.dataListProductViaCondition = [];
    }
    handleSelectProductInTable(checked: boolean, value: number) {
        if (checked === true) {
            groupProductStore.idProductList.push(value);
        } else {
            groupProductStore.idProductList =
                groupProductStore.idProductList.filter(
                    (item) => item !== value
                );
        }
    }
    checkIfRequireSeo() {
        if (this.staticDataGroupProduct.type === 1) {
            this.isRequireSeo = true;
        } else {
            this.isRequireSeo = false;
        }
    }
}

export const groupProductStore = new GroupProductStore();
